import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {LOGIN} from '../../constants/routes';
import firebase from 'firebase/app';
import {
    authSetUserData,
    fetchUserData,
    setUserSelectedBranches,
    setBranches,
    fetchOrganizationData,
    authSetOrganizationData,
} from '../../store/action';
import {getUserRole} from '../../store/action/authHelpers';
import {
    saveUserData,
    getSelectedBranchesFromStorage,
} from '../../storage/localStorage';

const withAuthentication = (
    Component,
    redirect,
    allowedRoles,
    props = null,
) => {
    class WithAuthentication extends React.Component {
        componentDidMount() {
            firebase.auth().onAuthStateChanged(user => {
                user &&
                    fetchUserData(user.uid)
                        .then(doc => {
                            const userData = doc.data();
                            saveUserData(userData);
                            this.props.setUserData(userData || {});
                            this.props.setBranches(userData.branches);
                            const selectedBranches =
                                getSelectedBranchesFromStorage();
                            if (selectedBranches) {
                                this.props.setUserSelectedBranches(
                                    selectedBranches,
                                );
                            } else if (!this.props.selectedBranches.length) {
                                this.props.setUserSelectedBranches(
                                    userData.branches,
                                );
                            }
                            return fetchOrganizationData(userData.organization);
                        })
                        .then(doc => {
                            this.props.authSetOrganizationData(doc.data());
                        });
                if (!user && redirect) {
                    this.props.history.push(LOGIN);
                }
            });
        }

        render() {
            return this.shouldDisplayComponent() ? (
                <Component {...this.props} {...props} />
            ) : (
                <div />
            );
        }

        shouldDisplayComponent = () => {
            const userData = this.props.userData;
            return userData && allowedRoles.includes(getUserRole(userData));
        };
    }

    const mapStateToProps = state => ({
        userData: state.auth.userData,
        selectedBranches: state.branch.selectedBranches,
        branches: state.branch.branches,
    });

    const mapDispatchToProps = {
        setUserData: authSetUserData,
        setUserSelectedBranches: setUserSelectedBranches,
        setBranches: setBranches,
        authSetOrganizationData,
    };

    return withRouter(
        connect(mapStateToProps, mapDispatchToProps)(WithAuthentication),
    );
};

export default withAuthentication;
