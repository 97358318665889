import React, {Component} from 'react';
import Picker from '../picker/Picker';
import {connect} from 'react-redux';
import styles from './globalBranchPicker.module.css';
import {setUserSelectedBranches} from '../../../store/action/branch';
import {
    Option,
    MultiValue,
    ValueContainer,
} from './components/componentsForPicker';

class GlobalBranchPicker extends Component {
    allBranch = {
        id: 'all',
        name: 'Wybierz wszystkie',
    };
    possibleOptions = [];

    componentDidMount() {
        this.setPossibleOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.branches.length !== this.props.branches.length) {
            this.setPossibleOptions();
        }
    }

    setPossibleOptions = () => {
        const {branches} = this.props;
        const allBranch = this.mapBranchToPicker(this.allBranch);
        const mappedBranches = branches.map(this.mapBranchToPicker);
        this.possibleOptions = [allBranch, ...mappedBranches];
    };

    handleChange = branches => {
        const userBranches = this.props.branches;

        if (!branches || !branches.length) {
            branches = [];
        } else if (this.allBranchesSelected()) {
            branches = this.allBranchPresent(branches)
                ? branches.filter(branch => branch.value !== this.allBranch.id)
                : [];
        }

        if (this.allBranchPresent(branches)) {
            branches = userBranches.map(this.mapBranchToPicker);
        }

        this.props.setUserSelectedBranches(
            branches.map(this.mapBranchFromPicker),
        );
    };

    allBranchPresent = branches => {
        return branches.some(branch => branch.value === this.allBranch.id);
    };

    allBranchesSelected = () => {
        const {branches, selectedBranches} = this.props;
        return branches.length === selectedBranches.length;
    };

    mapBranchToPicker = branch => ({
        value: branch.id,
        label: branch.name,
    });

    mapBranchFromPicker = change => ({
        id: change.value,
        name: change.label,
    });

    render() {
        const selectedBranches = this.props.selectedBranches.map(
            this.mapBranchToPicker,
        );
        if (this.allBranchesSelected()) {
            selectedBranches.push(this.mapBranchToPicker(this.allBranch));
        }

        return (
            this.props.branches.length > 1 && (
                <Picker
                    headerClassName={styles.whiteHeader}
                    globalBranchPicker={true}
                    componentsForPicker={{Option, MultiValue, ValueContainer}}
                    label={'Oddziały'}
                    selectedValue={selectedBranches}
                    pickerItems={this.possibleOptions}
                    name="Oddziały"
                    handleChange={this.handleChange}
                    placeholder={'Szukaj oddziału'}
                    clearable={false}
                    closeMenuOnSelect={false}
                    searchable={false}
                    menuPlacement={'top'}
                    multi
                />
            )
        );
    }
}

GlobalBranchPicker.defaultProps = {
    bottomLabelActive: true,
};

const mapStateToProps = state => ({
    selectedBranches: state.branch.selectedBranches,
    branches: state.branch.branches,
});

const mapDispatchToProps = {
    setUserSelectedBranches: setUserSelectedBranches,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalBranchPicker);
