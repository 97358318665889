import {SET_USERS} from './actionTypes';
import {
    DELETE_USER,
    CREATE_NEW_USER,
    UPDATE_USER_PASSWORD,
} from '../../constants/CloudFunctions';
import axios from '../../config/axios/axiosConfig';
import * as Endpoints from './../../constants/endpoints';

export const setUsers = users => {
    return {
        type: SET_USERS,
        users,
    };
};

export const updateUser =
    dispatch => (documentId, user, onSuccess, onError) => {
        Endpoints.users()
            .doc(documentId)
            .update(user)
            .then(onSuccess)
            .catch(error => {
                console.error(error);
                onError();
            });
    };

export const updateUserPassword =
    dispatch => async (userUid, newPassword, onSuccess, onError) => {
        try {
            const config = {
                data: {
                    uid: userUid,
                    newPassword,
                },
            };
            await axios.post(UPDATE_USER_PASSWORD, config);
            onSuccess();
        } catch (error) {
            const passwordUpdateResponse = error.response.data;
            console.error(passwordUpdateResponse);
            onError('Hasło nie zostało zmienione');
            passwordChangeErrorHandler(passwordUpdateResponse?.code, onError);
        }
    };

const passwordChangeErrorHandler = (code, onError) => {
    switch (code) {
        case 'auth/invalid-password':
            onError('Wprowadzone hasło jest niepoprawne');
            break;
        default:
            onError('Hasło nie zostało zmienione');
            break;
    }
};

export const deleteUser = dispatch => async (userUid, onSuccess, onError) => {
    try {
        const config = {
            data: {
                uid: userUid,
            },
        };
        await axios.delete(DELETE_USER, config);
        onSuccess();
    } catch (error) {
        if (error.response) {
            const deleteUserResponse = error.response.data;
            console.error(deleteUserResponse);
            deleteUserErrorHandler(deleteUserResponse.code, onError);
        } else {
            onError('Nie usunięto użytkownika');
        }
    }
};

const deleteUserErrorHandler = (code, onError) => {
    switch (code) {
        case 'auth/user-not-found':
            onError('Użytkownik nie istnieje');
            break;
        default:
            onError('Nie usunięto użytkownika');
            break;
    }
};

export const onUserAdded = dispatch => async (user, onError, onSuccess) => {
    try {
        await axios.post(CREATE_NEW_USER, user);
        onSuccess();
    } catch (err) {
        const createNewUserResponse = err.response.data;
        console.error(createNewUserResponse);
        addUserErrorHandler(createNewUserResponse.code, onError);
    }
};

const addUserErrorHandler = (code, onError) => {
    switch (code) {
        case 'auth/email-already-exists':
            onError('Email jest już zajęty');
            break;
        case 'auth/invalid-email':
            onError('Niepoprawny email');
            break;
        case 'auth/invalid-password':
            onError('Hasło musi mieć min 6 znaków');
            break;
        default:
            onError('Nie udało się stworzyć nowego konta');
    }
};
