import {DESC} from '../../constants/sortingDirections';
import {rolesPriority} from '../../constants/roles';
import {ascendingSort, descendingSort} from '../StringUtils/StringUtils';
import {isSameDay} from '../date/dateUtils';

const OPEN_STATUS = 'open';
const IN_PROGRESS_STATUS = 'inProgress';
const IN_REVIEW_STATUS = 'inReview';
const DONE_STATUS = 'done';

export const sortRows = (compare, accessAttribute, rows) => {
    const sortedRows = [...rows];
    sortedRows.sort((a, b) => compare(accessAttribute(a), accessAttribute(b)));
    return sortedRows;
};

export const statusComparator = (a, b) => {
    switch (b) {
        case OPEN_STATUS:
            return 1;
        case DONE_STATUS:
            return -1;
        default:
            break;
    }

    switch (a) {
        case OPEN_STATUS:
            return -1;
        case DONE_STATUS:
            return 1;
        default:
            break;
    }

    switch (b) {
        case IN_PROGRESS_STATUS:
            return 1;
        case IN_REVIEW_STATUS:
            return -1;
        default:
            break;
    }

    return 0;
};

export const getComparator = (comparator, sortDirection) => (a, b) => {
    return sortDirection === DESC ? comparator(a, b) : -comparator(a, b);
};

export const maintainerComparator = (a, b) => {
    const fullNameA = (a && `${a.name} ${a.surname}`) || '';
    const fullNameB = (b && `${b.name} ${b.surname}`) || '';

    return fullNameB.localeCompare(fullNameA);
};

export const roleComparator = (a, b) => {
    const roleA = Object.keys(a)[0].toUpperCase();
    const roleB = Object.keys(b)[0].toUpperCase();
    return rolesPriority[roleB] - rolesPriority[roleA];
};

export const issueUrgentPriorityComparator = (a, b) =>
    descendingSort(a.urgentPriority, b.urgentPriority);

export const issuePriorityComparator = (a, b) =>
    descendingSort(a.highPriority, b.highPriority);

export const issueCreateDateComparator = (a, b) =>
    ascendingSort(a.createdDate, b.createdDate);

export const elementLocationComparator = (a, b) =>
    ascendingSort(a.location.name, b.location.name);

export const elementNameComparator = (a, b) => ascendingSort(a.name, b.name);

export const issueClosedDateComparator = (a, b) =>
    descendingSort(a.closedDate, b.closedDate);

export const userSurnameComparator = (a, b) =>
    descendingSort(a.surname, b.surname);

export const byDate = (a, b) => {
    if (isSameDay(a, b)) {
        return 0;
    }

    return a.getTime() < b.getTime() ? -1 : 1;
};

export const byTruthiness = (a, b) => {
    if (a === b) {
        return 0;
    }

    return a ? -1 : 1;
};
