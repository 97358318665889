import React, {Component} from 'react';
import {CustomTextField} from '../../../common/components/index';
import Dialog from '../../../common/components/Dialog/Dialog';

class AddBranchDialog extends Component {
    state = {
        newBranchName: '',
    };

    render() {
        const {dialogTitle, handleClose, loading} = this.props;
        const {newBranchName} = this.state;
        const submitButtonDisabled = !newBranchName || loading;

        return (
            <Dialog
                loading={loading}
                handleClose={handleClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={submitButtonDisabled}
                onSubmitHandler={this.onSubmitNewBranchHandler}
                submitButtonText={dialogTitle}
            >
                <CustomTextField
                    label="Nazwa oddziału"
                    onChange={this.onBranchNameChange}
                    fullWidth
                />
            </Dialog>
        );
    }

    onBranchNameChange = event => {
        this.setState({newBranchName: event.target.value});
    };

    onSubmitNewBranchHandler = () => {
        const branchToSet = {
            name: this.state.newBranchName.trim(),
        };

        this.props.onSubmitHandler(branchToSet);
    };
}

export default AddBranchDialog;
