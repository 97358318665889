import React, {Component} from 'react';
import {CustomTextField, RolePicker} from '../../../common/components';
import {NotificationContext} from '../../../context/notifications';
import Dialog from '../../../common/components/Dialog/Dialog';
import BranchPicker from '../../../common/components/branchPicker/BranchPicker';
import {connect} from 'react-redux';
import {roles} from '../../../constants/roles';
import {TYPES} from '../../../constants/error';

class AddUserDialog extends Component {
    state = {
        name: '',
        surname: '',
        email: '',
        password: '',
        checkPassword: '',
        role: '',
        busy: false,
        branches: [],
    };
    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        if (props.branches.length === 1) {
            this.state.branches = this.props.branches;
        }
    }

    componentDidMount() {
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.branches.length && this.props.branches.length === 1) {
            this.setState({branches: this.props.branches});
        }
    }

    render() {
        const {busy, role} = this.state;

        return (
            <Dialog
                loading={busy}
                handleClose={this.props.handleClose}
                dialogTitle={'Dodaj użytkownika'}
                submitButtonDisabled={this.isSubmitButtonDisabled()}
                onSubmitHandler={this.onSubmitNewUserHandler}
                submitButtonText={'Dodaj użytkowanika'}
            >
                <CustomTextField
                    label="Imię"
                    onChange={this.onTextChangeHandler('name')}
                    fullWidth
                    first
                />
                <CustomTextField
                    label="Nazwisko"
                    onChange={this.onTextChangeHandler('surname')}
                    fullWidth
                />
                <CustomTextField
                    label="Email"
                    onChange={this.onTextChangeHandler('email')}
                    fullWidth
                    type="email"
                />
                <RolePicker handleChange={this.onRoleChangeHandler} />
                <BranchPicker
                    handleChange={this.onBranchChange}
                    branches={this.props.branches}
                    selectedBranches={this.state.branches}
                    disabled={role.toUpperCase() === roles.ADMIN}
                    multi
                />
                <CustomTextField
                    label="Hasło"
                    onChange={this.onTextChangeHandler('password')}
                    fullWidth
                    type="password"
                />
                <CustomTextField
                    label="Potwiedź hasło"
                    onChange={this.onTextChangeHandler('checkPassword')}
                    fullWidth
                    type="password"
                />
            </Dialog>
        );
    }

    isSubmitButtonDisabled = () => {
        const {email, password, checkPassword, name, surname, role, branches} =
            this.state;
        return (
            !email ||
            !password ||
            checkPassword !== password ||
            !name ||
            !surname ||
            !role ||
            !branches.length ||
            password.length < 6
        );
    };

    onRoleChangeHandler = change => {
        const role = change.value;
        const branches =
            role === roles.ADMIN ? this.props.branches : this.state.branches;
        this.setState({role: role.toLowerCase(), branches});
    };

    onTextChangeHandler = field => event => {
        this.setState({[field]: event.target.value});
    };

    onBranchChange = selectedBranches => {
        selectedBranches = selectedBranches || [];
        const branches = selectedBranches.map(branch => ({
            id: branch.value,
            name: branch.label,
        }));
        this.setState({branches});
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    onSubmitNewUserHandler = () => {
        const {name, surname, password, role, email, branches} = this.state;
        this.setState({busy: true});
        const userToSet = {
            name,
            surname,
            email,
            role,
            password,
            branches,
        };
        this.props.onUserAdded(
            userToSet,
            error => {
                this.setState({busy: false});
                this.showNotification(error, TYPES.error);
            },
            () => {
                this.showNotification(
                    'Poprawnie dodano użytkownika',
                    TYPES.success,
                );
                this.props.handleClose();
            },
        );
    };
}

const mapStateToProps = store => ({
    branches: store.branch.branches,
});

export default connect(mapStateToProps)(AddUserDialog);
