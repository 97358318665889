import React from 'react';
import {List, ListColumnCreator} from '../../../common/components/index';
import MobileList from '../../../common/components/list/MobileList';
import {
    createUserListTemplate,
    createMobileUserListItemTemplate,
} from './ListItemCreator/createListItemTemplate';
import {roleComparator} from '../../../utils/sorting/sorting';

const usersList = ({disabled, users, onUserClick}) => {
    const columns = [
        new ListColumnCreator('name', 'Imię i nazwisko', {
            accessAttribute: user => `${user.name} ${user.surname}`,
        }),
        new ListColumnCreator('email', 'E-mail', {}),
        new ListColumnCreator('roles', 'Typ uprawnień', {
            customComparator: roleComparator,
        }),
    ];

    return (
        <>
            <List
                columns={columns}
                rows={users}
                rowTemplate={createUserListTemplate(disabled, onUserClick)}
            />
            <MobileList
                columns={columns}
                rows={users}
                rowTemplate={createMobileUserListItemTemplate(
                    disabled,
                    onUserClick,
                )}
            />
        </>
    );
};

export default usersList;
