import React from 'react';
import Select from '../Select/CustomSelect';
import classNames from 'classnames';
import styles from './Picker.module.css';

const picker = ({
    className,
    handleChange,
    placeholder,
    label,
    first,
    pickerItems,
    searchable,
    disabled,
    selectedValue,
    multi,
    headerClassName,
    clearable,
    closeMenuOnSelect,
    componentsForPicker,
    globalBranchPicker,
    menuPlacement,
    menuPortalTarget,
}) => {
    const noTopMargin = first ? styles.noTopMargin : '';
    return (
        <div className={classNames(styles.picker, className, noTopMargin)}>
            <Select
                globalBranchPicker={globalBranchPicker}
                label={label}
                disabled={disabled}
                multi={multi}
                selectedValue={selectedValue}
                handleChange={handleChange}
                placeholder={placeholder}
                options={pickerItems}
                searchable={searchable}
                clearable={clearable}
                closeMenuOnSelect={closeMenuOnSelect}
                componentsForPicker={componentsForPicker}
                menuPlacement={menuPlacement}
                headerClassName={headerClassName}
                menuPortalTarget={menuPortalTarget}
            />
        </div>
    );
};

picker.defaultProps = {
    bottomLabelActive: true,
    searchable: true,
};

export default picker;
