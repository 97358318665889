import React from 'react';
import Picker from '../picker/Picker';

const branchPicker = props => {
    const mapBranchToPicker = branch => ({
        value: branch.id,
        label: branch.name,
    });

    const items = props.branches?.map(mapBranchToPicker);

    const selectedValue = props.selectedBranch
        ? mapBranchToPicker(props.selectedBranch)
        : props.selectedBranches?.map(mapBranchToPicker);

    return (
        items.length > 1 && (
            <Picker
                label="Oddział"
                selectedValue={selectedValue}
                pickerItems={items}
                name="Oddziały"
                multi={props.multi}
                first={props.first}
                placeholder={'Szukaj oddziału'}
                handleChange={props.handleChange}
                className={props.className}
                disabled={props.disabled}
                clearable={false}
                menuPlacement={props.menuPlacement}
                menuPortalTarget={props.menuPortalTarget}
            />
        )
    );
};

branchPicker.defaultProps = {
    bottomLabelActive: true,
};

export default branchPicker;
