export const allUsersOption = {
    uid: 'all',
    name: 'Wybierz',
    surname: 'wszystkich',
};

export const mapUserToPicker = user => {
    return {
        value: user.uid,
        label: `${user.name} ${user.surname}`,
    };
};

export const generateOptions = users => {
    if (!users.length) {
        return [];
    }

    const mappedOptions = [allUsersOption, ...users].map(mapUserToPicker);

    return mappedOptions;
};

export const isAllUsersOptionPresent = users => {
    return users.some(user => user.value === allUsersOption.uid);
};

export const areAllUsersSelected = (users, selectedUsers) => {
    return users.length === selectedUsers.length;
};

export const findSelectedUserInUsers = (users, selectedUser) => {
    return users.find(user => user.uid === selectedUser.value);
};
