import React from 'react';
import {ListFilterTemplate} from '../../../../../common/components/index';
import {Checkbox} from '../../../../../common/components/index';
import './WarrantyFilter.css';

const WarrantyFilter = props => (
    <ListFilterTemplate
        onApplyFilter={props.onApplyFilter}
        onCancelFilter={props.onCancelFilter}
    >
        <Checkbox
            onChange={value => {
                props.onChange({
                    ...props.value,
                    checked: value.target.checked,
                });
            }}
            checked={props.value.checked}
            label={'wyświetl elementy z gwarancją'}
            className={'warranty-filter-checkbox'}
        />
        <Checkbox
            onChange={value =>
                props.onChange({
                    ...props.value,
                    unchecked: value.target.checked,
                })
            }
            checked={props.value.unchecked}
            label={'wyświetl elementy bez gwarancji'}
            className={'warranty-filter-checkbox'}
        />
    </ListFilterTemplate>
);

export default WarrantyFilter;
