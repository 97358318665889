import firebase from 'firebase/app';

const storage = firebase.storage();

export const uploadFile = (ref, file) => {
    return storage.ref(ref).put(file);
};

export const deleteFiles = paths => {
    const pathsWithoutCopies = [...new Set(paths)].filter(Boolean);

    if (pathsWithoutCopies) {
        return Promise.all(pathsWithoutCopies.map(deleteFile));
    }
};

export const deleteFile = path => storage.ref(path).delete();

export const getDownloadUrl = ref => storage.ref(ref).getDownloadURL();
