import {MuiThemeProvider} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import {MainTheme} from '../../utils/Themes/Themes';
import {CustomTextField, AddButton} from '../../common/components/index';
import styles from './LoginForm.module.css';

const loginForm = props => (
    <form className={styles.container}>
        <div className={styles.logoContainer}>
            <img
                className={styles.logo}
                src={require('../../static/images/logo/logo_small2x.png')}
                alt=""
            />
        </div>
        <div className={styles.title}>Zaloguj się</div>
        <MuiThemeProvider theme={MainTheme}>
            <CustomTextField
                type="email"
                placeholder="Wpisz swój e-mail"
                fullWidth={true}
                onChange={props.onLoginTextChange}
                label={'E-mail'}
                autoFocus
            />
            <div className={styles.textInput}>
                <div className={styles.passwordLabel}>
                    Hasło
                    <div
                        onClick={props.onChangePasswordClick}
                        className={styles.resetPasswordButton}
                    >
                        Zapomniałeś hasła?
                    </div>
                </div>
                <TextField
                    type="password"
                    placeholder="Wpisz swoje hasło"
                    fullWidth={true}
                    className={styles.passwordTextField}
                    onChange={props.onPasswordTextChange}
                    InputProps={{disableUnderline: true}}
                />
            </div>
        </MuiThemeProvider>
        <MuiThemeProvider theme={MainTheme}>
            <div className={styles.marginForButton}>
                {props.alert}
                <AddButton
                    className={styles.loginButton}
                    disabled={props.disabled}
                    onClick={event => {
                        event.preventDefault();
                        props.onLoginButtonClicked();
                    }}
                    type="submit"
                    text="Zaloguj się"
                />
            </div>
        </MuiThemeProvider>
    </form>
);

export default loginForm;
