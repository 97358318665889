import {statuses} from '../../constants/issues';
import {
    diffTimeInMin,
    MINS_PER_DAY,
    MINS_PER_THREE_DAYS,
} from '../date/dateUtils';

export const isOutdated = (highPriority, history, status) => {
    const statusChangeDate =
        status !== statuses.IN_REVIEW
            ? findDateOfLastStatusChange(history)
            : new Date();
    return highPriority
        ? diffTimeInMin(new Date(), statusChangeDate) > MINS_PER_DAY
        : diffTimeInMin(new Date(), statusChangeDate) > MINS_PER_THREE_DAYS;
};

const findDateOfLastStatusChange = history =>
    history.reduce((previousValue, currentValue) => {
        if (currentValue.changes.status && previousValue < currentValue.date) {
            return currentValue.date;
        }
    }, null);
