import {SET_LOCATIONS, SET_SELECTED_LOCATION} from './actionTypes';
import * as firebase from 'firebase/app';
import {
    fetchDocumentById,
    getItemFromDocumentAndPassToCallback,
} from '../../utils/firestoreDocumentUtils/firestoreDocumentUtils';
import * as Endpoints from '../../constants/endpoints';

const compareLocationsByName = (a, b) => {
    return a.name.localeCompare(b.name);
};

export const addLocation = (location, onSuccess, onError) => {
    return dispatch => {
        firebase
            .firestore()
            .collection(Endpoints.locations())
            .add(location)
            .then(result => {
                const key = result._key.path.segments[3];
                onSuccess(key);
            })
            .catch(error => {
                onError();
                console.error(error);
            });
    };
};

export const updateLocation = (location, key, onSuccess, onError) => {
    return dispatch => {
        firebase
            .firestore()
            .collection(Endpoints.locations())
            .doc(key)
            .update(location)
            .then(() => {
                onSuccess();
            })
            .catch(error => {
                onError();
                console.error(error);
            });
    };
};

export const deleteLocation = (locationId, onSuccess, onError) => {
    return dispatch => {
        firebase
            .firestore()
            .collection(Endpoints.locations())
            .doc(locationId)
            .delete()
            .then(result => {
                onSuccess();
            })
            .catch(error => {
                onError();
                console.error(error);
            });
    };
};

export const fetchLocations = (locations, setActivityIndicator) => {
    return dispatch => {
        setActivityIndicator && setActivityIndicator(true);
        locations = locations.sort(compareLocationsByName);
        setActivityIndicator && setActivityIndicator(false);
        dispatch(setLocations(locations));
    };
};

export const fetchLocationByDocumentId = documentId => {
    return dispatch => {
        const locationDocument = fetchDocumentById(
            Endpoints.locations(),
            documentId,
        );
        getItemFromDocumentAndPassToCallback(locationDocument, location => {
            dispatch(setSelectedLocation(location));
        });
    };
};

export const checkIfLocationExist = async location => {
    const querySnapshot = await firebase
        .firestore()
        .collection(Endpoints.locations())
        .where('name', '==', location.name)
        .where('branch.id', '==', location.branch.id)
        .get();
    return querySnapshot.docs.length !== 0;
};

export const checkIfLocationsExistsByBranch = async branch => {
    const querySnapshot = await firebase
        .firestore()
        .collection(Endpoints.locations())
        .where('branch.id', '==', branch.id)
        .get();
    return querySnapshot.docs.length !== 0;
};

export const fetchAllLocations = () => {
    return dispatch => {
        firebase
            .firestore()
            .collection('locations')
            .get()
            .then(collection => {
                if (!collection.empty) {
                    dispatch(fetchLocations(collection));
                } else {
                    console.log('Collection is empty');
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const setLocations = locations => {
    return {
        locations,
        type: SET_LOCATIONS,
    };
};

export const setSelectedLocation = location => {
    return {
        selectedLocation: location,
        type: SET_SELECTED_LOCATION,
    };
};
