import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {updateUser, deleteUser} from '../../store/action/user';
import {
    Box,
    CustomButton,
    CustomTextField,
    RolePicker,
    CustomCircularProgress,
} from '../../common/components/index';
import {USERS} from '../../constants/routes';
import styles from './UserDetailsBox.module.css';
import {NotificationContext} from '../../context/notifications';
import BranchPicker from '../../common/components/branchPicker/BranchPicker';
import isEqual from 'react-fast-compare';
import {roles} from '../../constants/roles';
import {menuPlacement} from '../../constants/menuPlacement';
import {TYPES} from '../../constants/error';
import PasswordManagerModal from '../../components/PasswordManagerModal/PasswordManagerModal';
import {getUserRole} from '../../store/action/authHelpers';

class UserDetailsBox extends Component {
    state = {
        name: '',
        surname: '',
        email: '',
        role: '',
        uid: null,
        branches: [],
        loading: false,
        passwordChangeModalVisible: false,
    };

    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        this.state.uid = this.props.user.uid;
        this.state.name = this.props.user.name;
        this.state.surname = this.props.user.surname;
        this.state.email = this.props.user.email;
        this.state.branches = this.props.user.branches;
        const role = Object.keys(this.props.user.roles)[0];
        this.state.role = role.toUpperCase();
    }

    componentDidMount() {
        this.notificationSystem = this.context;
    }

    render() {
        let {name, surname, email, role, loading, passwordChangeModalVisible} =
            this.state;
        const {user} = this.props;

        return (
            <>
                {loading && <CustomCircularProgress />}
                {this.isUserAdmin() && (
                    <PasswordManagerModal
                        open={passwordChangeModalVisible}
                        onClose={() =>
                            this.setState({passwordChangeModalVisible: false})
                        }
                        userId={user.uid}
                    />
                )}
                <Box className={styles.detailsBox}>
                    <div className={styles.userDetails}>
                        <CustomTextField
                            className={styles.textField}
                            label="Imię"
                            placeholder="Wpisz imię"
                            defaultValue={name}
                            onChange={this.onFieldValueChangeHandler('name')}
                        />
                        <CustomTextField
                            className={styles.textField}
                            label="Nazwisko"
                            placeholder="Wpisz nazwisko"
                            defaultValue={surname}
                            onChange={this.onFieldValueChangeHandler('surname')}
                        />
                        <CustomTextField
                            className={styles.textField}
                            label="Email"
                            placeholder="Wpisz email"
                            defaultValue={email}
                            onChange={this.onFieldValueChangeHandler('email')}
                        />
                        <RolePicker
                            selectedRole={role}
                            handleChange={this.onRoleChangeHandler}
                            menuPlacement={menuPlacement.TOP}
                        />
                        <BranchPicker
                            handleChange={this.onBranchChange}
                            branches={this.props.branches}
                            selectedBranches={this.state.branches}
                            menuPlacement={menuPlacement.TOP}
                            multi
                        />
                    </div>
                    <section className={styles.buttons}>
                        <CustomButton
                            className={`${styles.btn} ${styles.deleteBtn}`}
                            text="Usuń użytkownika"
                            contained={false}
                            onClick={this.deleteUser}
                            disabled={loading}
                        />
                        {this.isUserAdmin() && (
                            <CustomButton
                                className={`${styles.btn} ${styles.changePasswordBtn}`}
                                text="Zmień hasło"
                                contained={false}
                                onClick={() =>
                                    this.setState({
                                        passwordChangeModalVisible: true,
                                    })
                                }
                                disabled={loading}
                            />
                        )}
                        <CustomButton
                            className={`${styles.btn} ${styles.saveBtn}`}
                            text="Zapisz zmiany"
                            disabled={loading || this.isNotChangedOrEmpty()}
                            onClick={this.updateUser}
                        />
                    </section>
                </Box>
            </>
        );
    }

    isUserAdmin = () => getUserRole() === roles.ADMIN;

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    onFieldValueChangeHandler = field => event => {
        this.setState({[field]: event.target.value});
    };

    onRoleChangeHandler = change => {
        const role = change.value;
        const branches =
            role === roles.ADMIN ? this.props.branches : this.state.branches;
        this.setState({role, branches});
    };

    onBranchChange = selectedBranches => {
        selectedBranches = selectedBranches || [];
        const branches = selectedBranches.map(branch => ({
            id: branch.value,
            name: branch.label,
        }));
        this.setState({branches});
    };

    updateUser = () => {
        this.setState({loading: true});
        const {name, surname, email, role, branches} = this.state;
        const userToSet = {
            name,
            surname,
            email,
            branches,
            roles: {[role.toLowerCase()]: true},
        };
        this.props.updateUser(
            this.state.uid,
            userToSet,
            () => {
                this.setState({loading: false});
                this.showNotification('Zaktualizowano użytkownika', 'success');
                this.props.history.push(USERS);
            },
            () => {
                this.setState({loading: false});
                this.showNotification(
                    'Edycja użytkownika nie powiodła się',
                    TYPES.error,
                );
            },
        );
    };

    deleteUser = () => {
        this.setState({loading: true});
        this.props.deleteUser(
            this.state.uid,
            () => {
                this.setState({loading: false});
                this.showNotification('Użytkownik usunięty', 'success');
                this.props.history.push(USERS);
            },
            error => {
                this.setState({loading: false});
                this.showNotification(error, TYPES.error);
            },
        );
    };

    isNotChangedOrEmpty = () =>
        (this.state.name === this.props.user.name &&
            this.state.surname === this.props.user.surname &&
            this.state.email === this.props.user.email &&
            isEqual(this.state.branches, this.props.user.branches) &&
            this.state.role.toLowerCase() ===
                Object.keys(this.props.user.roles)[0]) ||
        !this.state.name ||
        !this.state.surname ||
        !this.state.email ||
        !this.state.branches.length;
}

const mapStateToProps = store => ({
    branches: store.branch.branches,
    userData: store.auth.userData,
});

const mapDispatchToProps = dispatch => ({
    deleteUser: deleteUser(dispatch),
    updateUser: updateUser(dispatch),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserDetailsBox),
);
