import {SET_ELEMENTS} from './actionTypes';
import firebase from 'firebase/app';
import {elements, elementImages} from '../../constants/endpoints';
import {
    uploadFile,
    deleteFiles,
    deleteFile,
} from '../../utils/storageUtils/storageUtils';
import {hashFilename} from './utilHelper';
import {elementConverter} from '../../utils/converter/elementConverter';
import {downloadImagesAndSetElements} from './photos';

const firestore = firebase.firestore();

export const setElements = elements => {
    return {
        elements,
        type: SET_ELEMENTS,
    };
};

export const fetchElements = (elements, setActivityIndicator) => {
    return dispatch => {
        setActivityIndicator && setActivityIndicator(true);
        elements = elements.sort((e1, e2) => e1.name > e2.name);
        dispatch(
            downloadImagesAndSetElements(
                elements,
                setElements,
                () => setActivityIndicator && setActivityIndicator(false),
            ),
        );
    };
};

export const putNewElement = (element, newIconFile, newFiles) => {
    return async dispatch => {
        const elementIconData = newIconFile
            ? await uploadElementIcon(newIconFile, element.key)
            : {patternImagePath: null, iconImagePath: null};
        const elementFiles = newFiles
            ? await uploadFiles(newFiles, element.key)
            : {files: null};

        const elementData = {...element, ...elementIconData, ...elementFiles};
        return firestore.collection(elements()).doc().set(elementData);
    };
};

export const deleteElement = element => {
    return dispatch => {
        removeElementIcon(element);
        removeElementFiles(element);

        return firestore.collection(elements()).doc(element.key).delete();
    };
};

const removeElementFiles = element => {
    element.files.forEach(file => deleteFile(file.path));
};

export const updateElement = (
    element,
    newIconFile,
    removeOldIcon,
    newFiles,
) => {
    return async dispatch => {
        if (removeOldIcon) {
            removeElementIcon(element);
        }

        newFiles.removed.forEach(file => deleteFile(file.path));

        const elementFiles =
            newFiles.new && (await uploadFiles(newFiles.new, element.key));

        newFiles.uploaded.forEach(file => elementFiles.files.push(file));

        const elementIconData =
            newIconFile && (await uploadElementIcon(newIconFile, element.key));

        const elementToSet = {
            ...element,
            ...elementIconData,
            ...elementFiles,
        };
        delete elementToSet.key;

        return firestore
            .collection(elements())
            .doc(element.key)
            .update(elementToSet);
    };
};

const uploadElementIcon = (iconFile, elementId) => {
    const path = `${elementImages()}/${elementId}/${hashFilename(iconFile)}`;

    return uploadFile(path, iconFile).then(() => ({
        patternImagePath: path,
        iconImagePath: path,
    }));
};

const uploadFiles = (files, elementId) => {
    const filesTable = files.map(file => {
        const path = `${elementImages()}/${elementId}/${hashFilename(file)}`;
        uploadFile(path, file);
        return {name: file.name, path};
    });
    return {files: filesTable};
};

const removeElementIcon = element => {
    const {patternImagePath, iconImagePath} = element;

    element.patternImagePath = null;
    element.iconImagePath = null;
    deleteFiles([patternImagePath, iconImagePath]);
};

export const getElementsByLocationId = locationId => {
    return dispatch => {
        firebase
            .firestore()
            .collection(elements())
            .withConverter(elementConverter)
            .where('location.id', '==', locationId)
            .get()
            .then(collection => {
                if (!collection.empty) {
                    const elements = collection.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    dispatch(
                        downloadImagesAndSetElements(
                            elements,
                            setElements,
                            () => {},
                        ),
                    );
                } else {
                    dispatch(setElements([]));
                    console.log('No elements found for specified element');
                }
            })
            .catch(error => {
                dispatch(setElements([]));
                console.log(error);
            });
    };
};
