import React from 'react';
import {ListItem} from '../../../../common/components/index';
import ListItemMobile from '../../../../common/components/list/listItem/ListItemMobile/ListItemMobile';
import MobileDataDisplay from '../../../../common/components/list/listItem/display/mobileDataDisplay/moblieDataDisplay';

export const createUserListTemplate = (disabled, onClick) => user => {
    const role = Object.keys(user.roles)[0];
    return (
        <ListItem key={user.uid} onClick={() => !disabled && onClick(user.uid)}>
            {`${user.name} ${user.surname}`}
            {user.email}
            {role}
        </ListItem>
    );
};

export const createMobileUserListItemTemplate = (disabled, onClick) => user => {
    const role = Object.keys(user.roles)[0];
    return (
        <ListItemMobile
            key={user.uid}
            onClick={() => !disabled && onClick(user.uid)}
        >
            <MobileDataDisplay
                title={`${user.name} ${user.surname}`}
                left={role}
                withoutImage
            />
        </ListItemMobile>
    );
};
