import React from 'react';
import Dialog from '../../common/components/Dialog/Dialog';
import {CustomTextField} from '../../common/components/';

const changePasswordDialog = props => (
    <Dialog
        dialogTitle={'Zmiana hasła'}
        loading={props.loading}
        handleClose={props.onClose}
        submitButtonDisabled={props.submitButtonDisabled}
        onSubmitHandler={props.onSubmit}
        submitButtonText={'Wyślij e-mail'}
    >
        <CustomTextField label="Email" onChange={props.onChange} fullWidth />
    </Dialog>
);

export default changePasswordDialog;
