import firebase from 'firebase/app';
import * as endpoints from '../../constants/endpoints';

export const updateBranch = (branchId, branch) =>
    firebase
        .firestore()
        .collection(endpoints.branches())
        .doc(branchId)
        .update(branch);

export const addBranch = branch =>
    firebase.firestore().collection(endpoints.branches()).add(branch);

export const deleteBranch = branch =>
    firebase
        .firestore()
        .collection(endpoints.branches())
        .doc(branch.id)
        .delete();

export const fetchUsersWithBranch = branch =>
    firebase
        .firestore()
        .collection('user')
        .where('branches', 'array-contains', branch)
        .get();

export const deleteBranchInUser = (uid, branch) =>
    firebase
        .firestore()
        .collection('user')
        .doc(uid)
        .update({
            branches: firebase.firestore.FieldValue.arrayRemove(branch),
        });

export const deleteBranchFromUsers = branch =>
    fetchUsersWithBranch(branch).then(res => {
        if (res.size > 0) {
            res.docs.forEach(doc => {
                const {uid} = doc.data();
                if (uid) {
                    deleteBranchInUser(uid, branch);
                }
            });
        }
    });
