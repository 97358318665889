class ListColumnCreator {
    id;
    label;
    sorting;
    filterElementGetter;
    customComparator;
    accessAttribute;

    constructor(id, label, options) {
        const {
            sorting = true,
            filterElementGetter = null,
            customComparator = undefined,
            accessAttribute = this.defaultAccessAttribute,
        } = options;
        this.id = id;
        this.label = label;
        this.sorting = sorting;
        this.filterElementGetter = filterElementGetter;
        this.customComparator = customComparator;
        this.accessAttribute = accessAttribute;
    }

    defaultAccessAttribute = item => item[this.id];
}

export default ListColumnCreator;
