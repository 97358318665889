import React from 'react';

const cyclicIcon = ({active}) =>
    active ? (
        <img
            src={require('../../../../../static/images/icons/check-circle.svg')}
            width={15}
            alt="cykliczne"
        />
    ) : (
        <img
            src={require('../../../../../static/images/icons/remove.svg')}
            width={15}
            alt="Niecykliczne"
        />
    );

export default cyclicIcon;
