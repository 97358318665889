import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import {statuses} from '../../constants/issues';
import {REPORT_TYPES} from '../../constants/reports';

export const generateArchivedIssuesReport = (
    startDate,
    endDate,
    reportType,
    issuesForReport,
) => {
    const document = createArchivedIssuesReport(
        issuesForReport,
        reportType,
        startDate.format('DD/MM/YYYY'),
        endDate.format('DD/MM/YYYY'),
    );
    if (document.content) {
        generatePDF(
            document,
            startDate.format('DD/MM/YYYY'),
            endDate.format('DD/MM/YYYY'),
        );
    }
};

const generatePDF = (document, startDate, endDate) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(document).download(`report  ${startDate}  ${endDate}`);
};

const createArchivedIssuesReport = (
    issuesForReport,
    reportType,
    startDate,
    endDate,
) => {
    let documentDefinition = {
        content: [],
        pageOrientation:
            reportType === REPORT_TYPES.detailed ? 'landscape' : 'portrait',
    };
    const title = 'Wykaz prac wykonanych między ' + startDate + ' a ' + endDate;
    documentDefinition = addHeader(documentDefinition, title);
    const tableRows = createTableRowsForArchivedIssues(
        issuesForReport,
        reportType,
    );
    documentDefinition = addTableToDocument(
        documentDefinition,
        tableRows,
        reportType,
    );
    return documentDefinition;
};

const createTableRowsForArchivedIssues = (issuesForReport, reportType) => {
    const places = Object.entries(issuesForReport);
    const reportTypeIsDetailedIssues = reportType === REPORT_TYPES.detailed;
    let tableRows = [
        [
            {text: 'Element', style: styles.tableHeader},
            {text: 'Opis', style: styles.tableHeader},
            {text: 'Data dodania', style: styles.tableHeader},
        ],
    ];
    if (reportTypeIsDetailedIssues)
        tableRows[0] = [
            ...tableRows[0],
            {text: 'Pracownik', style: styles.tableHeader},
            {text: 'Czas reakcji', style: styles.tableHeader},
            {text: 'Czas wykonania', style: styles.tableHeader},
        ];
    places.forEach((place, placeIndex) => {
        const placeKey = place[0];
        const placeValue = place[1];
        let index = 0;
        const placeRow = [
            {
                text: placeKey,
                style: styles.tableSubHeader,
                colSpan: reportTypeIsDetailedIssues ? 6 : 3,
            },
        ];
        tableRows.push(placeRow);
        const items = Object.entries(placeValue);
        items.forEach((item, itemIndex) => {
            const itemKey = item[0];
            const issues = item[1];
            const subTable = [];
            issues.forEach((issue, issueIndex) => {
                const user = issue.assignedTo
                    ? `${issue.assignedTo.name} ${issue.assignedTo.surname}`
                    : 'Brak';
                const reactionDuration = calcDurations(issue.history);
                const issueRow = reportTypeIsDetailedIssues
                    ? [
                          '',
                          issue.issueDescription,
                          moment(issue.createdDate).format('DD/MM/YYYY'),
                          user,
                          reactionDuration.reaction,
                          reactionDuration.execution,
                      ]
                    : [
                          '',
                          issue.issueDescription,
                          moment(issue.createdDate).format('DD/MM/YYYY'),
                      ];
                index = issueIndex;
                subTable.push(issueRow);
            });
            const itemRow = [
                {text: itemKey, rowSpan: index + 1},
                ...subTable[0].slice(1),
            ];
            tableRows.push(itemRow);
            subTable.shift();
            tableRows = [...tableRows, ...subTable];
        });
    });
    return tableRows;
};

const calcDurations = issueHistory => {
    let openDate = '';
    let inProgressDate = '';
    let doneDate = '';
    issueHistory.forEach(history => {
        if (history.changes.status === statuses.OPEN) {
            openDate = history.date;
        } else if (history.changes.status === statuses.IN_PROGRESS) {
            inProgressDate = history.date;
        } else if (history.changes.status === statuses.DONE) {
            doneDate = history.date;
        }
    });
    const reaction = moment(inProgressDate).diff(moment(openDate), 'hours');
    const execution = moment(doneDate).diff(moment(inProgressDate), 'hours');
    const noProgressDuration = moment(doneDate).diff(moment(openDate), 'hours');
    if (inProgressDate)
        return {
            reaction: stringForDuration(reaction, openDate, inProgressDate),
            execution: stringForDuration(execution, inProgressDate, doneDate),
        };
    else {
        return {
            reaction: 'Brak danych',
            execution: stringForDuration(
                noProgressDuration,
                openDate,
                doneDate,
            ),
        };
    }
};

const stringForDuration = (duration, fromDate, date) =>
    duration > 0
        ? `${duration} godz`
        : `${moment(date).diff(moment(fromDate), 'minutes')} min`;

const addHeader = (document, header) => {
    document.content = [
        {text: header, style: styles.header},
        ...document.content,
    ];
    return document;
};

const addTableToDocument = (document, rows, reportType) => {
    const table = {
        table: {
            widths:
                reportType === REPORT_TYPES.detailed
                    ? ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']
                    : ['auto', 'auto', 'auto'],
            body: rows,
        },
    };
    document.content = [...document.content, table];
    return document;
};

const styles = {
    header: {
        bold: true,
        fontSize: 18,
        margin: [0, 0, 0, 10],
    },
    subheader: {
        bold: true,
        fontSize: 16,
        margin: [0, 10, 0, 5],
    },
    tableExample: {
        margin: [0, 5, 0, 15],
    },
    tableHeader: {
        bold: true,
        color: 'black',
        fontSize: 13,
    },
    tableSubHeader: {
        color: 'black',
        fillColor: '#EEEEEE',
        fontSize: 13,
    },
};
