import Switch from '@material-ui/core/Switch';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import styles from './Switch.module.css';

export const switchField = ({switchComponent, label}) => (
    <div className={styles.switch}>
        <label>{label}</label>
        {switchComponent}
    </div>
);

export const IOSSwitch = withStyles(theme => ({
    root: {
        width: 30,
        height: 18,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: 'var(--green)',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: 'var(--green)',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 16,
        height: 16,
    },
    track: {
        borderRadius: 18 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({classes, ...props}) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export const IOSSwitchBigger = withStyles(theme => ({
    root: {
        width: 42,
        height: 25,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(17px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: 'var(--green)',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: 'var(--green)',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 23,
        height: 23,
    },
    track: {
        borderRadius: 23 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({classes, ...props}) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            checked={props.checked}
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
