import {createHash as hash} from 'crypto';

export const hashFilename = file => {
    const [name, type] = file.name.split('.');
    return `${hash('md5')
        .update(name + file.size + file.lastModified)
        .digest('hex')}.${type}`;
};

export const mapPhotosToPhotosData = photos => {
    const photosData = photos.map(photo => ({
        fileName: hashFilename(photo),
        fileSize: photo.size,
        timestamp: new Date().getTime(),
    }));
    return photosData;
};

export const createPromisesToUploadPhotosAndGetPath = (photos, storageRef) =>
    photos.map(
        photo =>
            new Promise((resolve, reject) => {
                storageRef
                    .child(hashFilename(photo))
                    .put(photo)
                    .then(result => resolve(result.metadata.fullPath))
                    .catch(reject);
            }),
    );

export const removeNullFieldsFromObject = object => {
    Object.keys(object).forEach(key => {
        if (object[key] === undefined) {
            delete object[key];
        }
    });
};
