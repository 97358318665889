import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import classNames from 'classnames';
import styles from './CustomTextField.module.css';

const customTextField = ({
    className,
    label,
    icon,
    disabled,
    first,
    type,
    maxLength,
    ...props
}) => (
    <div
        className={classNames(styles.textFieldContainer, {
            [styles.noTopMargin]: first,
        })}
    >
        {label}
        <div className={styles.textField}>
            {icon}
            <TextField
                margin="normal"
                disabled={disabled}
                className={classNames(styles.textInput, className)}
                InputProps={{disableUnderline: true, rows: 4}}
                inputProps={{color: 'var(--dark-grey)'}}
                type={type}
                maxLength={maxLength}
                {...props}
            />
        </div>
    </div>
);

export default customTextField;
