import React, {Component} from 'react';
import {isEqual} from 'lodash';
import {
    List,
    ListItem,
    ListColumnCreator,
    MobileDataDisplay,
} from '../../../common/components/index';
import MobileList from '../../../common/components/list/MobileList';
import MobileListItem from '../../../common/components/list/listItem/ListItemMobile/ListItemMobile';
import {connect} from 'react-redux';
import {checkIfElementsExists} from '../../../utils/firestoreDocumentUtils/firestoreDocumentUtils';

class LocationsList extends Component {
    state = {
        rows: [],
    };

    columns;

    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.locations,
        };
        this.columns = [
            new ListColumnCreator('name', 'Nazwa lokalizacji', {}),
            new ListColumnCreator('branchName', 'Oddział', {
                accessAttribute: location => location.branch.name,
            }),
        ];
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.locations, prevProps.locations)) {
            this.setState({rows: this.props.locations});
        }
    }

    render() {
        const {rows} = this.state;

        return (
            <>
                <List
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getLocationListItemTemplate}
                />
                <MobileList
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getLocationMobileListItemTemplate}
                />
            </>
        );
    }

    getLocationListItemTemplate = location => {
        const {onLocationClick, onRemoveLocation, userData} = this.props;
        return (
            <ListItem
                key={location.id}
                onClick={onLocationClick(location.id)}
                onDelete={() => onRemoveLocation(location.id)}
                deletable={userData.roles.admin}
            >
                <div className="element-name">{location.name}</div>
                {location.branch.name}
            </ListItem>
        );
    };

    getLocationMobileListItemTemplate = location => {
        const {onLocationClick, onRemoveLocation, userData} = this.props;
        return (
            <MobileListItem
                key={location.id}
                onClick={onLocationClick(location.id)}
                onDelete={() => onRemoveLocation(location.id)}
                deletable={userData.roles.admin}
            >
                <MobileDataDisplay title={location.name} withoutImage />
            </MobileListItem>
        );
    };
}

const mapStateToProps = state => ({
    elements: state.element.elements,
    userData: state.auth.userData,
});

export default connect(mapStateToProps)(LocationsList);
