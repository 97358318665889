import React from 'react';

import styles from './ElementDetailsBoxLayout.module.css';
import {
    Box,
    CustomCircularProgress,
    CustomTextField,
    ImageDropzone,
    FilesDropzone,
    LocationsPicker,
    CustomButton,
} from '../../common/components';
import classNames from 'classnames';
import CustomDatePicker from '../../common/components/CustomDatePicker/CustomDatePicker';

const elementDetailsBoxLayout = ({
    innerComponents: [DeleteButton, SaveButton],
    roleProps: {inputsDisabled},
    props,
}) => (
    <div className={styles.root}>
        {props.loading && <CustomCircularProgress />}
        <Box key={props.key} className={styles.elementDetailsBox}>
            <section className={styles.buttons}>
                {DeleteButton({
                    className: classNames(styles.button, styles.deleteButton),
                    text: 'Usuń element',
                    contained: false,
                    onClick: props.deleteElement,
                })}
                {SaveButton({
                    className: classNames(styles.button, styles.saveButton),
                    text: 'Zapisz zmiany',
                    disabled: !props.isSaveButtonEnabled(),
                    onClick: props.updateElement,
                })}
            </section>
            <div className={styles.container}>
                <LocationsPicker
                    selectedLocation={props.location}
                    handleChange={props.onLocationChange}
                    locations={props.locations}
                    disabled={inputsDisabled}
                    controlled
                />
                <CustomTextField
                    className={styles.textField}
                    label="Nazwa elementu"
                    placeholder="Wpisz nazwę elementu"
                    defaultValue={props.name}
                    disabled={inputsDisabled}
                    onChange={props.onTextChangeHandler('name')}
                />
                <CustomDatePicker
                    label={'Data dodania'}
                    value={props.createdDate}
                    readOnly
                />
                <CustomDatePicker
                    label={'Termin gwarancji'}
                    value={props.warrantyTo}
                    onChange={props.onElementWarrantyChange}
                    readOnly={inputsDisabled}
                />
                <CustomTextField
                    className={styles.textField}
                    label="Opis elementu"
                    placeholder="Wpisz opis elementu"
                    disabled={inputsDisabled}
                    defaultValue={props.description}
                    onChange={props.onTextChangeHandler('description')}
                />
                <ImageDropzone
                    label="Zdjęcie elementu"
                    imagesSrc={props.iconUri}
                    onDrop={props.onIconSelectHandler}
                    disabled={inputsDisabled}
                    onRemoveImage={props.onIconRemoveHandler}
                />
                <FilesDropzone
                    filesSrc={[
                        ...props.filesDropped.new.map(file => ({
                            path: URL.createObjectURL(file),
                            name: file.name,
                        })),
                        ...props.filesDropped.uploaded,
                    ]}
                    onDrop={props.onFileDrop}
                    onRemoveFile={props.onFileRemove}
                    label={'Pliki dodatkowe'}
                />
                <CustomButton
                    text={'Wygeneruj QR kod'}
                    onClick={props.onGenerateQRCode}
                />
            </div>
        </Box>
    </div>
);

export default elementDetailsBoxLayout;
