import React from 'react';
import DrawerItems from '../DrawerItems/DrawerItems';
import {withUserRole} from '../../../hoc/User/WithUserRole';
import {drawerItemsForRole} from '../DrawerItems/drawerItemsForRole';
import './NavBar.css';

const navBar = () => (
    <div className="navBar">
        {withUserRole(DrawerItems, drawerItemsForRole)}
    </div>
);

export default navBar;
