import React from 'react';
import styles from './LogoutButton.module.css';

const logoutButton = props => (
    <div
        className={styles.logoutButton}
        style={{color: 'var(--dark-grey)', textTransform: 'none'}}
        onClick={props.logOutHandler}
        color="inherit"
    >
        <img
            className={styles.draverIcon}
            alt=""
            src={require('../../static/images/icons/logout-1.svg')}
        />
        Wyloguj się
    </div>
);

export default logoutButton;
