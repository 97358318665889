import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducer/auth';
import elementsReducer from './reducer/element';
import issuesReducer from './reducer/issue';
import reportReducer from './reducer/report';
import locationReducer from './reducer/location';
import userReducer from './reducer/user';
import taskReducer from './reducer/tasks';
import branchReducer from './reducer/branch';

const rootReducer = combineReducers({
    auth: authReducer,
    element: elementsReducer,
    issue: issuesReducer,
    report: reportReducer,
    location: locationReducer,
    user: userReducer,
    task: taskReducer,
    branch: branchReducer,
});

const composeEnhancers =
    process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
        : compose;

const configureStore = () =>
    createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default configureStore;
