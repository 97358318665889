import {initializeApp} from 'firebase/app';
import 'firebase/firebase-storage';
import 'firebase/firebase-firestore';
import 'firebase/firebase-auth';
import {database} from '../package.json';

const databaseType = Object.keys(database).find(field => database[field]);
const config = {
    develop: {
        apiKey: 'AIzaSyCkm4zWTR9tB2MB_k8Ldy23WQP5Md-gnUE',
        authDomain: 'usterka-dev-v2.firebaseapp.com',
        databaseURL: 'https://usterka-dev-v2.firebaseio.com',
        projectId: 'usterka-dev-v2',
        storageBucket: 'usterka-dev-v2.appspot.com',
        messagingSenderId: '395486507679',
        appId: '1:395486507679:web:266fb4d967aeb184',
    },
    demo: {
        apiKey: 'AIzaSyD8DGxOjeG_MH6A0cbjEAcUhPmx6Fk5240',
        authDomain: 'usterkav2-demo.firebaseapp.com',
        databaseURL: 'https://usterkav2-demo.firebaseio.com',
        projectId: 'usterkav2-demo',
        storageBucket: 'usterkav2-demo.appspot.com',
        messagingSenderId: '569520143470',
        appId: '1:569520143470:web:16ebd10028345c8753fa7f',
        measurementId: 'G-26LV5Q8N6P',
    },
    production: {
        apiKey: 'AIzaSyA5dufByxfrJ7DnrRzjDaZcw0KutbnbllY',
        authDomain: 'usterka-prod-v2.firebaseapp.com',
        databaseURL: 'https://usterka-prod-v2.firebaseio.com',
        projectId: 'usterka-prod-v2',
        storageBucket: 'usterka-prod-v2.appspot.com',
        messagingSenderId: '647244717006',
        appId: '1:647244717006:web:d5290362e5ce6f102d299f',
        measurementId: 'G-3MW9QLVHE8',
    },
};

initializeApp(config[databaseType]);
