import React from 'react';
import {CustomCircularProgress, ListActionPanel} from '../../common/components';

const ElementsPageLayout = ({
    innerComponents: [AddElementDialog],
    roleProps: {showAddButton},
    props,
}) => (
    <div>
        {props.actionPending && <CustomCircularProgress />}
        <ListActionPanel
            searchPlaceholder={'szukaj elementów'}
            title={'Lista elementów'}
            addButtonText="Dodaj element"
            onSearchTextChange={props.onSearchTextChange}
            onToggleAddDialogHandler={props.onToggleAddDialogHandler}
            showAddButton={showAddButton}
        />
        {AddElementDialog({
            dialogTitle: 'Dodaj element',
            locations: props.locations,
            open: props.addDialogOpen,
            handleClose: props.onToggleAddDialogHandler,
            onElementSubmit: props.onElementSubmit,
            loading: props.actionPending,
        })}
        <div>{props.elementsList}</div>
    </div>
);

export default ElementsPageLayout;
