import React, {Component} from 'react';
import Dialog from '../../../common/components/Dialog/Dialog';
import {CustomTextField} from '../../../common/components/index';
import BranchPicker from '../../../common/components/branchPicker/BranchPicker';
import {connect} from 'react-redux';

class AddLocationDialog extends Component {
    state = {
        newLocationName: '',
        branch: null,
        dialogRef: null,
    };

    constructor(props) {
        super(props);
        if (props.branches.length === 1) {
            this.state.branch = this.props.branches[0];
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.branches.length && this.props.branches.length === 1) {
            this.setState({branch: this.props.branches[0]});
        }
    }

    render() {
        const {dialogTitle, handleClose} = this.props;
        const {newLocationName, branch} = this.state;
        const submitButtonDisabled = !newLocationName || !branch;

        return (
            <Dialog
                ref={dialogRef =>
                    !this.state.dialogRef && this.setState({dialogRef})
                }
                loading={false}
                handleClose={handleClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={submitButtonDisabled}
                onSubmitHandler={this.onSubmitNewLocationHandler}
                submitButtonText={dialogTitle}
            >
                <BranchPicker
                    handleChange={this.onBranchChange}
                    selectedBranch={branch}
                    branches={this.props.branches}
                    menuPortalTarget={this.state.dialogRef}
                />
                {this.getTextField(
                    'Nazwa lokalizacji',
                    this.onLocationNameChange,
                )}
            </Dialog>
        );
    }

    getTextField(topLabel, onChange) {
        return (
            <CustomTextField
                label={topLabel}
                onChange={onChange}
                fullWidth={true}
            />
        );
    }

    onLocationNameChange = event => {
        this.setState({newLocationName: event.target.value});
    };

    onBranchChange = ({value, label}) => {
        this.setState({branch: {id: value, name: label}});
    };

    onSubmitNewLocationHandler = () => {
        const {branch, newLocationName} = this.state;
        const locationToSet = {
            name: newLocationName.trim(),
            branch,
        };
        this.props.onAddLocation(locationToSet);
        this.props.handleClose();
    };
}

const mapStateToProps = store => ({
    branches: store.branch.branches,
});

export default connect(mapStateToProps)(AddLocationDialog);
