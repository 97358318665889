import {
    AUTH_SET_USER_DATA,
    AUTH_SET_ORGANIZATION_DATA,
} from '../action/actionTypes';

const initialState = {
    userData: null,
    organizationData: {
        reporterCanSeeAllIssues: false,
        importantFlagOnIssueHidden: true,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SET_USER_DATA:
            return {
                ...state,
                userData: action.userData,
            };
        case AUTH_SET_ORGANIZATION_DATA:
            return {
                ...state,
                organizationData: action.organizationData,
            };
        default:
            return state;
    }
};

export default reducer;
