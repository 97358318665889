import {GET_USERS} from '../../constants/CloudFunctions';
import axios from '../../config/axios/axiosConfig';
import {isEmptyArray} from '../array/array';

const getUsersQuery = () => UsersQueryBuilder.query();

export class UsersQueryBuilder {
    _queryConfig = {params: {}};

    static query() {
        return new UsersQueryBuilder();
    }

    withRoles(roles) {
        this._queryConfig.params.roles = roles;
        return this;
    }

    withBranch(branch) {
        return this.withBranches([branch]);
    }

    withBranches(branches) {
        this._queryConfig.params.branches = branches;
        return this;
    }

    get() {
        return isEmptyArray(this._queryConfig.params.branches)
            ? Promise.resolve([])
            : axios
                  .get(GET_USERS, this._queryConfig)
                  .then(response => response.data);
    }
}

export default getUsersQuery;
