import firebase from 'firebase/app';
import {downloadIconsAndSetItems} from './index';
import {SET_ARCHIVED_ISSUES, SET_ISSUES} from './actionTypes';
import {
    createPromisesToUploadPhotosAndGetPath,
    removeNullFieldsFromObject,
} from './utilHelper';
import {removePhotos} from './photos';
import * as Endpoints from '../../constants/endpoints';
import {statuses} from '../../constants/issues';
import {addHistoryEntry} from '../../utils/history/historyUtils';
import axios from '../../config/axios/axiosConfig';
import {UPDATE_ISSUE} from '../../constants/CloudFunctions';

const setIssues = issues => {
    return {
        issues,
        type: SET_ISSUES,
    };
};

const setArchivedIssues = archivedIssues => {
    return {
        archivedIssues,
        type: SET_ARCHIVED_ISSUES,
    };
};

export const fetchIssues = (issues, archived, setActivityIndicator) => {
    return dispatch => {
        setActivityIndicator && setActivityIndicator(true);
        const setIssuesFunc = archived ? setArchivedIssues : setIssues;
        dispatch(
            downloadIconsAndSetItems(
                issues,
                issue => issue.element.iconImagePath,
                setIssuesFunc,
                () => setActivityIndicator && setActivityIndicator(false),
            ),
        );
    };
};

export const onCreateNewIssue =
    (dispatch, getState) =>
    async (newIssue, element, assignedTo, imageFiles, onSuccess, onError) => {
        const photos = imageFiles.filter(photo => photo != null);
        try {
            const newIssueDocRef = firebase
                .firestore()
                .collection(Endpoints.issues())
                .doc();
            const storagePath = Endpoints.issuesImages() + newIssueDocRef.id;
            const storageRef = firebase.storage().ref().child(storagePath);

            const photosUploadPromises = createPromisesToUploadPhotosAndGetPath(
                photos,
                storageRef,
            );
            const photoPaths = await Promise.all(photosUploadPromises);
            const newFullIssue = prepareNewFullIssue(
                newIssue,
                element,
                assignedTo,
                getState().auth.userData,
                photos,
                photoPaths,
            );
            await newIssueDocRef.set(newFullIssue);
            onSuccess();
        } catch (error) {
            console.error('cannot create new issue ' + error);
            onError();
        }
    };

const prepareNewFullIssue = (
    newIssue,
    element,
    assignedTo,
    reporter,
    issuePhotosData,
    photoPaths,
) => {
    const history = [];
    addHistoryEntry(history, {status: statuses.OPEN});
    return {
        ...newIssue,
        element,
        assignedTo: assignedTo && {
            name: assignedTo.name,
            surname: assignedTo.surname,
            uid: assignedTo.uid,
            roles: assignedTo.roles,
        },
        reporter: {
            name: reporter.name,
            surname: reporter.surname,
            uid: reporter.uid,
        },
        createdDate: new Date(),
        lastModified: new Date(),
        solutionDescription: '',
        history,
        status: statuses.OPEN,
        issueImagePaths: photoPaths,
        solutionImagePaths: [],
    };
};

export const updateIssue =
    (dispatch, getState) =>
    async (
        issue,
        oldIssue,
        issuePhotos,
        solutionPhotos,
        onSuccess,
        onError,
    ) => {
        removeNullFieldsFromObject(issue);
        issue.lastModified = new Date();
        updateIssueHistory(oldIssue, issue);
        const storagePath = Endpoints.issuesImages() + issue.id;
        const storageRef = firebase.storage().ref().child(storagePath);
        try {
            const uploadIssuePhotos = createPromisesToUploadPhotosAndGetPath(
                issuePhotos.new,
                storageRef,
            );
            const uploadSolutionPhotos = createPromisesToUploadPhotosAndGetPath(
                solutionPhotos.new,
                storageRef,
            );
            const issueNewImagePaths = await Promise.all(uploadIssuePhotos);
            const solutionNewImagePaths = await Promise.all(
                uploadSolutionPhotos,
            );
            issue.issueImagePaths = [
                ...issuePhotos.uploaded.map(photo => photo.fileName),
                ...issueNewImagePaths,
            ];
            issue.solutionImagePaths = [
                ...solutionPhotos.uploaded.map(photo => photo.fileName),
                ...solutionNewImagePaths,
            ];
            await Promise.all(removePhotos(issuePhotos.removed));
            await Promise.all(removePhotos(solutionPhotos.removed));
            await uploadIssue(issue, oldIssue, onError, onSuccess);
            onSuccess();
        } catch (err) {
            console.error(err);
            onError();
        }
    };

const updateIssueHistory = (oldIssue, newIssue) => {
    if (oldIssue.status !== newIssue.status) {
        addHistoryEntry(newIssue.history, {status: newIssue.status});
    }
};

const convertIssueDatesToString = issue => {
    issue.createdDate = issue.createdDate?.toISOString();
    issue.lastModified = issue.lastModified?.toISOString();
    issue.element.createdDate = issue.element.createdDate?.toISOString();
    issue.element.warrantyTo =
        issue.element.warrantyTo && issue.element.warrantyTo.toISOString();
    if (issue.closedDate) {
        issue.closedDate = issue.closedDate.toISOString();
    }
    issue.history.forEach(changes => {
        changes.date = changes.date.toISOString();
    });
    return issue;
};

const uploadIssue = (issue, oldIssue) => {
    const updatedIssue = {
        ...issue,
        element: {...issue.element},
    };
    const issueWithDateInString = convertIssueDatesToString(updatedIssue);
    const data = {
        issueWithDateInString,
        oldIssue,
    };
    return axios.post(UPDATE_ISSUE, data);
};

export const deleteIssue =
    (dispatch, getState) => async (issue, onSuccess, onError) => {
        try {
            const issueCollection = firebase
                .firestore()
                .collection(
                    issue.status === statuses.DONE
                        ? Endpoints.archivedIssue()
                        : Endpoints.issues(),
                );
            issueCollection.doc(issue.id).delete();
            const issuePhotos = issue.issueImagePaths.map(photo => ({
                fileName: photo,
            }));
            const solutionPhotos = issue.solutionImagePaths.map(photo => ({
                fileName: photo,
            }));
            await Promise.all(removePhotos(issuePhotos));
            await Promise.all(removePhotos(solutionPhotos));
            onSuccess();
        } catch (err) {
            console.error(err);
            onError();
        }
    };
