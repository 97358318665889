import React, {Component} from 'react';
import * as moment from 'moment/moment';
import {connect} from 'react-redux';
import Alert from '../../components/Alert/Alert';
import {
    fetchIssuesForReportAndGeneratePDF,
    fetchLocations,
    setUsers,
    fetchElements,
} from '../../store/action';
import ReportForm from '../../components/Reports/ReportForm/ReportForm';
import CircularProgress from '../../common/components/CustomCircularProgress/customCircularProgress';
import getLocationsQuery from '../../utils/queryBuilder/LocationQueryBuilder';
import getUsersQuery from '../../utils/queryBuilder/UsersQueryBuilder';
import getElementQuery from '../../utils/queryBuilder/ElementsQueryBuilder';
import {NotificationContext} from '../../context/notifications';
import {REPORT_TYPES, reportsForStore} from '../../constants/reports';
import {TYPES} from '../../constants/error';

class ReportsPage extends Component {
    state = {
        errorMessage: '',
        indicator: false,
        startDate: '',
        endDate: '',
        reportType: reportsForStore[0],
        user: null,
        location: null,
        element: null,
    };
    static contextType = NotificationContext;
    unsubscribeLocations;
    unsubscribeElements;
    notificationSystem = null;

    componentDidMount() {
        this.subscribeOnCollections();
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
                prevProps.selectedBranches.length ||
            (!prevState.location && this.state.location) ||
            prevState.location?.id !== this.state.location?.id ||
            prevState.user?.id !== this.state.user?.id
        ) {
            this.unsubscribeLocations && this.unsubscribeLocations();
            this.unsubscribeElements && this.unsubscribeElements();
            this.subscribeOnCollections();
        }
    }

    subscribeOnCollections = () => {
        this.setActivityIndicator(true);
        let branchIds = this.props.selectedBranches.map(branch => branch.id);
        if (this.state.user) {
            branchIds = this.props.users
                .find(user => user.uid === this.state.user.uid)
                .branches.map(branch => branch.id);
        }
        this.unsubscribeLocations = getLocationsQuery(branchIds).onSnapshot(
            this.props.fetchLocations,
            console.error,
        );
        if (this.state.location) {
            this.unsubscribeElements = getElementQuery(branchIds)
                .withLocation(this.state.location.id)
                .onSnapshot(this.props.fetchElements, console.error);
        }
        getUsersQuery()
            .withBranches(this.props.selectedBranches)
            .get()
            .then(users => {
                this.props.setUsers(users);
                this.setActivityIndicator(false);
            })
            .catch(error => {
                console.error(error);
                this.showNotification(
                    'Wystąpił błąd podczas pobierania użytkowników',
                    TYPES.error,
                );
                this.setActivityIndicator(false);
            });
    };

    render() {
        const alert = this.state.errorMessage && (
            <Alert>{this.state.errorMessage}</Alert>
        );
        const {startDate, endDate, reportType, location, element, user} =
            this.state;
        const {reportTypes, users, locations, elements} = this.props;
        return (
            <div>
                <ReportForm
                    reportPickerTypes={reportTypes}
                    users={users}
                    locations={locations}
                    elements={elements}
                    reportType={reportType}
                    location={location}
                    element={element}
                    user={user}
                    onGenerate={this.onGenerateReportedClickHandler}
                    onDatePickerChangeHandler={this.onDatePickerChangeHandler}
                    endDate={endDate}
                    startDate={startDate}
                    onReportTypeChange={this.onReportTypeChangeHandler}
                    onUserChange={this.onUserChangeHandler}
                    onLocationChange={this.onPickerValueChangeHandler(
                        'location',
                        'locations',
                    )}
                    onElementChange={this.onPickerValueChangeHandler(
                        'element',
                        'elements',
                    )}
                    showElementPicker={Boolean(location)}
                />
                {this.state.indicator && <CircularProgress />}
                {alert}
            </div>
        );
    }

    onDatePickerChangeHandler = (dateType, value) => {
        this.setState({[dateType]: value});
    };

    onPickerValueChangeHandler = (property, collection) => event => {
        const itemsCollection = this.props[collection];
        const item = {
            ...itemsCollection.find(i => event.value === i.id),
        };
        this.setState({[property]: item});
    };

    onUserChangeHandler = event => {
        const {users} = this.props;
        const user = event
            ? {
                  ...users.find(u => event.value === u.uid),
              }
            : null;
        this.setState({user});
    };

    onReportTypeChangeHandler = type => {
        const updateObject = {reportType: type};
        if (type.value === REPORT_TYPES.general) {
            updateObject.user = null;
            updateObject.location = null;
            updateObject.element = null;
        }
        this.setState(updateObject);
    };

    setActivityIndicator = bool => {
        this.setState({indicator: bool});
    };

    onGenerateReportedClickHandler = () => {
        const {startDate, endDate, user, location, element, reportType} =
            this.state;
        this.setErrorMessage('');
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        this.setState({indicator: true});
        this.props.fetchIssuesForReportAndGeneratePDF(
            startDateMoment,
            endDateMoment,
            this.setErrorMessage,
            user?.uid,
            location?.id,
            reportType.value,
            element?.id,
            () => this.setState({indicator: false}),
        );
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message,
                type,
            });
        }
    };

    setErrorMessage = message => {
        this.setState({errorMessage: message});
    };
}

const mapStateToProps = state => ({
    issues: state.report.issuesForReport,
    reportTypes: state.report.reportTypes,
    users: state.user.users,
    locations: state.location.locations,
    elements: state.element.elements,
    selectedBranches: state.branch.selectedBranches,
});

const mapDispatchToProps = {
    fetchIssuesForReportAndGeneratePDF,
    fetchLocations,
    setUsers,
    fetchElements,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
