import React from 'react';
import Picker from '../picker/Picker';
import {allRoles, labels} from '../../../constants/roles';

const rolePicker = props => {
    const items = allRoles.map(role => ({value: role, label: labels[role]}));

    const selectedValue = props.selectedRole && {
        value: props.selectedRole,
        label: labels[props.selectedRole],
    };

    return (
        <Picker
            label="Typ uprawnień"
            selectedValue={selectedValue}
            pickerItems={items}
            name="Rola"
            first={props.first}
            placeholder={'Wybierz rolę'}
            handleChange={props.handleChange}
            searchable={false}
            className={props.className}
            disabled={props.disabled}
            menuPlacement={props.menuPlacement}
            menuPortalTarget={props.menuPortalTarget}
        />
    );
};

rolePicker.defaultProps = {
    bottomLabelActive: true,
};

export default rolePicker;
