import React from 'react';
import Scrollbar from 'react-custom-scrollbars';

import styles from './CustomScrollbar.module.css';

const CustomScrollbar = props => (
    <Scrollbar
        style={{height: '100vh'}}
        renderTrackVertical={({style, ...props}) => (
            <div
                className={styles.verticalTrack}
                style={{...style}}
                {...props}
            />
        )}
        renderThumbVertical={({style, ...props}) => (
            <div className={styles.thumb} style={{...style}} {...props} />
        )}
        renderThumbHorizontal={({style, ...props}) => (
            <div className={styles.thumb} style={{...style}} {...props} />
        )}
    >
        {props.children}
    </Scrollbar>
);

export default CustomScrollbar;
