import React from 'react';
import DatePicker from 'react-datepicker';
import './CustomDatePicker.css';
import styles from './CustomDatePicker.module.css';
import 'react-datepicker/dist/react-datepicker.css';

const calendarIcon = (
    <img src={require('../../../static/images/icons/calendar-3.svg')} alt="" />
);

const customDatePicker = ({label, readOnly, value, onChange}) => (
    <div className={styles.datePickerContainer}>
        <div className={styles.dateLabel}>{label}</div>
        <div
            className={
                readOnly
                    ? `${styles.datePicker} ${styles.disabled}`
                    : styles.datePicker
            }
        >
            <span className={styles.calendarIcon}>{calendarIcon}</span>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                className={styles.input}
                selected={value}
                onChange={onChange}
                readOnly={readOnly}
            />
        </div>
    </div>
);

export default customDatePicker;
