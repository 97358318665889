import React from 'react';
import BranchesList from '../../components/Branch/BranchesList/BranchesList';
import AddBranchDialog from '../../components/Branch/AddBranchDialog/AddBranchDialog';
import EditBranchDialog from '../../components/Branch/EditBranchDialog/EditBranchDialog';
import {ListActionPanel, CustomCircularProgress} from '../../common/components';

const BranchesPageLayout = ({props}) => (
    <div>
        {props.actionPending && <CustomCircularProgress />}
        <ListActionPanel
            searchPlaceholder="Szukaj oddziałów"
            title="Oddziały"
            addButtonText="Dodaj oddział"
            onSearchTextChange={props.onSearchTextChange}
            onToggleAddDialogHandler={props.onToggleAddDialogHandler}
            showAddButton={props.branches.length > 1}
        />
        {props.addDialogOpen && (
            <AddBranchDialog
                handleClose={props.onToggleAddDialogHandler}
                onSubmitHandler={props.onSubmitHandler}
                dialogTitle={'Dodaj oddział'}
                loading={props.actionPending}
            />
        )}
        {props.editDialogOpen && (
            <EditBranchDialog
                dialogTitle="Edytuj oddział"
                onBranchNameChange={props.onBranchNameChange}
                branch={props.branchSelectedForEdit}
                handleClose={props.onToggleEditDialogHandler}
                onUpdateBranch={props.onUpdateBranch}
                loading={props.actionPending}
            />
        )}
        <div>
            {props.listLoading ? (
                <CustomCircularProgress />
            ) : (
                <BranchesList
                    onBranchClick={props.onBranchClickHandler}
                    branches={props.branches}
                    onDeleteBranch={props.onDeleteBranch}
                />
            )}
        </div>
    </div>
);

export default BranchesPageLayout;
