import React from 'react';
import {
    FilterInput,
    ListFilterTemplate,
} from '../../../../../common/components/index';

import './ElementNameFilter.css';

const ElementNameFilter = props => (
    <ListFilterTemplate
        onApplyFilter={props.onApplyFilter}
        onCancelFilter={props.onCancelFilter}
    >
        <span className="filter-content__name">Wyszukaj i filtruj</span>
        <FilterInput onChange={props.onChange} value={props.value} />
    </ListFilterTemplate>
);

export default ElementNameFilter;
