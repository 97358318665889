import React from 'react';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';

import './FilterInput.css';

const FilterInput = props => (
    <TextField
        id="outlined-bare"
        className={classnames('filter-input', props.className)}
        margin="normal"
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
    />
);

export default FilterInput;
