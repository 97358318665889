const isNil = value => {
    return value === null || value === undefined;
};

export const replaceAll = (inputString, searchValue, replaceValue) => {
    const regExp = new RegExp(searchValue, 'g');
    return inputString.replace(regExp, replaceValue);
};

export const normalizeStr = str => {
    const polishLetters = {
        Ą: 'A',
        Ć: 'C',
        Ę: 'E',
        Ó: 'O',
        Ń: 'N',
        Ł: 'L',
        Ś: 'S',
        Ź: 'Z',
        Ż: 'Z',
        ' ': '',
        '"': '*',
    };

    str = str.toUpperCase();
    for (const letter of Object.keys(polishLetters)) {
        str = str.replace(new RegExp(letter, 'gi'), polishLetters[letter]);
    }
    return str;
};

export const normalizedStringEquals = (str1, str2) =>
    normalizeStr(str1) === normalizeStr(str2);

export const descendingSort = (a, b) => {
    if (isNil(b)) {
        return -1;
    }
    if (isNil(a)) {
        return 1;
    }
    if (typeof a === 'string' && typeof b === 'string') {
        return b.localeCompare(a);
    } else {
        return b - a;
    }
};

export const ascendingSort = (a, b) => -descendingSort(a, b);

export const compareWithQueryString = (text, queryString) =>
    normalizeStr(text).includes(normalizeStr(queryString));

const nounVariationForPluralForms = (number, variations) => {
    const pluralRules = new Intl.PluralRules('pl-PL');
    return variations[pluralRules.select(number)];
};

export const getElementPlural = number => {
    const variations = {
        one: 'element',
        few: 'elementy',
        many: 'elementów',
    };
    return nounVariationForPluralForms(number, variations);
};

export const getLocationsPlural = number => {
    const variations = {
        one: 'lokalizacja',
        few: 'lokalizacje',
        many: 'lokalizacji',
    };
    return nounVariationForPluralForms(number, variations);
};
