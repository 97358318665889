import {archivedIssue} from '../../constants/endpoints';
import {issueConverter} from '../converter/issueConverter';
import firebase from 'firebase/app';
import QueryBuilder from './QueryBuilder';

class ArchivedIssueQueryBuilder {
    constructor(branchIds) {
        const createQuery = () =>
            firebase.firestore().collection(archivedIssue());
        this._queryBuilder = new QueryBuilder(
            createQuery,
            branchIds,
            {},
            issueConverter,
        );
    }

    static query(branchIds) {
        return new ArchivedIssueQueryBuilder(branchIds);
    }

    onSnapshot(callback, onError) {
        return this._queryBuilder.onSnapshot(callback, onError);
    }

    get() {
        return this._queryBuilder.get();
    }
}

const getArchivedIssuesQuery = ArchivedIssueQueryBuilder.query;

export default getArchivedIssuesQuery;
