import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import React, {useState} from 'react';
import classnames from 'classnames';
import NavBar from '../../Navigation/NavBar/NavBar';
import UserStatusPanel from '../../UserStatusPanel/UserStatus';
import styles from './AppBar.module.css';
import {BurgerIcon} from '../../../static/images/icons/BurgerIcon';
import Notifications from '../../Notifications/Notifications';

const AppBar = props => {
    const [burgerMenu, setBurger] = useState(false);
    return (
        <div className={styles.appBar}>
            <table cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.appBar__tableTopRow}>
                        <th
                            style={{
                                color: 'var(--white)',
                                backgroundColor: 'var(--light-blue)',
                            }}
                        >
                            <img
                                className={styles.logo}
                                alt=""
                                src={require('../../../static/images/logo/logo_small.svg')}
                            />
                        </th>
                        <th
                            style={{
                                backgroundColor: 'var(--white)',
                                border: '1px solid var(--light-grey2)',
                            }}
                        >
                            <Toolbar>
                                <div style={{marginLeft: 'auto'}}>
                                    <UserStatusPanel />
                                </div>
                            </Toolbar>
                        </th>
                    </tr>
                    <tr>
                        <td
                            className={classnames(styles.nav, {
                                [styles.closed]: !burgerMenu,
                            })}
                        >
                            <NavBar />
                        </td>
                        <td
                            className={classnames(styles.burgerButton, {
                                [styles.closeIcon]: burgerMenu,
                            })}
                            onClick={() => setBurger(!burgerMenu)}
                        >
                            {props.withBurger && <BurgerIcon />}
                        </td>
                        <td className={styles.container}>
                            <span
                                className={classnames({
                                    [styles.backdrop]: burgerMenu,
                                })}
                                onClick={() => setBurger(!burgerMenu)}
                            />
                            <Notifications />
                            {props.children}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AppBar;
