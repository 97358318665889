import {SET_ELEMENTS} from '../action/actionTypes';

const initialState = {
    elements: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ELEMENTS:
            return {
                ...state,
                elements: action.elements,
            };
        default:
            return state;
    }
};

export default reducer;
