import {convertElement} from './elementConverter';

export const taskConverter = {
    toFirestore: task => task,
    fromFirestore: (snapshot, options) => {
        const task = snapshot.data(options);
        task.startDate = task.startDate.toDate();
        task.nextOccurrenceDate = task.nextOccurrenceDate.toDate();
        task.id = snapshot.id;
        task.elements.forEach(convertElement);
        return task;
    },
};
