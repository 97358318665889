import React from 'react';
import classnames from 'classnames';
import styles from './Notificationitem.module.css';
import {CloseButton} from '../../../common/components';
import Indicator from './NotificationIndicator/NotificationIndicator';
import {TYPES} from '../../../constants/error';

const notificationItem = ({description, type, onRemove}) => (
    <div
        className={classnames(styles.notification, {
            [styles.error]: type === TYPES.error,
        })}
    >
        <Indicator type={type} />
        {description}
        <span className={styles.closeButton}>
            <CloseButton onClick={onRemove} />
        </span>
    </div>
);

export default notificationItem;
