import * as QRCode from 'qrcode';

export const generateAndDownloadQR = async (content, fileName) => {
    try {
        const qrCode = await QRCode.toDataURL(content);
        const link = document.createElement('a');
        link.href = qrCode;
        link.setAttribute('download', fileName + '.png');
        document.body.appendChild(link);
        link.click();
    } catch (err) {
        throw err;
    }
};
