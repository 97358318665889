import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocationsList from '../../components/Location/LocationsList/LocationsList';
import {mappings} from './LocationsPageRoleMapper';
import {
    fetchLocations,
    deleteLocation,
    addLocation,
    updateLocation,
} from '../../store/action';
import {CustomCircularProgress} from '../../common/components/index';
import LocationsPageLayout from './LocationsPageLayout';
import {withUserRole} from '../../hoc/User/WithUserRole';
import {NotificationContext} from '../../context/notifications';
import {normalizedStringEquals} from '../../utils/StringUtils/StringUtils';
import getLocationsQuery from '../../utils/queryBuilder/LocationQueryBuilder';
import {TYPES} from '../../constants/error';
import {checkIfElementsExists} from '../../utils/firestoreDocumentUtils/firestoreDocumentUtils';

class LocationsPage extends Component {
    state = {
        locationSelectedForEdit: null,
        addDialogOpen: false,
        editDialogOpen: false,
        searchString: '',
        indicateActivity: true,
    };

    static contextType = NotificationContext;
    unsubscribeLocations;
    notificationSystem;

    constructor(props) {
        super(props);
        this.notificationSystem = null;
    }

    componentDidMount() {
        this.subscribeOnLocations();
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            this.unsubscribeLocations && this.unsubscribeLocations();
            this.subscribeOnLocations();
        }
    }

    subscribeOnLocations = () => {
        const branchIds = this.props.selectedBranches.map(branch => branch.id);
        this.unsubscribeLocations = getLocationsQuery(branchIds).onSnapshot(
            locations =>
                this.props.fetchLocations(locations, this.setActivityIndicator),
            console.error,
        );
    };

    componentWillUnmount() {
        this.unsubscribeLocations();
    }

    render() {
        const locationsList = this.getLocationsList();
        const {locationSelectedForEdit} = this.state;

        return withUserRole(LocationsPageLayout, mappings, {
            locationsList,
            locationSelectedForEdit,
            onSearchTextChange: this.onSearchTextChange,
            onToggleAddDialogHandler: this.onToggleAddDialogHandler,
            addDialogOpen: this.state.addDialogOpen,
            onLocationSubmit: this.onLocationSubmit,
            editDialogOpen: this.state.editDialogOpen,
            onToggleEditDialogHandler: this.onToggleEditDialogHandler,
            onUpdateLocationEventHandler: this.onUpdateLocationEventHandler,
            showNotification: this.showNotification,
        });
    }

    onSearchTextChange = event => {
        this.setState({searchString: event.target.value.trim()});
    };

    onRemoveLocationHandler = async locationId => {
        const elementsExists = await checkIfElementsExists(locationId);
        if (elementsExists) {
            return this.showNotification(
                'Nie możesz usunąć tej lokalizacji, ponieważ znajdują się w niej zapisane elementy',
                TYPES.error,
            );
        }
        this.props.deleteLocation(
            locationId,
            () =>
                this.showNotification(
                    'Usuwanie elementu powiodło się',
                    TYPES.success,
                ),
            () =>
                this.showNotification(
                    'Usuwanie elementu nie powiodło się',
                    TYPES.error,
                ),
        );
    };

    filterLocations = locations => {
        if (this.state.searchString) {
            locations = locations.filter(location =>
                location.name
                    .toUpperCase()
                    .includes(this.state.searchString.toUpperCase()),
            );
        }
        return locations;
    };

    onLocationsClickHandler = locationId => () => {
        const location = this.props.locations.find(
            loc => loc.id === locationId,
        );
        this.setState({
            locationSelectedForEdit: location,
            editDialogOpen: true,
        });
    };

    onToggleAddDialogHandler = () => {
        this.setState({addDialogOpen: !this.state.addDialogOpen});
    };

    onToggleEditDialogHandler = () => {
        this.setState({editDialogOpen: !this.state.editDialogOpen});
    };

    locationExists = location =>
        this.props.locations
            .filter(loc => normalizedStringEquals(loc.name, location.name))
            .some(loc => loc.branch.id === location.branch.id);

    onLocationSubmit = locationToSet => {
        if (this.locationExists(locationToSet)) {
            this.showNotification(
                'Już istnieje obiekt o tej nazwie',
                TYPES.error,
            );
        } else {
            this.props.addLocation(
                locationToSet,
                () => this.showNotification('Dodano lokalizacje', 'success'),
                () =>
                    this.showNotification(
                        'Dodawanie elementu nie powiodło się',
                        TYPES.error,
                    ),
            );
        }
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    getLocationsList = () =>
        this.state.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <LocationsList
                locations={this.filterLocations(this.props.locations)}
                onRemoveLocation={this.onRemoveLocationHandler}
                onLocationClick={this.onLocationsClickHandler}
            />
        );

    onUpdateLocationEventHandler = (location, onSuccess, onError) => {
        if (this.locationExists(location)) {
            onError('Lokacja z taką nazwą już istnieje');
        } else {
            this.props.updateLocation(location, location.id, onSuccess, () =>
                onError('Edycja Lokacji nie powiodła się'),
            );
        }
    };
}

const mapStateToProps = state => ({
    locations: state.location.locations,
    selectedBranches: state.branch.selectedBranches,
});

const mapDispatchToProps = dispatch => ({
    fetchLocations: (querySnapshot, callback) =>
        dispatch(fetchLocations(querySnapshot, callback)),
    deleteLocation: (locationId, onSuccess, onError) =>
        dispatch(deleteLocation(locationId, onSuccess, onError)),
    addLocation: (location, onSuccess, onError) =>
        dispatch(addLocation(location, onSuccess, onError)),
    updateLocation: (location, key, onSuccess, onError) =>
        dispatch(updateLocation(location, key, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsPage);
