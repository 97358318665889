import {getDownloadUrl} from '../storageUtils/storageUtils';

export const fetchElementImages = element => {
    if (!element.iconImagePath) {
        return Promise.resolve([null, null]);
    }
    return Promise.all([
        getDownloadUrl(element.iconImagePath),
        getDownloadUrl(element.patternImagePath),
    ]);
};
