import React from 'react';
import classnames from 'classnames';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Tooltip from '@material-ui/core/Tooltip';
import * as styles from './warranty.module.css';
import 'moment/locale/pl';
import {dateDiffInDays} from '../../../utils/date/dateUtils';

const isDateLaterThan30DaysFromNow = endDate => {
    const now = new Date();
    const diffInDays = dateDiffInDays(endDate, now);
    return diffInDays > 30;
};

const isWarrantyActive = endDate => {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    return now < endDate;
};

const Warranty = ({endDate, warrantyComment}) => {
    const warrantyEndsLaterThanIn30Days =
        endDate && isDateLaterThan30DaysFromNow(endDate);
    const warrantyActive = endDate && isWarrantyActive(endDate);
    const warrantyIcon = (
        <VerifiedUser
            className={classnames(styles.warrantyIcon, {
                [styles.warrantyIconPositive]: warrantyEndsLaterThanIn30Days,
                [styles.warrantyIconNegative]: !warrantyEndsLaterThanIn30Days,
            })}
        />
    );
    const dateString = endDate && endDate.toLocaleDateString('pl');

    return endDate && warrantyActive ? (
        warrantyComment ? (
            <Tooltip
                classes={{tooltip: styles.warrantyTooltip}}
                title={warrantyComment}
                placement="top"
            >
                <div>
                    {warrantyIcon}
                    <span>{dateString}</span>
                </div>
            </Tooltip>
        ) : (
            <>
                <span className={styles.iconHidden}>{warrantyIcon}</span>
                <span>{dateString}</span>
            </>
        )
    ) : (
        <div>Brak</div>
    );
};

export default Warranty;
