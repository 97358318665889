import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    fetchElements,
    fetchLocations,
    getElementsByLocationId,
} from '../../store/action';
import {withUserRole} from '../../hoc/User/WithUserRole';
import {NotificationContext} from '../../context/notifications';
import TaskDetailsLayout from './TaskDetailsLayout';
import {mappings} from './TaskDetailsRoleMapper';
import {elements, locations, task} from '../../constants/endpoints';
import {
    fetchDocumentById,
    getItemFromDocumentAndPassToCallback,
} from '../../utils/firestoreDocumentUtils/firestoreDocumentUtils';
import {taskConverter} from '../../utils/converter/taskConverter';
import firebase from 'firebase/app';
import {deleteTask, updateTask} from '../../utils/task/task';
import {TASKS} from '../../constants/routes';
import {withRouter} from 'react-router-dom';
import {CustomCircularProgress} from '../../../src/common/components';
import {elementConverter} from '../../utils/converter/elementConverter';
import {TYPES} from '../../constants/error';

class TaskDetails extends Component {
    state = {
        originalTask: null,
        elements: [],
        locations: [],
        saveButtonDisabled: true,
        loading: true,
    };

    editedTask = null;
    static contextType = NotificationContext;
    notificationSystem = null;
    elementsCollection = firebase.firestore().collection(elements());
    locationCollection = firebase.firestore().collection(locations());

    componentDidMount() {
        this.notificationSystem = this.context;
        this.subscribeOnElements();
        this.fetchTask();
    }

    fetchTask() {
        const taskId = this.props.match.params.id;
        const taskDocument = fetchDocumentById(task(), taskId, taskConverter);
        getItemFromDocumentAndPassToCallback(taskDocument, this.taskFetched);
    }

    taskFetched = task => {
        this.setState({
            originalTask: task,
            loading: false,
        });
        this.unsubscribeLocations = this.locationCollection
            .where('branch.id', '==', this.state.originalTask.branch.id)
            .onSnapshot(this.fetchLocations);
    };

    subscribeOnElements() {
        this.unsubscribeElements = this.elementsCollection
            .withConverter(elementConverter)
            .onSnapshot(this.fetchElements);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locations !== this.props.locations) {
            const {selectedLocations} = this.state;
            this.setElementsForLocations(selectedLocations);
        }
        if (
            this.state.originalTask &&
            prevProps.selectedBranches !== this.props.selectedBranches &&
            !this.props.selectedBranches.some(
                branch => branch.id === this.state.originalTask.branch.id,
            )
        ) {
            this.props.history.push(TASKS);
        }
    }

    componentWillUnmount() {
        this.unsubscribeLocations();
        this.unsubscribeElements();
    }

    render() {
        const {elements, name, locations, loading, originalTask} = this.state;
        const {users} = this.props;

        return originalTask ? (
            withUserRole(TaskDetailsLayout, mappings, {
                name,
                loading,
                originalTask,
                elements,
                locations,
                users,
                header: originalTask.name,
                saveButtonDisabled: this.state.saveButtonDisabled,
                setSaveButtonDisabled: this.setSaveButtonDisabled,
                setFormState: this.setFormState,
                onTaskUpdated: this.onTaskUpdated,
                onTaskDeleted: this.onTaskDeleted,
            })
        ) : (
            <CustomCircularProgress />
        );
    }

    setSaveButtonDisabled = value => {
        this.setState({saveButtonDisabled: value});
    };

    setFormState = formState => {
        this.editedTask = formState;
    };

    fetchLocations = ({docs}) => {
        const locations = docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            key: doc.id,
        }));
        this.setState({locations});
    };

    fetchElements = ({docs}) => {
        const elements = docs.map(doc => ({
            ...doc.data(),
            key: doc.id,
            id: doc.id,
        }));
        this.setState({elements});
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    onTaskUpdated = () => {
        this.setState({loading: true});
        const {userData} = this.props;
        const {
            active,
            assignedTo,
            repeat,
            selectedElements,
            startDate,
            name,
            notifications,
            priority,
            addToIssueList,
            addToIssueListTimePeriod,
        } = this.editedTask;

        const updatedTask = {
            active,
            repeat,
            assignedTo: assignedTo && {
                name: assignedTo.name,
                surname: assignedTo.surname,
                uid: assignedTo.uid,
            },
            reporter: {
                name: userData.name,
                surname: userData.surname,
                uid: userData.uid,
            },
            elements: selectedElements.filter(Boolean),
            startDate,
            name,
            nextOccurrenceDate: startDate,
            notifications,
            priority,
            addToIssueList,
            addToIssueListTimePeriod,
        };

        updateTask(this.state.originalTask.key, updatedTask)
            .then(() => {
                this.showNotification('Zadanie zaktualizowane', 'success');
                this.props.history.push(TASKS);
            })
            .catch(error => {
                console.error(error);
                this.setState({loading: false});
                this.showNotification(
                    'Nie udało się zaktualizować zadania',
                    TYPES.error,
                );
            });
    };

    onTaskDeleted = () => {
        this.setState({loading: true});
        deleteTask(this.state.originalTask.key)
            .then(() => {
                this.setState({loading: false});
                this.showNotification('Zadanie usunięte', 'success');
                this.props.history.push(TASKS);
            })
            .catch(error => {
                console.error(error);
                this.setState({loading: false});
                this.showNotification(
                    'Nie udało się usunąć zadania',
                    TYPES.error,
                );
            });
    };
}

const mapStateToProps = state => ({
    users: state.user.users,
    userData: state.auth.userData,
    elements: state.element.elements,
    selectedBranches: state.branch.selectedBranches,
});

const mapDispatchToProps = dispatch => ({
    fetchLocations: dispatch(fetchLocations),
    fetchElements: dispatch(fetchElements),
    getElementsByLocationId: dispatch(getElementsByLocationId),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TaskDetails),
);
