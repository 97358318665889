import {SET_ARCHIVED_ISSUES, SET_ISSUES} from '../action/actionTypes';

const initialState = {
    issues: [],
    archivedIssues: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ISSUES:
            return {
                ...state,
                issues: action.issues,
            };
        case SET_ARCHIVED_ISSUES:
            return {
                ...state,
                archivedIssues: action.archivedIssues,
            };
        default:
            return state;
    }
};

export default reducer;
