import React from 'react';
import Picker from '../picker/Picker';
import {STATUS_LABELS} from '../../../constants/issues';

const statusPicker = props => {
    const items = props.statuses?.map(status => ({
        value: status,
        label: STATUS_LABELS[status],
    }));
    const selectedValue = props.selectedStatus && {
        value: props.selectedStatus,
        label: STATUS_LABELS[props.selectedStatus],
    };
    return (
        <Picker
            label="Status zgłoszenia"
            selectedValue={selectedValue}
            pickerItems={items}
            name="Miejsca"
            first={props.first}
            placeholder={'Szukaj lokalizacji'}
            handleChange={props.handleChange}
            className={props.className}
            searchable={false}
            disabled={props.disabled}
        />
    );
};

statusPicker.defaultProps = {
    bottomLabelActive: true,
};

export default statusPicker;
