import React from 'react';
import styles from './mobileDataDisplay.module.css';
import classnames from 'classnames';

const MobileDataDisplay = ({title, left, right, image, withoutImage}) => (
    <div className={styles.column}>
        {!withoutImage && (
            <img
                className={classnames(styles.issueImage, {
                    [styles.default]: !image,
                })}
                src={
                    image ||
                    require('../.././../../../../static/images/icons/picture-landscape.svg')
                }
                alt="Zdjęcie elementu"
            />
        )}
        <div className={styles.textContainer}>
            <p className={styles.title}>{title}</p>
            <p className={styles.text}>{right ? `${left} | ${right}` : left}</p>
        </div>
    </div>
);

export default MobileDataDisplay;
