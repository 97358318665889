import React from 'react';
import {connect} from 'react-redux';
import LogOutButton from '../../components/LogoutButton/LogoutButton';
import {logOut} from '../../store/action';

const withLogout = Component => {
    class WithLogout extends React.Component {
        render() {
            return this.props.userData ? (
                <LogOutButton logOutHandler={this.props.logOut} />
            ) : (
                <Component {...this.props} />
            );
        }
    }

    const mapStateToProps = state => ({
        userData: state.auth.userData,
    });

    const mapDispatchToProps = dispatch => ({
        logOut: () => dispatch(logOut()),
    });

    return connect(mapStateToProps, mapDispatchToProps)(WithLogout);
};

export default withLogout;
