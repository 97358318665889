export const statuses = {
    OPEN: 'open',
    IN_PROGRESS: 'inProgress',
    IN_REVIEW: 'inReview',
    DONE: 'done',
};

export const STATUS_LABELS = {
    open: 'Otwarte',
    inProgress: 'W toku',
    inReview: 'Do akceptacji',
    done: 'Zakończone',
};

export const PRIORITY_LABEL = {
    urgent: 'Krytyczne',
    important: 'Pilne',
    normal: 'Zwykłe',
};
