export const saveUserData = userData => {
    localStorage.setItem('userData', JSON.stringify(userData));
};

export const saveSelectedBranches = selectedBranches => {
    localStorage.setItem('selectedBranches', JSON.stringify(selectedBranches));
};

export const getUserDataFromStorage = () => {
    const userData = localStorage.getItem('userData');
    !userData && console.error('userData is undefined');
    return userData ? JSON.parse(userData) : null;
};

export const getSelectedBranchesFromStorage = () => {
    const branches = localStorage.getItem('selectedBranches');
    !branches && console.error('selectedBranches are empty');
    return branches ? JSON.parse(branches) : null;
};

export const clearStorage = () => {
    localStorage.clear();
};
