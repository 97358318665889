import React from 'react';
import {ListActionPanel} from '../../../common/components/index';

const UsersPageControl = ({
    onSearchTextChange,
    onToggleAddDialogHandler,
    showAddButton,
}) => (
    <ListActionPanel
        searchPlaceholder={'Szukaj użytkownika'}
        title={'Użytkownicy'}
        addButtonText="Dodaj użytkownika"
        onSearchTextChange={onSearchTextChange}
        onToggleAddDialogHandler={onToggleAddDialogHandler}
        showAddButton={showAddButton}
    />
);

export default UsersPageControl;
