import React from 'react';
import * as moment from 'moment';
import {
    List,
    ListItem,
    ListColumnCreator,
    IOSSwitch,
    MobileList,
    ListItemMobile,
    MobileHeader,
    MobileDataDisplay,
} from '../../../common/components/index';
import CyclicIcon from './display/cyclicIcon/cyclicIcon';
import {maintainerComparator} from '../../../utils/sorting/sorting';
import Elements from './display/element/elements';
import {
    getElementPlural,
    getLocationsPlural,
} from '../../../utils/StringUtils/StringUtils';

const tasksList = props => {
    const columns = [
        new ListColumnCreator('name', 'Nazwa zadania', {}),
        new ListColumnCreator('startDate', 'Termin zadania', {}),
        new ListColumnCreator('elements', 'Wybrane elementy', {
            accessAttribute: task => task.elements[0]?.name,
        }),
        new ListColumnCreator('assignedTo', 'Przypisany pracownik', {
            customComparator: maintainerComparator,
        }),
        new ListColumnCreator('cyclic', 'cykliczne', {}),
        new ListColumnCreator('active', 'Aktywne', {}),
    ];

    const columnIds = columns.map(column => column.id);
    const getTaskListItemTemplate = task => (
        <ListItem
            key={task.id}
            onClick={props.onTaskClick(task.id)}
            columns={columnIds}
        >
            {task.name}
            {moment(task.startDate).format('DD/MM/YYYY')}
            <Elements elements={task.elements} />
            {task.assignedTo &&
                `${task.assignedTo.name} ${task.assignedTo.surname}`}
            <CyclicIcon active={task.repeat} />
            <IOSSwitch
                checked={task.active}
                name={task.id}
                onClick={props.onActiveStateChange}
            />
        </ListItem>
    );

    const getMobileTaskListItemTemplate = task => {
        const locationsNumber = task.elements.reduce((set, element) => {
            set.add(element.location.name);
            return set;
        }, new Set()).size;
        return (
            <ListItemMobile
                key={task.id}
                onClick={props.onTaskClick(task.id)}
                classes={columnIds}
            >
                <MobileHeader classes={['date']}>
                    {moment(task.startDate).format('DD/MM/YYYY')}
                </MobileHeader>
                <MobileDataDisplay
                    title={task.name}
                    withoutImage
                    left={`${locationsNumber} ${getLocationsPlural(
                        locationsNumber,
                    )}`}
                    right={`${task.elements.length} ${getElementPlural(
                        task.elements.length,
                    )}`}
                />
            </ListItemMobile>
        );
    };

    const {tasks} = props;
    return (
        <>
            <List
                columns={columns}
                rows={tasks}
                rowTemplate={getTaskListItemTemplate}
            />
            <MobileList
                columns={columns}
                rows={tasks}
                rowTemplate={getMobileTaskListItemTemplate}
            />
        </>
    );
};

export default tasksList;
