import {Component} from 'react';
import IssueDetailsLayout from './IssueDetailsLayout';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import {
    fetchElements,
    fetchLocations,
    getElementsByLocationId,
    updateIssue,
    dispatchAndDownloadPhotos,
    deleteIssue,
    setElements,
    setUsers,
} from '../../store/action';
import {mappings} from './IssueDetailsRoleMapper';
import {archivedIssue, issues} from '../../constants/endpoints';
import {MAX_PHOTOS, MAX_PHOTO_SIZE} from '../../constants/files';
import {withUserRole} from '../../hoc/User/WithUserRole';
import {NotificationContext} from '../../context/notifications';
import {issueConverter} from '../../utils/converter/issueConverter';
import isEqual from 'react-fast-compare';
import {ARCHIVED_ISSUES, ISSUES} from '../../constants/routes';
import {getUserRole} from '../../store/action/authHelpers';
import getUsersQuery from '../../utils/queryBuilder/UsersQueryBuilder';
import {fetchElementImages} from '../../utils/element/element';
import getLocationsQuery from '../../utils/queryBuilder/LocationQueryBuilder';
import {TYPES} from '../../constants/error';

class IssueDetails extends Component {
    state = {
        issue: null,
        editedIssue: null,
        selectedElement: null,
        selectedLocation: null,
        issueImages: {
            new: [],
            uploaded: [],
            removed: [],
        },
        solutionImages: {
            new: [],
            uploaded: [],
            removed: [],
        },
        users: [],
        uploadingInProgress: false,
    };
    static contextType = NotificationContext;
    unsubscribeLocations;
    unsubscribeIssue;
    issuesRef = null;
    notificationSystem = null;

    constructor(props) {
        super(props);
        const collection = props.archived ? archivedIssue() : issues();
        this.issuesRef = firebase.firestore().collection(collection);
    }

    componentDidMount() {
        this.subscribeOnIssue(this.props.match.params.id);
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedBranches !== prevProps.selectedBranches &&
            this.state.issue &&
            !this.props.selectedBranches.find(
                branch => branch.id === this.state.issue.branch.id,
            )
        ) {
            this.props.history.push(
                this.props.archived ? ARCHIVED_ISSUES : ISSUES,
            );
        }
    }

    subscribeOnIssue(issueId) {
        this.unsubscribeIssue = this.issuesRef
            .doc(issueId)
            .withConverter(issueConverter)
            .onSnapshot(doc => {
                if (!doc.data()) {
                    return;
                }
                const issue = {key: doc.id, ...doc.data()};
                this.unsubscribeLocations && this.unsubscribeLocations();
                this.unsubscribeLocations = getLocationsQuery([
                    issue.branch.id,
                ]).onSnapshot(this.props.fetchLocations, console.error);
                this.props.getElementsByLocationId(issue.element.location.id);
                this.fetchUsers(issue.element.location.branch);
                this.fetchImagesAndUpdateElement(issue.element);
                this.props.downloadPhotos(
                    issue.issueImagePaths,
                    this.onPhotosDownloadedHandler('issueImages'),
                );
                this.props.downloadPhotos(
                    issue.solutionImagePaths,
                    this.onPhotosDownloadedHandler('solutionImages'),
                );
                const editedIssue = this.applyRemoteChanges(issue);
                this.setState({
                    issue,
                    editedIssue,
                    selectedLocation: issue.element.location,
                    selectedElement: issue.element,
                });
            });
    }

    fetchUsers(branch) {
        const {rolesToFetchUsersWith} = mappings[getUserRole()].roleProps;

        if (rolesToFetchUsersWith) {
            getUsersQuery()
                .withBranch(branch)
                .withRoles(rolesToFetchUsersWith)
                .get()
                .then(users => this.setState({users}))
                .catch(error => {
                    console.error(error);
                    this.showNotification(
                        'Wystąpił błąd podczas pobierania użytkowników',
                        TYPES.error,
                    );
                });
        }
    }

    fetchImagesAndUpdateElement = element => {
        fetchElementImages(element)
            .then(([iconUri, patternUri]) => {
                this.setState({
                    selectedElement: {
                        ...this.state.selectedElement,
                        iconUri,
                        patternUri,
                    },
                });
            })
            .catch(err => console.error('Bad element image paths', err));
    };

    applyRemoteChanges(newIssue) {
        if (!this.state.issue) {
            return {...newIssue};
        }
        const oldIssue = {...this.state.issue};
        const editedIssue = {...this.state.editedIssue};
        Object.keys(newIssue).forEach(key => {
            if (!isEqual(newIssue[key], oldIssue[key])) {
                oldIssue[key] = newIssue[key];
            }
        });
        return {...editedIssue, ...oldIssue};
    }

    componentWillUnmount() {
        this.unsubscribeLocations();
        this.unsubscribeIssue();
        this.props.clearElements();
    }

    render() {
        const {locations, elements, archived, userData, organizationData} =
            this.props;
        const {
            editedIssue,
            selectedElement,
            selectedLocation,
            issueImages,
            solutionImages,
            uploadingInProgress,
            users,
        } = this.state;
        const issueImagesSrc = [
            ...issueImages.new.map(image => URL.createObjectURL(image)),
            ...issueImages.uploaded.map(image => image.uri),
        ];
        const solutionImagesSrc = [
            ...solutionImages.new.map(image => URL.createObjectURL(image)),
            ...solutionImages.uploaded.map(image => image.uri),
        ];
        const formDisabled =
            editedIssue &&
            userData.roles.reporter &&
            editedIssue.reporter.uid !== userData.uid;
        return (
            editedIssue &&
            withUserRole(IssueDetailsLayout, mappings, {
                issue: editedIssue,
                formDisabled,
                archived,
                locations,
                elements,
                users,
                selectedElement,
                selectedLocation,
                solutionImagesSrc,
                issueImagesSrc,
                uploadingInProgress,
                organizationData,
                onHighPriorityCheckboxChange: this.onHighPriorityCheckboxChange,
                onUrgentPriorityCheckboxChange:
                    this.onUrgentPriorityCheckboxChange,
                onElementChange: this.onElementChange,
                onLocationChange: this.onLocationChange,
                onAssignedEmployeeChange: this.onAssignedEmployeeChange,
                onAcceptedByChange: this.onAcceptedByChange,
                onStatusChange: this.onStatusChange,
                onIssueFieldChangeHandlerBuilder:
                    this.onIssueFieldChangeHandlerBuilder,
                onTextChangeHandlerBuilder: this.onTextChangeHandlerBuilder,
                onAddNewImageHandlerBuilder: this.onAddNewImageHandlerBuilder,
                onRemovePhotoHandlerBuilder: this.onRemovePhotoHandlerBuilder,
                updateIssue: this.updateIssue,
                deleteIssue: this.deleteIssue,
                isSubmitButtonDisabled: this.isSubmitButtonDisabled(),
            })
        );
    }

    isSubmitButtonDisabled() {
        const {issue} = this.state;
        const editedIssue = this.state.editedIssue;
        const {
            issueImages,
            solutionImages,
            uploadingInProgress,
            selectedElement,
        } = this.state;

        const photoChangesCount =
            issueImages.removed.length +
            issueImages.new.length +
            solutionImages.removed.length +
            solutionImages.new.length;

        return (
            uploadingInProgress ||
            (editedIssue.issueDescription === issue.issueDescription &&
                editedIssue.solutionDescription === issue.solutionDescription &&
                (editedIssue.element.id === issue.element.id ||
                    !selectedElement) &&
                photoChangesCount === 0 &&
                editedIssue.status === issue.status &&
                this.userDidNotChange(
                    editedIssue.assignedTo,
                    issue.assignedTo,
                ) &&
                this.userDidNotChange(
                    editedIssue.acceptedBy,
                    issue.acceptedBy,
                ) &&
                editedIssue.highPriority === issue.highPriority &&
                editedIssue.urgentPriority === issue.highPriority)
        );
    }

    userDidNotChange(user1, user2) {
        return user1 === user2 || (user1 && user2 && user1.uid === user2.uid);
    }

    onPhotosDownloadedHandler = photosField => photosUrls => {
        const photos = {...this.state[photosField]};
        photos.uploaded = photosUrls;
        this.setState({
            [photosField]: photos,
        });
    };

    onAddNewImageHandlerBuilder = photosField => files => {
        const photos = {...this.state[photosField]};
        const numberOfunacceptedFiles = files.filter(
            file => file.size > MAX_PHOTO_SIZE,
        ).length;
        const actualImageCount = photos.new.length + photos.uploaded.length;
        const newImageCount = actualImageCount + files.length;
        if (newImageCount > MAX_PHOTOS) {
            this.showNotification(
                'Nie można dodać więcej niż 5 zdjęć',
                TYPES.error,
            );
        } else if (numberOfunacceptedFiles > 0) {
            this.showNotification(
                'Maksymalny rozmiar zdjęcia to 5MB',
                TYPES.error,
            );
        } else {
            photos.new = [...photos.new, ...files];
            this.setState({[photosField]: photos});
        }
    };

    onRemovePhotoHandlerBuilder = photosField => removedPhotoIndex => {
        const photos = this.state[photosField];
        const newPhotos = [...photos.new];
        const removedPhotos = [...photos.removed];
        const uploadedPhotos = [...photos.uploaded];
        const uploadedPhotoIndex = removedPhotoIndex - newPhotos.length;
        if (uploadedPhotoIndex >= 0) {
            removedPhotos.push(uploadedPhotos.splice(uploadedPhotoIndex, 1)[0]);
        } else {
            newPhotos.splice(uploadedPhotoIndex, 1);
        }
        const newPhotosField = {
            new: newPhotos,
            removed: removedPhotos,
            uploaded: uploadedPhotos,
        };
        this.setState({
            [photosField]: newPhotosField,
            displaySubmitButton: true,
        });
    };

    onLocationChange = event => {
        const location = {
            ...this.props.locations.find(
                location => event.value === location.id,
            ),
        };
        if (location !== this.state.selectedLocation) {
            this.setState({
                selectedLocation: location,
                selectedElement: null,
            });
            this.fetchUsers(location.branch);
            this.props.getElementsByLocationId(location.id);
        }
    };

    onStatusChange = event => {
        this.onIssueFieldChangeHandlerBuilder('status')(event.value);
    };

    onTextChangeHandlerBuilder = field => event => {
        this.onIssueFieldChangeHandlerBuilder(field)(event.target.value);
    };

    onHighPriorityCheckboxChange = event => {
        const editedIssue = {...this.state.editedIssue};
        editedIssue.highPriority = event.target.checked;
        editedIssue.urgentPriority = false;
        this.setState({editedIssue});
    };

    onUrgentPriorityCheckboxChange = event => {
        const editedIssue = {...this.state.editedIssue};
        editedIssue.urgentPriority = event.target.checked;
        editedIssue.highPriority = false;
        this.setState({editedIssue});
    };

    onIssueFieldChangeHandlerBuilder = field => change => {
        const editedIssue = {...this.state.editedIssue};
        editedIssue[field] = change;
        this.setState({editedIssue});
    };

    onAssignedEmployeeChange = event => {
        const user = this.state.users.find(user => event.value === user.uid);
        const editedIssue = {...this.state.editedIssue};
        editedIssue.assignedTo = user;
        this.setState({editedIssue});
    };

    onAcceptedByChange = event => {
        const user = this.state.users.find(user => event.value === user.key);
        const editedIssue = {...this.state.editedIssue};
        editedIssue.acceptedBy = user;
        this.setState({editedIssue});
    };

    onElementChange = event => {
        const element = {
            ...this.props.elements.find(element => event.value === element.id),
        };
        this.fetchImagesAndUpdateElement(element);
        this.setState({selectedElement: element});
        this.onIssueFieldChangeHandlerBuilder('element')(element);
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    updateIssue = () => {
        const {issue, editedIssue, issueImages, solutionImages} = this.state;
        this.setState({uploadingInProgress: true});
        this.props.updateIssue(
            editedIssue,
            issue,
            issueImages,
            solutionImages,
            () => {
                this.showNotification('Zaktualizowano element', 'success');
                this.props.history.push(
                    this.props.archived ? ARCHIVED_ISSUES : ISSUES,
                );
            },
            () => {
                this.setState({uploadingInProgress: false});
                this.showNotification(
                    'Edycja elementu nie powiodła się',
                    TYPES.error,
                );
            },
        );
    };

    deleteIssue = () => {
        const {issue} = this.state;
        if (window.confirm('Na pewno chcesz usunąć to zgłoszenie?')) {
            this.setState({uploadingInProgress: true});
            this.props.deleteIssue(
                issue,
                () => {
                    this.setState({uploadingInProgress: false});
                    this.showNotification('Usunięto zgłoszenie', 'success');
                    this.props.history.push(
                        this.props.archived ? ARCHIVED_ISSUES : ISSUES,
                    );
                },
                () => {
                    this.setState({uploadingInProgress: false});
                    this.showNotification(
                        'Usuwanie zgłoszenia nie powiodło się',
                        TYPES.error,
                    );
                },
            );
        }
    };
}

const mapStateToProps = state => ({
    locations: state.location.locations,
    elements: state.element.elements,
    issuePhotoUris: state.issue.issuePhotoUris,
    solutionPhotoUris: state.issue.solutionPhotoUris,
    selectedBranches: state.branch.selectedBranches,
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
});

const mapDispatchToProps = dispatch => ({
    fetchLocations: querySnapshot => dispatch(fetchLocations(querySnapshot)),
    fetchElements: querySnapshot => dispatch(fetchElements(querySnapshot)),
    updateIssue: dispatch(updateIssue),
    getElementsByLocationId: locationId =>
        dispatch(getElementsByLocationId(locationId)),
    setUsers: users => dispatch(setUsers(users)),
    deleteIssue: dispatch(deleteIssue),
    downloadPhotos: dispatchAndDownloadPhotos(dispatch),
    clearElements: () => dispatch(setElements([])),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueDetails);
