import React from 'react';
import styles from './DrawerItems.module.css';

const archive = () => (
    <img
        className={styles.draverIcon}
        alt=""
        src={require('../../../static/images/icons/archive.svg')}
    />
);

const issue = () => (
    <img
        className={styles.draverIcon}
        alt=""
        src={require('../../../static/images/icons/folder-file-1.svg')}
    />
);

const users = () => (
    <img
        className={styles.draverIcon}
        alt=""
        src={require('../../../static/images/icons/multiple-neutral-2.svg')}
    />
);

const report = () => (
    <img
        className={styles.draverIcon}
        alt=""
        src={require('../../../static/images/icons/presentation-board-graph.svg')}
    />
);

const elements = () => (
    <img
        className={styles.draverIcon}
        alt=""
        src={require('../../../static/images/icons/task-list-approve.svg')}
    />
);

const location = () => (
    <img
        className={styles.draverIcon}
        alt=""
        src={require('../../../static/images/icons/pin-1.svg')}
    />
);

const task = () => (
    <img
        className={styles.draverIcon}
        alt=""
        src={require('../../../static/images/icons/tasks_list.svg')}
    />
);

const branch = () => (
    <img
        className={styles.draverIcon}
        alt=""
        src={require('../../../static/images/icons/buildings.svg')}
    />
);

export default {
    ArchiveIcon: archive,
    IssuesIcon: issue,
    LocationIcon: location,
    ReportsIcon: report,
    UsersIcon: users,
    ElementsIcon: elements,
    TasksIcon: task,
    BranchesIcon: branch,
};
