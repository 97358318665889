import React, {Component} from 'react';
import Dialog from '../../common/components/Dialog/Dialog';
import {CustomTextField} from '../../common/components';
import {connect} from 'react-redux';
import BranchPicker from '../../common/components/branchPicker/BranchPicker';
import {TYPES} from '../../constants/error';

class ChangeLocationDialog extends Component {
    state = {
        id: null,
        name: '',
        branch: null,
        dialogRef: null,
    };

    constructor(props) {
        super(props);
        this.state.id = props.location.id;
        this.state.name = props.location.name;
        this.state.branch = props.location.branch;
    }

    render() {
        const {dialogTitle} = this.props;
        const {name, branch} = this.state;
        return (
            <Dialog
                loading={false}
                handleClose={this.onClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={this.isSubmitButtonDisabled()}
                onSubmitHandler={this.onSubmitNewLocationHandler}
            >
                <BranchPicker
                    handleChange={this.onBranchChange}
                    branches={this.props.branches}
                    selectedBranch={branch}
                    menuPortalTarget={this.state.dialogRef}
                />
                {this.getTextField('Nazwa', this.onLocationNameChange, name)}
            </Dialog>
        );
    }

    getTextField(topLabel, onChange, value) {
        return (
            <CustomTextField
                label={topLabel}
                onChange={onChange}
                value={value}
                fullWidth={true}
            />
        );
    }

    onLocationNameChange = event => {
        this.setState({name: event.target.value});
    };

    onBranchChange = change => {
        const branch = {id: change.value, name: change.label};
        this.setState({branch});
    };

    isSubmitButtonDisabled = () => {
        const {name, branch} = this.state;
        const {location} = this.props;
        return (
            !name ||
            !branch ||
            (name === location.name && branch.id === location.branch.id)
        );
    };

    onClose = () => {
        this.props.handleClose();
    };

    onSubmitNewLocationHandler = () => {
        const {branch, name, id} = this.state;
        const locationToSet = {
            id,
            name: name.trim(),
            branch: {
                id: branch.id,
                name: branch.name,
            },
        };
        this.props.onUpdateLocationEvent(
            locationToSet,
            () => {
                this.props.showNotification(
                    'Zaktualizowano element',
                    TYPES.success,
                );
            },
            message => {
                this.props.showNotification(message, TYPES.error);
            },
        );
        this.onClose();
    };
}

const mapStateToProps = store => ({
    branches: store.branch.branches,
});

export default connect(mapStateToProps)(ChangeLocationDialog);
