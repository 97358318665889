import React from 'react';
import styles from './IssueDetails.module.css';
import {
    CustomTextField,
    LocationsPicker,
    ElementsPicker,
    ImageDisplay,
    UserPicker,
    Checkbox,
    Box,
    BackNavigation,
    CustomButton,
    StatusPicker,
    CustomDatePicker,
    ImageDropzone,
    CustomCircularProgress,
} from '../../../src/common/components';
import classnames from 'classnames';
import {ISSUES, ARCHIVED_ISSUES} from '../../constants/routes';

const IssueDetailsLayout = ({
    innerComponents: [DeleteButton, Hr, TextField, Dropzone],
    roleProps: {
        archivedIssueInputsDisabled,
        activeIssueInputsDisabled,
        statuses,
    },
    props,
}) => {
    const {formDisabled} = props;
    const inputsDisabled =
        formDisabled ||
        (props.archived
            ? archivedIssueInputsDisabled
            : activeIssueInputsDisabled);
    const descriptionDisabled =
        formDisabled || (props.archived ? archivedIssueInputsDisabled : false);
    const issueImagesDisabled =
        formDisabled || (props.archived ? archivedIssueInputsDisabled : false);
    const saveButtonVisible =
        !formDisabled && (props.archived ? !archivedIssueInputsDisabled : true);
    const backNavText = props.archived
        ? 'Wróć do archiwum'
        : 'Wróć do listy zgłoszeń';
    const backUrl = props.archived ? ARCHIVED_ISSUES : ISSUES;

    return (
        <div className={styles.mobileBackground}>
            <BackNavigation
                text={backNavText}
                header={props.issue.element.name}
                backUrl={backUrl}
            />
            {props.uploadingInProgress && <CustomCircularProgress />}
            <div className={styles.root}>
                <Box className={styles.issueDetailsBox}>
                    <section className={styles.buttons}>
                        {DeleteButton({
                            className: classnames(
                                styles.button,
                                styles.deleteButton,
                            ),
                            text: 'Usuń zgłoszenie',
                            contained: false,
                            onClick: props.deleteIssue,
                        })}
                        {saveButtonVisible && (
                            <CustomButton
                                className={classnames(
                                    styles.button,
                                    styles.saveButton,
                                )}
                                text="Zapisz zmiany"
                                disabled={props.isSubmitButtonDisabled}
                                onClick={props.updateIssue}
                            />
                        )}
                    </section>
                    <div className={styles.issueDetails}>
                        <LocationsPicker
                            selectedLocation={props.selectedLocation}
                            handleChange={props.onLocationChange}
                            locations={props.locations}
                            disabled={inputsDisabled}
                        />
                        <ElementsPicker
                            selectedElement={props.selectedElement}
                            handleChange={props.onElementChange}
                            elements={props.elements}
                            disabled={inputsDisabled}
                        />
                        <ImageDisplay
                            templateSrc={require('../../static/images/icons/picture-landscape.svg')}
                            src={
                                props.selectedElement &&
                                props.selectedElement.iconUri
                            }
                            title="Zdjęcie elementu"
                            fullSizeSrc={
                                props.selectedElement &&
                                props.selectedElement.patternUri
                            }
                        />
                        <CustomDatePicker
                            label="Termin gwarancji"
                            value={props.issue.element.warrantyTo}
                            readOnly
                        />

                        <CustomDatePicker
                            label="Data dodania"
                            value={props.issue.createdDate}
                            readOnly
                        />
                        <CustomTextField
                            className={styles.textField}
                            label="Autor zgłoszenia"
                            defaultValue={`${props.issue.reporter.name} ${props.issue.reporter.surname}`}
                            disabled
                        />
                        <StatusPicker
                            selectedStatus={props.issue.status}
                            statuses={statuses}
                            handleChange={props.onStatusChange}
                            disabled={inputsDisabled}
                        />
                        <UserPicker
                            label={'Przypisany pracownik'}
                            selectedUser={props.issue.assignedTo}
                            handleChange={props.onAssignedEmployeeChange}
                            users={props.users}
                            disabled={inputsDisabled}
                        />
                        {props.archived && (
                            <CustomDatePicker
                                label="Data zakończenia"
                                value={props.issue.closedDate}
                                readOnly
                            />
                        )}
                        {props.archived && (
                            <UserPicker
                                label="Zakończone przez"
                                selectedUser={props.issue.acceptedBy}
                                handleChange={props.onAcceptedByChange}
                                users={props.users}
                                disabled={inputsDisabled}
                            />
                        )}
                        {(inputsDisabled
                            ? props.issue.urgentPriority
                            : true) && (
                            <Checkbox
                                checked={props.issue.urgentPriority}
                                onChange={props.onUrgentPriorityCheckboxChange}
                                label="Zgłoszenie krytyczne"
                                showIcon={inputsDisabled}
                            />
                        )}
                        {(inputsDisabled ? props.issue.highPriority : true) &&
                            !props.organizationData
                                .importantFlagOnIssueHidden && (
                                <Checkbox
                                    checked={props.issue.highPriority}
                                    onChange={
                                        props.onHighPriorityCheckboxChange
                                    }
                                    label="Zgłoszenie pilne"
                                    showIcon={inputsDisabled}
                                />
                            )}
                        <CustomTextField
                            className={styles.textField}
                            label="Opis zgłoszenia"
                            placeholder="Wpisz opis zgłoszenia"
                            value={props.issue.issueDescription}
                            multiline
                            onChange={props.onTextChangeHandlerBuilder(
                                'issueDescription',
                            )}
                            disabled={descriptionDisabled}
                        />
                        <ImageDropzone
                            label="Zdjęcie zgłoszenia"
                            imagesSrc={props.issueImagesSrc}
                            onRemoveImage={props.onRemovePhotoHandlerBuilder(
                                'issueImages',
                            )}
                            onDrop={props.onAddNewImageHandlerBuilder(
                                'issueImages',
                            )}
                            disabled={issueImagesDisabled}
                        />
                    </div>
                    {Hr({className: styles.separator})}
                    <div className={styles.issueDetails}>
                        {TextField({
                            className: styles.textField,
                            label: 'Opis rozwiązania',
                            placeholder: 'Wpisz opis rozwiązania',
                            value: props.issue.solutionDescription,
                            multiline: true,
                            onChange: props.onTextChangeHandlerBuilder(
                                'solutionDescription',
                            ),
                            disabled: inputsDisabled,
                        })}
                        {Dropzone({
                            label: 'Zdjęcie rozwiązania',
                            imagesSrc: props.solutionImagesSrc,
                            onRemoveImage:
                                props.onRemovePhotoHandlerBuilder(
                                    'solutionImages',
                                ),
                            onDrop: props.onAddNewImageHandlerBuilder(
                                'solutionImages',
                            ),
                            disabled: inputsDisabled,
                        })}
                    </div>
                </Box>
            </div>
        </div>
    );
};
export default IssueDetailsLayout;
