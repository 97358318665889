import React from 'react';
import Picker from '../picker/Picker';

const locationsPicker = props => {
    const branchNames = props.locations.reduce((set, location) => {
        set.add(location.branch.name);
        return set;
    }, new Set());

    const items =
        props.locations &&
        [...branchNames].map(name => {
            const options = props.locations
                .filter(location => location.branch.name === name)
                .map(location => ({
                    value: location.id,
                    label: location.name,
                    group: location.branch.name,
                }));
            return {label: name, options};
        });

    const selectedValue = props.selectedLocation && {
        value: props.selectedLocation.id,
        label: props.selectedLocation.name,
    };

    return (
        <Picker
            label="Lokalizacja"
            selectedValue={selectedValue}
            pickerItems={items}
            name="Miejsca"
            first={props.first}
            placeholder={'Szukaj lokalizacji'}
            handleChange={props.handleChange}
            className={props.className}
            disabled={props.disabled}
        />
    );
};

locationsPicker.defaultProps = {
    bottomLabelActive: true,
};

export default locationsPicker;
