import firebase from 'firebase/app';
import {AUTH_SET_USER_DATA, AUTH_SET_ORGANIZATION_DATA} from './actionTypes';
import {clearStorage, saveUserData} from '../../storage/localStorage';
import {setUserSelectedBranches, setBranches} from './branch';

export const tryAuth = (authData, onLoginFailed, onLoginSuccess) => {
    return dispatch => {
        firebase
            .auth()
            .signInWithEmailAndPassword(authData.login, authData.password)
            .then(result => {
                fetchUserData(result.user.uid)
                    .then(doc => {
                        const userData = doc.data();
                        saveUserData(userData);
                        authSetUserData(userData || {});
                        dispatch(setUserSelectedBranches(userData.branches));
                        dispatch(setBranches(userData.branches));
                        onLoginSuccess();
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(error => {
                onLoginFailedHandler(error.code, onLoginFailed);
            });
    };
};

const onLoginFailedHandler = (code, onError) => {
    switch (code) {
        case 'auth/invalid-email':
        case 'auth/wrong-password':
            onError('Niepoprawne dane do logowania');
            break;
        case 'auth/user-disabled':
            onError('Podane konto jest nieaktywne');
            break;
        case 'auth/user-not-found':
            onError('Nie znaleziono użytkownika o podanym adresie e-mail');
            break;
        default:
            onError('Błąd logowania');
    }
};

const onResetFailedHandler = (code, onError) => {
    switch (code) {
        case 'auth/invalid-email':
        case 'auth/wrong-password':
            onError('Niepoprawny adres email');
            break;
        case 'auth/user-disabled':
            onError('Podane konto jest nieaktywne');
            break;
        case 'auth/user-not-found':
            onError('Nie znaleziono e-mailu');
            break;
        default:
            onError('Błąd resetu hasła');
    }
};

export const fetchUserData = userId => {
    const userRef = firebase.firestore().collection('user').doc(userId);
    return userRef.get();
};

export const fetchOrganizationData = organization => {
    const organizationRef = firebase
        .firestore()
        .collection('organization')
        .doc(organization);
    return organizationRef.get();
};

export const sendResetPasswordEmail = (email, onError) => dispatch => {
    firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(result => console.log('Succesfully send email'))
        .catch(error => onResetFailedHandler(error.code, onError));
};

export const logOut = () => {
    return dispatch => {
        firebase
            .auth()
            .signOut()
            .then(result => {
                console.log('Successfully logged out');
                dispatch(authSetUserData(null));
                clearStorage();
            })
            .catch(error => {
                console.log('Could not log out');
                console.log(error);
            });
    };
};

export const authSetUserData = userData => {
    return {
        type: AUTH_SET_USER_DATA,
        userData,
    };
};

export const authSetOrganizationData = organizationData => ({
    type: AUTH_SET_ORGANIZATION_DATA,
    organizationData,
});
