import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import * as styles from './ListItem.module.css';
import classNames from 'classnames';
import TrashIcon from '../../../../static/images/icons/trash.svg';
import {withUserRole} from '../../../../hoc/User/WithUserRole';

const ListItem = props => (
    <TableRow
        classes={{root: classNames(styles.listItem, props.className)}}
        hover={true}
    >
        {React.Children.map(props.children, (child, index) => {
            const classForCell = props.columns && styles[props.columns[index]];
            return (
                <TableCell
                    classes={{
                        root: classNames(styles.cell, classForCell),
                    }}
                    onClick={props.onClick}
                >
                    {child}
                </TableCell>
            );
        })}
        {props.deletable && (
            <td className={styles.cell} onClick={props.onDelete}>
                <img
                    src={TrashIcon}
                    className={styles.bin}
                    alt="Usuń element"
                />
            </td>
        )}
    </TableRow>
);

export default ListItem;
