import React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/es/DialogActions/DialogActions';
import DialogTitle from '@material-ui/core/es/DialogTitle/DialogTitle';
import {CloseButton, AddButton, CancelButton, CustomCircularProgress} from '..';
import styles from './Dialog.module.css';

const dialog = React.forwardRef((props, ref) => (
    <Dialog
        classes={{
            paper: styles.window,
        }}
        ref={ref}
        fullWidth
        open
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
    >
        {props.loading && <CustomCircularProgress />}
        <CloseButton onClick={props.handleClose} />
        <DialogTitle id="form-dialog-title" className={styles.title}>
            {props.dialogTitle}
        </DialogTitle>
        <hr className={styles.hrInMobileForm} />
        <DialogContent className={styles.content}>
            {props.children}
        </DialogContent>
        <DialogActions className={styles.actions}>
            <CancelButton
                className={styles.cancelButton}
                text="Anuluj"
                onClick={props.handleClose}
            />
            <AddButton
                text={props.submitButtonText}
                disabled={props.submitButtonDisabled}
                onClick={props.onSubmitHandler}
            />
        </DialogActions>
    </Dialog>
));

dialog.defaultProps = {
    submitButtonText: 'Zapisz zmiany',
};

export default dialog;
