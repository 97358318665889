import React from 'react';
import styles from './NotificationIndicator.module.css';
import {TYPES} from '../../../../constants/error';

const notificationIndicator = ({type}) =>
    type === TYPES.error ? (
        <img
            className={styles.indicator}
            src={require('../../../../static/images/icons/remove.svg')}
            alt={''}
        />
    ) : (
        <img
            className={styles.indicator}
            src={require('../../../../static/images/icons/check-circle.svg')}
            alt={''}
        />
    );

export default notificationIndicator;
