import {Component} from 'react';
import {connect} from 'react-redux';
import {
    fetchLocations,
    getElementsByLocationId,
    onCreateNewIssue,
    setElements,
} from '../../../store/action';
import AddIssueLayout from './AddIssueLayout';
import {MAX_PHOTOS, MAX_PHOTO_SIZE} from '../../../constants/files';
import {mappings} from './AddIssueRoleRoleMapper';
import {withUserRole} from '../../../hoc/User/WithUserRole';
import {NotificationContext} from '../../../context/notifications';
import getUsersQuery from '../../../utils/queryBuilder/UsersQueryBuilder';
import getLocationsQuery from '../../../utils/queryBuilder/LocationQueryBuilder';
import {TYPES} from '../../../constants/error';
import {getUserRole} from '../../../store/action/authHelpers';

class AddIssueDialog extends Component {
    state = {
        selectedLocation: null,
        selectedElement: null,
        assignedEmployee: null,
        issueImageFiles: [],
        highPriority: false,
        urgentPriority: false,
        description: '',
        elementIconUrl: '',
        users: [],
    };

    static contextType = NotificationContext;
    unsubscribeLocations = null;
    notificationSystem = null;

    componentDidMount() {
        const {selectedBranches} = this.props;
        const branchIds = selectedBranches.map(branch => branch.id);
        this.subscribeOnLocations(branchIds);
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            const branchIds = this.props.selectedBranches.map(
                branch => branch.id,
            );
            this.unsubscribeLocations && this.unsubscribeLocations();
            this.subscribeOnLocations(branchIds);
        }
    }

    subscribeOnLocations(branchIds) {
        this.unsubscribeLocations = getLocationsQuery(branchIds).onSnapshot(
            this.props.fetchLocations,
            console.error,
        );
    }

    componentWillUnmount() {
        this.unsubscribeLocations();
    }

    render() {
        const {locations, open, elements, loading, organizationData} =
            this.props;

        const {
            selectedElement,
            highPriority,
            urgentPriority,
            assignedEmployee,
            selectedLocation,
            issueImageFiles,
            users,
        } = this.state;
        return withUserRole(AddIssueLayout, mappings, {
            open,
            onClose: this.onClose,
            selectedLocation,
            onLocationChange: this.onLocationChange,
            locations,
            selectedElement,
            onElementChange: this.onElementChange,
            elements,
            assignedEmployee,
            onAssignedEmployeeChange: this.onAssignedEmployeeChange,
            users,
            highPriority,
            urgentPriority,
            loading,
            organizationData,
            onHighPriorityChange: () =>
                this.setState({
                    urgentPriority: false,
                    highPriority: !highPriority,
                }),
            onUrgentPriorityChange: () =>
                this.setState({
                    highPriority: false,
                    urgentPriority: !urgentPriority,
                }),
            onDescriptionChange: this.onDescriptionChange,
            isSubmitButtonDisabled: this.isSubmitButtonDisabled(),
            onSubmitNewIssueHandler: this.onSubmitNewIssueHandler,
            issueImageFiles: issueImageFiles,
            onIssueImageDrop: this.onIssueImageDrop,
            onRemoveIssueImage: this.onRemoveIssueImage,
        });
    }

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    onRemoveIssueImage = index => {
        const issueImageFiles = [...this.state.issueImageFiles];
        issueImageFiles.splice(index, 1);
        this.setState({issueImageFiles});
    };

    onAssignedEmployeeChange = event => {
        const user = this.state.users.find(user => event.value === user.uid);
        this.setState({assignedEmployee: user});
    };

    onIssueImageDrop = files => {
        const {issueImageFiles} = this.state;
        const numberOfunacceptedFiles = files.filter(
            file => file.size > MAX_PHOTO_SIZE,
        ).length;
        const fullImageCount = issueImageFiles.length + files.length;
        if (fullImageCount > MAX_PHOTOS) {
            this.showNotification(
                'Nie można dodać więcej niż 5 zdjęć',
                TYPES.error,
            );
        } else if (numberOfunacceptedFiles > 0) {
            this.showNotification(
                'Maksymalny rozmiar zdjęcia to 5MB',
                TYPES.error,
            );
        } else {
            const images = [...this.state.issueImageFiles, ...files];
            this.setState({issueImageFiles: images});
        }
    };

    onDescriptionChange = event => {
        this.setState({description: event.target.value});
    };

    onLocationChange = event => {
        const location = {
            ...this.props.locations.find(
                location => event.value === location.id,
            ),
        };
        if (location !== this.state.selectedLocation) {
            this.setState({
                selectedLocation: location,
                selectedElement: null,
            });
            const {rolesToFetchUsersWith} = mappings[getUserRole()].roleProps;
            getUsersQuery()
                .withBranch(location.branch)
                .withRoles(rolesToFetchUsersWith)
                .get()
                .then(users => this.setState({users}))
                .catch(console.error);
            this.props.getElementsByLocationId(location.id);
        }
    };

    onElementChange = event => {
        const element = {
            ...this.props.elements.find(element => event.value === element.id),
        };
        this.setState({selectedElement: element});
    };

    isSubmitButtonDisabled = () =>
        !this.state.selectedLocation ||
        !this.state.selectedElement ||
        !this.state.description.length;

    onClose = () => {
        this.setState(
            {
                selectedLocation: null,
                selectedElement: null,
                assignedEmployee: null,
                issueImageFiles: [],
                highPriority: false,
                description: '',
            },
            () => {
                this.props.clearElements();
                this.props.handleClose();
            },
        );
    };

    onSubmitNewIssueHandler = () => {
        const {
            description,
            highPriority,
            urgentPriority,
            assignedEmployee,
            issueImageFiles,
            selectedElement,
        } = this.state;

        const issue = {
            issueDescription: description,
            highPriority,
            urgentPriority,
            branch: selectedElement.location.branch,
        };

        this.props.onCreateNewIssue(
            issue,
            selectedElement,
            assignedEmployee,
            issueImageFiles,
            () => this.showNotification('Dodano zgłosznie', 'success'),
            () =>
                this.showNotification(
                    'Błąd podczas dodawania zgłoszenia',
                    TYPES.error,
                ),
        );
        this.onClose();
    };
}

const mapStateToProps = state => {
    return {
        elements: state.element.elements,
        userData: state.auth.userData,
        organizationData: state.auth.organizationData,
        locations: state.location.locations,
        selectedBranches: state.branch.selectedBranches,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchLocations: querySnapshot =>
            dispatch(fetchLocations(querySnapshot)),
        getElementsByLocationId: locationId =>
            dispatch(getElementsByLocationId(locationId)),
        onCreateNewIssue: dispatch(onCreateNewIssue),
        clearElements: () => dispatch(setElements([])),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIssueDialog);
