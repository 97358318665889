import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDown from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import ListHeaderFilterPanel from './listHeaderFilterPanel/ListHeaderFilterPanel';
import {DESC} from '../../../../constants/sortingDirections';

import './ListHeader.css';

const ListHeaderCellContent = props => (
    <TableCell key={props.column.id}>
        <div className="header-column-cell-content">
            {props.column.filterElementGetter && (
                <IconButton
                    aria-label="Filter column"
                    onClick={props.onFilterIconClick}
                >
                    <FilterListIcon />
                </IconButton>
            )}
            {props.column.sorting && (
                <TableSortLabel
                    active={props.column.sorting}
                    direction={
                        props.column.id === props.orderBy
                            ? props.sortDirection
                            : DESC
                    }
                    IconComponent={props => (
                        <ArrowDropDown
                            {...props}
                            style={{fontSize: 15, color: 'var(--light-blue)'}}
                        />
                    )}
                    onClick={
                        props.column.sorting
                            ? props.sortHandler(
                                  props.column.id,
                                  props.column.customComparator,
                              )
                            : undefined
                    }
                >
                    <div className={'cell'}>{props.column.label}</div>
                </TableSortLabel>
            )}

            {props.column.filterElementGetter && (
                <ListHeaderFilterPanel
                    filterElementGetter={props.column.filterElementGetter}
                    isOpen={props.filterIsOpen}
                    onClose={props.onFilterIconClick}
                />
            )}
        </div>
    </TableCell>
);

export default ListHeaderCellContent;
