import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import {ASC, DESC} from '../../../constants/sortingDirections';
import ListHeader from './listHeader/ListHeader';
import {descendingSort} from '../../../utils/StringUtils/StringUtils';
import {sortRows, getComparator} from '../../../utils/sorting/sorting';
import CustomScrollbar from '../CustomScrollbar/CustomScrollbar';
import styles from './List.module.css';

class List extends Component {
    state = {
        sortDirection: DESC,
        orderBy: '',
        openedFilterNumber: -1,
    };

    customComparator;

    render() {
        const {sortDirection, orderBy, openedFilterNumber} = this.state;
        const {columns, rows, rowTemplate} = this.props;

        const accessor =
            orderBy &&
            columns.find(item => item.id === orderBy).accessAttribute;
        const sortedRows = orderBy
            ? sortRows(this.getSorting(), accessor, rows)
            : [...rows];

        return (
            <div
                className={`${styles.list} ${this.props.className} ${styles.noDisplayMobile}`}
            >
                {columns.length ? (
                    <CustomScrollbar>
                        <Table>
                            <ListHeader
                                columns={columns}
                                sortDirection={sortDirection}
                                orderBy={orderBy}
                                sortHandler={this.changeOrder}
                                onFilterIconClick={this.onFilterIconClick}
                                openedFilterNumber={openedFilterNumber}
                            />
                            <TableBody>
                                {sortedRows.map(row => rowTemplate(row))}
                            </TableBody>
                        </Table>
                    </CustomScrollbar>
                ) : (
                    'Loading...'
                )}
            </div>
        );
    }

    onFilterIconClick = index => () => {
        this.setState(prevState => ({
            openedFilterNumber:
                prevState.openedFilterNumber === index ? -1 : index,
        }));
    };

    changeOrder = (property, customComparator) => () => {
        const orderBy = property;
        let sortDirection = DESC;

        if (
            this.state.orderBy === property &&
            this.state.sortDirection === DESC
        ) {
            sortDirection = ASC;
        }

        this.customComparator = customComparator;
        this.setState({sortDirection, orderBy, page: 0});
    };

    getSorting() {
        return getComparator(
            this.customComparator ? this.customComparator : descendingSort,
            this.state.sortDirection,
        );
    }
}

export default List;
