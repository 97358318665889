import React from 'react';
import DateInput from './DateInput';
import styles from './DatesInput.module.css';

const datesInput = ({onDateChange}) => (
    <div className={styles.container}>
        <DateInput
            className={styles.dateInput}
            label="Od"
            onChange={event => onDateChange('startDate', event.target.value)}
        />
        <DateInput
            className={styles.dateInput}
            label="Do"
            onChange={event => onDateChange('endDate', event.target.value)}
        />
    </div>
);

export default datesInput;
