import {defaultConverter} from '../firestoreDocumentUtils/firestoreDocumentUtils';
import {
    mapSnapshotToEntities,
    wrapQueryExecution,
} from '../firestore/firestore';
import {chunkArray, mergeArrays} from '../array/array';
import MultiSnapshotHandler from '../firestore/MultiSnapshotHandler';
import {isEmpty} from 'lodash';

const IN_OPERATOR_LIMIT = 10;

export default class QueryBuilder {
    _createQuery = null;
    _branchIds = null;
    _converter = null;
    _queryConfig = {params: {}};
    _pathToBranchId = 'branch.id';

    constructor(
        createQuery,
        branchIds,
        {pathToBranchId},
        converter = defaultConverter,
    ) {
        this._createQuery = createQuery;
        this._branchIds = branchIds;
        this._converter = converter;
        this._pathToBranchId = pathToBranchId || this._pathToBranchId;
    }

    onSnapshot = (callback, onError) => {
        const chunkedBranchIds = chunkArray(
            [...this._branchIds],
            IN_OPERATOR_LIMIT,
        );
        const snapshotHandler = new MultiSnapshotHandler(
            chunkedBranchIds.length,
            callback,
        );
        if (isEmpty(chunkedBranchIds)) {
            callback([]);
        }
        const unsubscribeMethods = chunkedBranchIds
            .map(this.__buildFullQuery)
            .map((query, index) =>
                query.onSnapshot(snapshot => {
                    const mappedEntities = mapSnapshotToEntities(snapshot);
                    snapshotHandler.addResult(mappedEntities, index);
                }, onError),
            );
        return () => unsubscribeMethods.forEach(method => method());
    };

    __buildFullQuery = branchIds => {
        return this._queryConfig.params.location
            ? this._createQuery()
                  .where(this._pathToBranchId, 'in', branchIds)
                  .where('location.id', '==', this._queryConfig.params.location)
                  .withConverter(this._converter)
            : this._createQuery()
                  .where(this._pathToBranchId, 'in', branchIds)
                  .withConverter(this._converter);
    };

    withLocation = locationId => {
        this._queryConfig.params.location = locationId;
        return this;
    };

    get = () => {
        const chunkedBranches = chunkArray([
            ...this._branchIds,
            IN_OPERATOR_LIMIT,
        ]);
        this._queries = chunkedBranches.map(this.__buildFullQuery);
        const promises = this._queries.map(query => {
            return wrapQueryExecution(query.get());
        });
        return Promise.all(promises).then(mergeArrays);
    };
}
