import React from 'react';
import classnames from 'classnames';
import Button from '@material-ui/core/Button/Button';

import styles from './Buttons.module.css';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBackIos';

export const CustomButton = props => (
    <Button
        variant={props.contained ? 'contained' : 'text'}
        color={props.contained ? 'primary' : 'default'}
        disabled={props.disabled}
        className={classnames(styles.customButton, props.className)}
        classes={{
            containedPrimary: styles.primary,
        }}
        fullWidth={props.fullWidth}
        onClick={props.onClick}
        type={props.type}
    >
        {props.text}
    </Button>
);
CustomButton.defaultProps = {
    contained: true,
};

export const closeButton = ({onClick, className}) => (
    <div className={styles.closeButton} onClick={onClick}>
        <CloseIcon
            className={classnames(styles.icon, styles.nomobile, className)}
        />
        <ArrowBack
            className={classnames(styles.icon, styles.mobile, className)}
        />
    </div>
);

export const cancelButton = ({text, onClick}) => (
    <CustomButton
        className={styles.cancelButton}
        text={text}
        contained={false}
        onClick={onClick}
    />
);

export const addButton = ({disabled, text, onClick, type, className}) => (
    <CustomButton
        className={classnames(styles.addButton, className)}
        disabled={disabled}
        text={text}
        onClick={onClick}
        type={type}
    />
);
