import React, {useState} from 'react';
import styles from './ImageDisplay.module.css';
import classnames from 'classnames';
import Dialog from '@material-ui/core/Dialog/Dialog';

const ImageDisplay = ({
    title,
    src,
    fullSizeSrc,
    onDelete,
    alt,
    onImageClick,
    imgClassName,
    deletable,
    templateSrc,
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    fullSizeSrc = fullSizeSrc || src;
    return (
        <div className={styles.row}>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.wrapper}>
                <img
                    className={classnames(
                        styles.imagePreview,
                        {
                            [styles.iconTemplate]: !src,
                        },
                        imgClassName,
                    )}
                    onClick={
                        onImageClick
                            ? onImageClick
                            : () => setDialogOpen(Boolean(src))
                    }
                    src={src || templateSrc}
                    alt={alt}
                />
                {deletable && (
                    <div className={styles.closeButton} onClick={onDelete} />
                )}
                {src && !onImageClick && (
                    <Dialog
                        maxWidth={false}
                        open={dialogOpen}
                        onClick={() => setDialogOpen(false)}
                    >
                        <img src={fullSizeSrc} alt={alt} />
                    </Dialog>
                )}
            </div>
        </div>
    );
};

export default ImageDisplay;
