import React from 'react';
import {components} from 'react-select';
import styles from './componentsForPicker.module.css';

export const Option = props => (
    <div>
        <components.Option {...props}>
            <input
                className={styles.checkbox}
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />
            <label className={styles.label}>{props.label}</label>
        </components.Option>
    </div>
);

export const ValueContainer = ({children, ...props}) => {
    const currentValues = props.getValue();

    return (
        <components.ValueContainer {...props}>
            {currentValues.length > 1
                ? [[children[0][0]], children[1]]
                : children}
        </components.ValueContainer>
    );
};

export const MultiValue = props => {
    let labelToBeDisplayed = props.data.label;
    if (props.options.length === props.selectProps.value.length) {
        labelToBeDisplayed = 'Wszystkie';
    } else if (props.selectProps.value.length > 1) {
        labelToBeDisplayed = 'Wiele';
    }

    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};
