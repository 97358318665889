import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import {Checkbox} from '../';
import {STATUS_LABELS, PRIORITY_LABEL} from '../../../constants/issues';

import styles from './SearchField.module.css';

const SearchField = props => (
    <div className={styles.container}>
        <div className={styles.header}>Szukaj</div>
        <div className={styles.body}>
            <img
                src={require('../../../static/images/icons/search.svg')}
                alt=""
                className={styles.icon}
            />

            <TextField
                variant="outlined"
                className={styles.searchField}
                placeholder={props.placeholder ? props.placeholder : 'Szukaj'}
                InputProps={{
                    className: styles.innerContainer,
                    classes: {
                        notchedOutline: styles.outline,
                    },
                }}
                inputProps={{
                    className: styles.input,
                }}
                onChange={props.onSearchTextChange}
            />
            {props.withFilter && (
                <img
                    src={require('../../../static/images/icons/filter.svg')}
                    alt=""
                    className={styles.filter}
                    onClick={props.onFilterIconClickHandler}
                />
            )}
        </div>
        {props.withFilter && props.openFilter && (
            <>
                <div className={styles.filterDrawer}>
                    <Checkbox
                        className={styles.checkboxMargin}
                        label={STATUS_LABELS.open}
                        checked={props.filterState.open}
                        onChange={props.onCheckboxClick('open')}
                    />
                    <Checkbox
                        className={styles.checkboxMargin}
                        label={STATUS_LABELS.inProgress}
                        checked={props.filterState.inProgress}
                        onChange={props.onCheckboxClick('inProgress')}
                    />
                    <Checkbox
                        className={styles.checkboxMargin}
                        label={STATUS_LABELS.inReview}
                        checked={props.filterState.inReview}
                        onChange={props.onCheckboxClick('inReview')}
                    />
                    <Checkbox
                        className={styles.checkboxMargin}
                        label={PRIORITY_LABEL.important}
                        checked={props.filterState.important}
                        onChange={props.onCheckboxClick('important')}
                    />
                    <Checkbox
                        className={styles.checkboxMargin}
                        label={PRIORITY_LABEL.urgent}
                        checked={props.filterState.urgent}
                        onChange={props.onCheckboxClick('urgent')}
                    />
                </div>
                <div
                    className={styles.closeWrapper}
                    onClick={props.onFilterIconClickHandler}
                />
            </>
        )}
    </div>
);

export default SearchField;
