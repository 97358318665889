import React, {Component} from 'react';
import {isEqual} from 'lodash';
import {
    List,
    ListItem,
    ListColumnCreator,
    MobileListItem,
    MobileList,
    MobileDataDisplay,
} from '../../../common/components/index';

class BranchesList extends Component {
    state = {
        rows: [],
    };

    columns;

    constructor(props) {
        super(props);
        this.columns = [new ListColumnCreator('name', 'Nazwa oddziału', {})];
    }

    componentDidMount() {
        this.setState({rows: this.props.branches});
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.branches, prevProps.branches)) {
            this.setState({rows: this.props.branches});
        }
    }

    render() {
        const {rows} = this.state;
        return (
            <>
                <List
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getBranchListItemTemplate}
                />
                <MobileList
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getBranchMobileListItemTemplate}
                />
            </>
        );
    }

    getBranchListItemTemplate = branch => (
        <ListItem
            key={branch.id}
            onClick={this.props.onBranchClick(branch.id)}
            deletable
            onDelete={() => this.props.onDeleteBranch(branch)}
        >
            {branch.name}
        </ListItem>
    );

    getBranchMobileListItemTemplate = branch => (
        <MobileListItem
            key={branch.id}
            onClick={this.props.onBranchClick(branch.id)}
        >
            <MobileDataDisplay title={branch.name} withoutImage />
        </MobileListItem>
    );
}

export default BranchesList;
