import React from 'react';
import {
    AddButton,
    TimespanPicker,
    UserPicker,
    LocationsPicker,
    ElementsPicker,
} from '../../../common/components/index';
import {ListTitle, Picker} from '../../../common/components/index';

import styles from './ReportForm.module.css';
import {REPORT_TYPES} from '../../../constants/reports';

const reportForm = ({
    endDate,
    startDate,
    onGenerate,
    location,
    element,
    user,
    onDatePickerChangeHandler,
    reportType,
    reportPickerTypes,
    users,
    locations,
    elements,
    onReportTypeChange,
    onUserChange,
    onLocationChange,
    onElementChange,
    showElementPicker,
}) => {
    const items = reportPickerTypes.map(raportType => ({
        label: raportType.label,
        value: raportType.value,
    }));

    return (
        <div className={styles.reportForm}>
            <header>
                <ListTitle title="Raporty" />
                <span className={styles.mobileStyleButton}>
                    <AddButton
                        className={styles.generateBtn}
                        text="Generuj raport"
                        disabled={!(startDate && endDate)}
                        onClick={onGenerate}
                    />
                </span>
            </header>
            <div className={styles.whiteBackground}>
                <div className={styles.body}>
                    <Picker
                        label={'Typ raportu'}
                        name="Elementy"
                        placeholder="Wybierz typ raportu"
                        handleChange={onReportTypeChange}
                        pickerItems={items}
                        selectedValue={reportType}
                    />
                    <TimespanPicker
                        startDate={startDate}
                        endDate={endDate}
                        onDateChange={onDatePickerChangeHandler}
                    />
                    <UserPicker
                        label={'Pracownik'}
                        handleChange={onUserChange}
                        users={users}
                        clearable
                        selectedUser={user}
                        disabled={reportType.value === REPORT_TYPES.general}
                    />
                    <LocationsPicker
                        locations={locations}
                        handleChange={onLocationChange}
                        selectedLocation={location}
                        disabled={reportType.value === REPORT_TYPES.general}
                    />
                    {showElementPicker && (
                        <ElementsPicker
                            elements={elements}
                            handleChange={onElementChange}
                            selectedElement={element}
                            disabled={reportType.value === REPORT_TYPES.general}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default reportForm;
