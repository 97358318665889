import axios from 'axios';
import firebase from 'firebase/app';
import {database} from '../../../package.json';

const databaseType = Object.keys(database).find(field => database[field]);
const URLs = {
    develop: 'https://us-central1-usterka-dev-v2.cloudfunctions.net/',
    demo: 'https://us-central1-usterkav2-demo.cloudfunctions.net/',
    production: 'https://us-central1-usterka-prod-v2.cloudfunctions.net/',
};

const defaultInstance = axios.create({baseURL: URLs[databaseType]});
firebase.auth().onIdTokenChanged(async user => {
    if (user) {
        const token = await user.getIdToken();
        defaultInstance.defaults.headers.Authorization = `Bearer ${token}`;
    }
});

export default defaultInstance;
