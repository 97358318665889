import {createMuiTheme} from '@material-ui/core/styles';

export const MainTheme = createMuiTheme({
    palette: {primary: {main: '#047beb'}},
    overrides: {
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottomColor: '#047beb',
                },
                '&:before': {
                    borderBottomColor: 'white',
                },
                '&&&&:hover:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                },
            },
        },
    },
    typography: {
        useNextVariants: true,
    },
});
