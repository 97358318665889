import React from 'react';
import {
    ELEMENTS,
    LOCATIONS,
    REPORTS,
    USERS,
    ARCHIVED_ISSUES,
    TASKS,
    BRANCHES,
} from '../../../constants/routes';
import NavigationItem from '../NavigationItem/NavigationItem';
import Icons from './DrawerIcons';
import {roles} from '../../../constants/roles';

export const drawerItemsForRole = {
    [roles.ADMIN]: {
        innerComponents: [
            props => (
                <NavigationItem
                    path={LOCATIONS}
                    icon={<Icons.LocationIcon />}
                    title={'Lokalizacje'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={TASKS}
                    icon={<Icons.TasksIcon />}
                    title={'Lista zadań'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ELEMENTS}
                    icon={<Icons.ElementsIcon />}
                    title={'Lista elementów'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ARCHIVED_ISSUES}
                    icon={<Icons.ArchiveIcon />}
                    title={'Archiwum'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={REPORTS}
                    icon={<Icons.ReportsIcon />}
                    title={'Raporty'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={USERS}
                    icon={<Icons.UsersIcon />}
                    title={'Użytkownicy'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={BRANCHES}
                    icon={<Icons.BranchesIcon />}
                    title={'Oddziały'}
                    {...props}
                />
            ),
        ],
    },
    [roles.SUPERVISOR]: {
        innerComponents: [
            props => (
                <NavigationItem
                    path={LOCATIONS}
                    icon={<Icons.LocationIcon />}
                    title={'Lokalizacje'}
                    {...props}
                />
            ),
            () => null,
            props => (
                <NavigationItem
                    path={ELEMENTS}
                    icon={<Icons.ElementsIcon />}
                    title={'Lista elementów'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ARCHIVED_ISSUES}
                    icon={<Icons.ArchiveIcon />}
                    title={'Archiwum'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={REPORTS}
                    icon={<Icons.ReportsIcon />}
                    title={'Raporty'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={USERS}
                    icon={<Icons.UsersIcon />}
                    title={'Użytkownicy'}
                    {...props}
                />
            ),
            props => null,
        ],
    },
    [roles.MAINTAINER]: {
        innerComponents: [
            props => (
                <NavigationItem
                    path={LOCATIONS}
                    icon={<Icons.LocationIcon />}
                    title={'Lokalizacje'}
                    {...props}
                />
            ),
            () => null,
            props => (
                <NavigationItem
                    path={ELEMENTS}
                    icon={<Icons.ElementsIcon />}
                    title={'Lista elementów'}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ARCHIVED_ISSUES}
                    icon={<Icons.ArchiveIcon />}
                    title={'Archiwum'}
                    {...props}
                />
            ),
            props => null,
            props => null,
            props => null,
        ],
    },
    [roles.REPORTER]: {
        innerComponents: [
            props => null,
            props => null,
            props => null,
            props => null,
            props => null,
            props => null,
            props => null,
        ],
    },
};
