import {roles as ROLES} from '../../constants/roles';
import React from 'react';
import {CustomButton} from '../../common/components';
import CustomTextField from '../../common/components/textField/CustomTextField';
import ImageDropzone from '../../common/components/FileDropzone/imageDropzone';
import {statuses} from '../../constants/issues';

const mappings = {};

mappings[ROLES.MAINTAINER] = {
    innerComponents: [
        props => null,
        props => <hr {...props} />,
        props => <CustomTextField {...props} />,
        props => <ImageDropzone {...props} />,
    ],
    roleProps: {
        activeIssueInputsDisabled: false,
        archivedIssueInputsDisabled: true,
        statuses: [statuses.OPEN, statuses.IN_PROGRESS, statuses.IN_REVIEW],
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.SUPERVISOR] = {
    innerComponents: [
        props => null,
        props => <hr {...props} />,
        props => <CustomTextField {...props} />,
        props => <ImageDropzone {...props} />,
    ],
    roleProps: {
        activeIssueInputsDisabled: false,
        archivedIssueInputsDisabled: true,
        statuses: [
            statuses.OPEN,
            statuses.IN_PROGRESS,
            statuses.IN_REVIEW,
            statuses.DONE,
        ],
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.ADMIN] = {
    innerComponents: [
        props => <CustomButton {...props} />,
        props => <hr {...props} />,
        props => <CustomTextField {...props} />,
        props => <ImageDropzone {...props} />,
    ],
    roleProps: {
        activeIssueInputsDisabled: false,
        archivedIssueInputsDisabled: false,
        statuses: [
            statuses.OPEN,
            statuses.IN_PROGRESS,
            statuses.IN_REVIEW,
            statuses.DONE,
        ],
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.REPORTER] = {
    innerComponents: [
        props => null,
        props => null,
        props => null,
        props => null,
    ],
    roleProps: {
        activeIssueInputsDisabled: true,
        statuses: [statuses.OPEN, statuses.IN_PROGRESS, statuses.IN_REVIEW],
    },
};

export {mappings};
