import React, {useCallback, useState} from 'react';
import Dropzone from 'react-dropzone';
import styles from './fileDropzone.module.css';
import {FileDisplay} from '../';
const dropzoneIconSrc = require('../../../static/images/icons/Group.svg');

const FileDropzone = ({label, onDrop, filesSrc, onRemoveFile, disabled}) => {
    const onDropHandler = useCallback((accepted, rejected) => {
        if (!rejected.length) {
            onDrop(accepted);
        }
    }, []);
    const files =
        filesSrc &&
        filesSrc.map((file, index) => (
            <FileDisplay
                key={index}
                fileSrc={file.uri || file.path}
                fileName={file.name}
                onDelete={() => onRemoveFile(index)}
            />
        ));
    return (
        <div className={styles.fileDropzone}>
            <header>{label}</header>
            {!disabled && (
                <Dropzone onDrop={onDropHandler}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className={styles.content}>
                            <input {...getInputProps()} />
                            <img alt="" src={dropzoneIconSrc} />
                        </div>
                    )}
                </Dropzone>
            )}
            {files}
        </div>
    );
};
export default FileDropzone;
