import React from 'react';
import styles from './BurgerIcon.module.css';

export const BurgerIcon = () => (
    <span className={styles.burgerButton}>
        <span className={styles.line} />
        <span className={styles.line} />
        <span className={styles.line} />
    </span>
);
