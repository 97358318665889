import React from 'react';
import {CustomButton} from '../';

import './ListFilterTemplate.css';

const ListFilterTemplate = props => (
    <div className="filter-content">
        {props.children}
        <div className="filter-action-buttons">
            <CustomButton
                className="filter-action-button"
                text="Zastosuj"
                onClick={props.onApplyFilter}
            />
            <CustomButton
                className="filter-action-button"
                text="Anuluj"
                contained={false}
                onClick={props.onCancelFilter}
            />
        </div>
    </div>
);

export default ListFilterTemplate;
