import React from 'react';
import styles from './fileDisplay.module.css';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const fileDisplay = ({fileSrc, fileName, onDelete}) => (
    <span className={styles.row}>
        <a
            className={styles.noDecoration}
            href={fileSrc}
            download
            target="_blank"
        >
            <div className={styles.imagePreview}>
                <DescriptionOutlinedIcon className={styles.fileIcon} />
                <p className={styles.fileTitle}>{fileName}</p>
            </div>
        </a>
        <div className={styles.closeButton} onClick={onDelete} />
    </span>
);

export default fileDisplay;
