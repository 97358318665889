import {getUserDataFromStorage} from '../storage/localStorage';
import firebase from 'firebase/app';

export const issues = () => getEndpoint('issue');
export const task = () => getEndpoint('task');
export const issuesImages = () => getStorageEndpoint('issue/');
export const solutionImages = () => getEndpoint('solutionImages/');
export const archivedIssue = () => getEndpoint('archivedIssue');
export const locations = () => getEndpoint('location');
export const branches = () => getEndpoint('branch');
export const elements = () => getEndpoint('element');
export const elementImages = () => getStorageEndpoint('element');
export const users = () => firebase.firestore().collection('user');

export const usersCollecton = () => {
    const userData = getUserDataFromStorage();
    const organization = userData ? userData.organization : 'null';
    return firebase
        .firestore()
        .collection('user')
        .where('organization', '==', organization);
};

export const getEndpoint = collection => {
    const userData = getUserDataFromStorage();
    const organization = userData ? userData.organization : 'null';
    return `organization/${organization}/${collection}`;
};

export const getStorageEndpoint = collection => {
    const userData = getUserDataFromStorage();
    const organization = userData ? userData.organization : 'null';
    return `${organization}/${collection}`;
};
