export const mapSnapshotToEntities = snapshot =>
    snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));

export const wrapQueryExecution = queryExecution =>
    new Promise((resolve, reject) => {
        queryExecution
            .then(snapshot => {
                const mappedEntities = mapSnapshotToEntities(snapshot);
                resolve(mappedEntities);
            })
            .catch(reject);
    });
