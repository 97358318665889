import {Component} from 'react';
import {connect} from 'react-redux';
import {onUserAdded, setUsers} from '../../store/action';
import {compareUserWithQueryString} from '../../utils/filtering/filtering';
import UsersPageLayout from './UsersPageLayout';
import {mappings} from './UserPageRoleMapper';
import {withUserRole} from '../../hoc/User/WithUserRole';
import {
    getComparator,
    userSurnameComparator,
} from '../../utils/sorting/sorting';
import {ASC} from '../../constants/sortingDirections';
import getUsersQuery from '../../utils/queryBuilder/UsersQueryBuilder';
import {TYPES} from '../../constants/error';

class UsersPage extends Component {
    state = {
        searchString: '',
        indicateActivity: true,
        addDialogOpen: false,
    };

    componentDidMount() {
        this.fetchUsers();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            this.fetchUsers();
        }
    }

    fetchUsers() {
        this.setActivityIndicator(true);

        getUsersQuery()
            .withBranches(this.props.selectedBranches)
            .get()
            .then(users => {
                this.props.setUsers(users);
                this.setActivityIndicator(false);
            })
            .catch(error => {
                console.error(error);
                this.showNotification(
                    'Wystąpił błąd podczas pobierania użytkowników',
                    TYPES.error,
                );
            });
    }

    render() {
        return withUserRole(UsersPageLayout, mappings, {
            onSearchTextChange: this.onSearchTextChange,
            indicateActivity: this.state.indicateActivity,
            users: this.filterUsers(this.sortUsers(this.props.users)),
            addDialogOpen: this.state.addDialogOpen,
            onToggleAddDialogHandler: this.onToggleAddDialogHandler,
            onUserAdded: this.props.onUserAdded,
            history: this.props.history,
        });
    }

    onSearchTextChange = event => {
        this.setState({searchString: event.target.value});
    };

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    onToggleAddDialogHandler = () => {
        this.setState({addDialogOpen: !this.state.addDialogOpen});
    };

    filterUsers = users => {
        const queryString = this.state.searchString;
        if (queryString) {
            users = users.filter(user =>
                compareUserWithQueryString(user, queryString),
            );
        }
        return users;
    };

    sortUsers = users =>
        [...users].sort(getComparator(userSurnameComparator, ASC));
}

const mapStateToProps = state => ({
    users: state.user.users,
    selectedBranches: state.branch.selectedBranches,
});

const mapDispatchToProps = dispatch => ({
    setUsers: users => dispatch(setUsers(users)),
    onUserAdded: onUserAdded(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
