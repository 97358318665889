import React from 'react';
import {getUserRole} from '../../store/action/authHelpers';

export const withUserRole = (Component, mappings, props) => {
    const role = getUserRole();
    return (
        <Component
            innerComponents={mappings[role].innerComponents}
            roleProps={mappings[role].roleProps}
            props={props}
        />
    );
};
