import {SET_SELECTED_BRANCHES, SET_BRANCHES} from './actionTypes';
import {saveSelectedBranches} from '../../storage/localStorage';

export const fetchBranches =
    dispatch => (querySnapshot, setActivityIndicator) => {
        setActivityIndicator && setActivityIndicator(true);
        const locations = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
        }));
        setActivityIndicator && setActivityIndicator(false);
        dispatch(setBranches(locations));
    };

export const setUserSelectedBranches = selectedBranches => {
    return dispatch => {
        saveSelectedBranches(selectedBranches);
        dispatch(setSelectedBranches(selectedBranches));
    };
};

export const setSelectedBranches = selectedBranches => {
    return {
        selectedBranches,
        type: SET_SELECTED_BRANCHES,
    };
};
export const setBranches = branches => {
    return {
        branches,
        type: SET_BRANCHES,
    };
};
