import React from 'react';

import EditLocationDialog from '../../components/LocationDetails/LocationDetailsDialog';
import {ListActionPanel} from '../../common/components';
import AddLocationDialog from '../../components/Location/AddLocationDialog/AddLocationDialog';

const LocationsPageLayout = ({props}) => (
    <div>
        <ListActionPanel
            searchPlaceholder={'szukaj lokalizacji'}
            title={'Lokalizacje'}
            addButtonText="Dodaj lokalizacje"
            onSearchTextChange={props.onSearchTextChange}
            onToggleAddDialogHandler={props.onToggleAddDialogHandler}
            showAddButton
        />
        {props.addDialogOpen && (
            <AddLocationDialog
                dialogTitle="Dodaj lokalizację"
                handleClose={props.onToggleAddDialogHandler}
                onAddLocation={props.onLocationSubmit}
            />
        )}
        {props.editDialogOpen && (
            <EditLocationDialog
                dialogTitle="Edytuj lokalizację"
                location={props.locationSelectedForEdit}
                handleClose={props.onToggleEditDialogHandler}
                onUpdateLocationEvent={props.onUpdateLocationEventHandler}
                showNotification={props.showNotification}
            />
        )}
        {props.locationsList}
    </div>
);

export default LocationsPageLayout;
