import React from 'react';
import classnames from 'classnames';
import styles from './highPriority.module.css';

const highPriority = props => (
    <div
        className={classnames(styles.highPriority, {
            [styles.priorityDisplay]: !props.active,
        })}
    >
        <div className={styles.imageWrapper}>
            {props.active && (
                <img
                    alt=""
                    src={require('../../../../../../static/images/icons/alert-triangle.svg')}
                />
            )}
        </div>
        <span className={styles.mobileDisplay}>
            {props.active ? 'Tak' : '-'}
        </span>
    </div>
);

export default highPriority;
