import React from 'react';
import classnames from 'classnames';
import {CustomTextField} from '..';
import styles from './DatesInput.module.css';

const calendarIconSrc = require('../../../static/images/icons/calendar-3.svg');

const DateInput = props => {
    const {
        defaultValue,
        fullWidth,
        label,
        className,
        value,
        onChange,
        disabled,
    } = props;
    const icon = <img className={styles.icon} alt="" src={calendarIconSrc} />;

    return (
        <div className={className}>
            <CustomTextField
                type={disabled ? 'text' : 'date'}
                className={classnames({
                    'date-field-empty': !value && !defaultValue,
                })}
                label={label}
                defaultValue={defaultValue}
                onChange={onChange}
                fullWidth={fullWidth}
                icon={icon}
                disabled={disabled}
                value={value}
            />
        </div>
    );
};

export default DateInput;
