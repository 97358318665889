import React, {Component} from 'react';
import Picker from '../../../common/components/picker/Picker';
import {Option, MultiValue, ValueContainer} from './componentsForPicker';
import {
    mapUserToPicker,
    allUsersOption,
    generateOptions,
    isAllUsersOptionPresent,
    areAllUsersSelected,
    findSelectedUserInUsers,
} from './multiUserPickerUtils';

const MultiUserPicker = props => {
    const selectedUsers = props.selectedUsers.map(mapUserToPicker);

    if (
        areAllUsersSelected(props.users, props.selectedUsers) &&
        selectedUsers.length
    ) {
        selectedUsers.push(mapUserToPicker(allUsersOption));
    }

    const handleChange = selectedUsers => {
        const {selectedUsers: previousSelectedUsers, users: usersFromProps} =
            props;

        if (!selectedUsers) {
            selectedUsers = [];
        }
        if (areAllUsersSelected(usersFromProps, selectedUsers)) {
            if (
                !isAllUsersOptionPresent(selectedUsers) &&
                areAllUsersSelected(usersFromProps, previousSelectedUsers)
            ) {
                selectedUsers = [];
            } else {
                selectedUsers = selectedUsers.filter(
                    user => user.value !== allUsersOption.uid,
                );
            }
        }

        if (isAllUsersOptionPresent(selectedUsers)) {
            selectedUsers = usersFromProps.map(mapUserToPicker);
        }

        props.setSelectedUsers(
            selectedUsers.map(selectedUser =>
                findSelectedUserInUsers(usersFromProps, selectedUser),
            ),
        );
    };

    return (
        <Picker
            componentsForPicker={{Option, MultiValue, ValueContainer}}
            label="Dodaj osoby"
            selectedValue={selectedUsers}
            pickerItems={generateOptions(props.users)}
            name="Użytkownicy"
            handleChange={handleChange}
            placeholder={'Szukaj użytkowników'}
            clearable={false}
            closeMenuOnSelect={false}
            searchable={false}
            menuPlacement={'top'}
            multi
        />
    );
};

MultiUserPicker.defaultProps = {
    bottomLabelActive: true,
};

export default MultiUserPicker;
