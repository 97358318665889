import {SET_TASKS} from './actionTypes';

export const fetchTasks = dispatch => (querySnaphot, setActivityIndicator) => {
    setActivityIndicator && setActivityIndicator(true);
    const tasks = querySnaphot.docs.map(doc => ({
        ...doc.data(),
    }));
    setActivityIndicator && setActivityIndicator(false);
    dispatch(setTasks(tasks));
};

const setTasks = tasks => ({
    tasks,
    type: SET_TASKS,
});
