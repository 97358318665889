import React, {Component} from 'react';
import {connect} from 'react-redux';
import IssuesList from '../../components/Issues/IssuesList/IssuesList';
import {fetchIssues} from '../../store/action';
import PageControl from '../../components/Issues/IssuesPageControl/IssuesPageControl';
import AddIssueDialog from '../../components/Issues/AddIssueDialog/AddIssueDialog';
import {compareIssueWithQueryString} from '../../utils/filtering/filtering';
import {CustomCircularProgress} from '../../common/components/index';
import getIssuesQuery from '../../utils/queryBuilder/IssueQueryBuilder';

class HomePage extends Component {
    state = {
        addDialogOpen: false,
        openFilter: false,
        indicateActivity: true,
        searchString: '',
        filter: {
            open: true,
            inProgress: true,
            inReview: true,
            important: false,
            urgent: false,
        },
    };

    unsubscribeIssues;

    componentDidMount() {
        this.subscribeOnIssues();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
                prevProps.selectedBranches.length ||
            this.props.organizationData.reporterCanSeeAllIssues !==
                prevProps.organizationData.reporterCanSeeAllIssues
        ) {
            this.unsubscribeIssues && this.unsubscribeIssues();
            this.subscribeOnIssues();
        }
    }

    subscribeOnIssues = () => {
        const {userData, organizationData} = this.props;
        const branchIds = this.props.selectedBranches.map(branch => branch.id);
        this.unsubscribeIssues = getIssuesQuery(
            branchIds,
            organizationData,
            userData,
        ).onSnapshot(
            issues => this.props.fetchIssues(issues, this.setActivityIndicator),
            console.error,
        );
    };

    componentWillUnmount() {
        this.unsubscribeIssues();
    }

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    render() {
        const issueList = this.state.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <IssuesList
                issues={this.filterIssues(this.props.issues)}
                onIssueClick={this.onIssueClickHandler}
            />
        );
        return (
            <div>
                <PageControl
                    onSearchTextChange={this.onSearchTextChange}
                    onToggleAddDialogHandler={this.onToggleAddDialogHandler}
                    onFilterIconClickHandler={this.onFilterIconClickHandler}
                    openFilter={this.state.openFilter}
                    filterState={this.state.filter}
                    onCheckboxClick={this.onCheckboxClick}
                />
                {this.state.addDialogOpen && (
                    <AddIssueDialog
                        handleClose={this.onToggleAddDialogHandler}
                        loading={this.state.indicateActivity}
                    />
                )}
                {issueList}
            </div>
        );
    }

    filterIssues = issues => {
        const {searchString} = this.state;
        const filteredByStatus = issues.filter(
            issue => this.state.filter[issue.status],
        );
        const filteredIssuesByImportant = this.state.filter.important
            ? filteredByStatus.filter(issue => issue.highPriority)
            : filteredByStatus;

        const filteredIssues = this.state.filter.urgent
            ? filteredIssuesByImportant.filter(issue => issue.urgentPriority)
            : filteredIssuesByImportant;

        if (searchString) {
            return filteredIssues.filter(issue =>
                compareIssueWithQueryString(issue, searchString),
            );
        }
        return filteredIssues;
    };

    onSearchTextChange = event => {
        this.setState({searchString: event.target.value});
    };

    onIssueClickHandler = issueId => () => {
        this.props.history.push('/issue/' + issueId);
    };

    onToggleAddDialogHandler = () => {
        this.setState({addDialogOpen: !this.state.addDialogOpen});
    };

    onFilterIconClickHandler = () => {
        this.setState({openFilter: !this.state.openFilter});
    };

    onCheckboxClick = field => event => {
        const object = {...this.state.filter};
        object[field] = !object[field];
        this.setState({filter: object});
    };
}

const mapStateToProps = state => ({
    issues: state.issue.issues,
    selectedBranches: state.branch.selectedBranches,
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
});

const mapDispatchToProps = dispatch => ({
    fetchIssues: (snapshot, callback) =>
        dispatch(fetchIssues(snapshot, false, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
