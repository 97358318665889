import {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import {fetchBranches} from '../../store/action/index';
import * as endpoints from '../../constants/endpoints';
import BranchesPageLayout from './BranchesPageLayout';
import {NotificationContext} from '../../context/notifications';
import {
    addBranch,
    deleteBranch,
    deleteBranchFromUsers,
    updateBranch,
} from '../../utils/branch/branch';
import {normalizeStr} from '../../utils/StringUtils/StringUtils';
import {TYPES} from '../../constants/error';
import {setUserSelectedBranches} from '../../store/action/branch';
import {checkIfLocationsExistsByBranch} from '../../store/action/location';

class BranchesPage extends Component {
    state = {
        searchString: '',
        addDialogOpen: false,
        editDialogOpen: false,
        listLoading: true,
        actionPending: false,
        branchSelectedForEdit: null,
    };

    static contextType = NotificationContext;
    unsubscribeBranches;
    branchesReference;
    notificationSystem = null;

    constructor(props) {
        super(props);
        this.branchesReference = firebase
            .firestore()
            .collection(endpoints.branches());
    }

    componentDidMount() {
        this.unsubscribeBranches = this.branchesReference
            .orderBy('name')
            .onSnapshot(
                snapshot =>
                    this.props.fetchBranches(
                        snapshot,
                        this.setActivityIndicator,
                    ),
                error => console.error('Error during fetching branches', error),
            );
        this.notificationSystem = this.context;
    }

    componentWillUnmount() {
        this.unsubscribeBranches();
    }

    render() {
        return BranchesPageLayout({
            props: {
                addDialogOpen: this.state.addDialogOpen,
                editDialogOpen: this.state.editDialogOpen,
                branchSelectedForEdit: this.state.branchSelectedForEdit,
                onSearchTextChange: this.onSearchTextChange,
                branches: this.filterBranches(this.props.branches),
                onToggleAddDialogHandler: this.onToggleAddDialogHandler,
                onToggleEditDialogHandler: this.onToggleEditDialogHandler,
                listLoading: this.state.listLoading,
                actionPending: this.state.actionPending,
                onSubmitHandler: this.onSubmitHandler,
                onBranchClickHandler: this.onBranchClickHandler,
                onUpdateBranch: this.onUpdateBranch,
                onDeleteBranch: this.onDeleteBranch,
            },
        });
    }

    onSearchTextChange = event => {
        this.setState({searchString: event.target.value});
    };

    onToggleAddDialogHandler = () => {
        this.setState({addDialogOpen: !this.state.addDialogOpen});
    };

    onToggleEditDialogHandler = () => {
        this.setState({editDialogOpen: !this.state.editDialogOpen});
    };

    filterBranches = branches => {
        if (this.state.searchString) {
            branches = branches.filter(branch =>
                normalizeStr(branch.name).includes(
                    normalizeStr(this.state.searchString),
                ),
            );
        }
        return branches;
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    setActivityIndicator = loading => {
        this.setState({listLoading: loading});
    };

    onBranchClickHandler = elementId => () => {
        const branch = this.props.branches.find(
            branch => branch.id === elementId,
        );
        this.setState({
            branchSelectedForEdit: branch,
            editDialogOpen: true,
        });
    };

    branchExists = branch =>
        this.props.branches.some(
            br => br.name.toUpperCase() === branch.name.toUpperCase(),
        );

    onUpdateBranch = branchName => {
        if (this.branchExists(branchName)) {
            this.showNotification(
                'Oddział z taką nazwą już istnieje',
                TYPES.error,
            );
        } else {
            this.setState({actionPending: true});
            updateBranch(this.state.branchSelectedForEdit.id, branchName)
                .then(() => {
                    this.setState({actionPending: false});
                    this.onToggleEditDialogHandler();
                    this.showNotification('Zaktualizowano oddział', 'success');
                })
                .catch(error => {
                    console.error('Error during updating branch', error);
                    this.showNotification(
                        'Aktualizacja oddziału nie powiodła się',
                        TYPES.error,
                    );
                });
        }
    };

    onDeleteBranch = async branch => {
        this.setState({actionPending: true});
        const locationsExists = await this.props.checkIfLocationsExistsByBranch(
            branch,
        );
        if (locationsExists) {
            this.setState({actionPending: false});
            return this.showNotification(
                'Nie możesz usunąć tego oddziału, ponieważ znajdują się w nim zapisane lokalizacje',
                TYPES.error,
            );
        }
        deleteBranch(branch)
            .then(() => deleteBranchFromUsers(branch))
            .then(() => {
                this.showNotification(
                    'Usuwanie elementu powiodło się',
                    TYPES.success,
                );
            })
            .catch(error => {
                this.showNotification(
                    'Usuwanie elementu nie powiodło się',
                    TYPES.error,
                );
                console.error(error);
            })
            .then(() => {
                this.setState({actionPending: false});
            });
    };

    onSubmitHandler = branchName => {
        if (this.branchExists(branchName)) {
            this.showNotification(
                'Oddział z taką nazwą już istnieje',
                TYPES.error,
            );
        } else {
            this.setState({actionPending: true});
            addBranch(branchName)
                .then(doc => {
                    this.setState({actionPending: false});
                    this.onToggleAddDialogHandler();
                    this.showNotification('Dodano oddział', 'success');
                    const branch = {id: doc.id, name: branchName.name};
                    this.selectNewBranch(branch);
                })
                .catch(error => {
                    console.error('Error during adding branch', error);
                    this.showNotification(
                        'Dodawanie oddziału nie powiodło się',
                        TYPES.error,
                    );
                });
        }
    };

    selectNewBranch = branch => {
        const {selectedBranches} = this.props;
        const updatedBranches = [...selectedBranches, branch];
        this.props.setUserSelectedBranches(updatedBranches);
    };
}

const mapStateToProps = state => ({
    branches: state.branch.branches,
    selectedBranches: state.branch.selectedBranches,
    locations: state.location.locations,
});

const mapDispatchToProps = dispatch => ({
    fetchBranches: dispatch(fetchBranches),
    setUserSelectedBranches: branches =>
        dispatch(setUserSelectedBranches(branches)),
    checkIfLocationsExistsByBranch: checkIfLocationsExistsByBranch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchesPage);
