import React, {useContext} from 'react';
import {NotificationContext} from '../../context/notifications';
import NotificationItem from './NotificationItem/NotificationItem';

const Notifications = () => {
    const notificationContext = useContext(NotificationContext);
    const onRemoveHandle = id => notificationContext.removeNotification(id);
    const notifications = notificationContext.state.notifications.map(
        (notification, index) => (
            <NotificationItem
                key={index}
                description={notification.message}
                type={notification.type}
                onRemove={() => onRemoveHandle(index)}
            />
        ),
    );
    return <>{notifications}</>;
};
export default Notifications;
