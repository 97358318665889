import React, {Component} from 'react';
import {isEqual} from 'lodash';
import {
    List,
    MobileList,
    MobileDataDisplay,
    ListItem,
    ListItemMobile,
    Warranty,
    ListColumnCreator,
} from '../../../common/components/index';
import {normalizeStr} from '../../../utils/StringUtils/StringUtils';
import * as moment from 'moment';
import WarrantyFilter from './filters/warrantyFilter/WarrantyFilter';
import ElementNameFilter from './filters/elementNameFilter/ElementNameFilter';
import LocationFilter from './filters/locationFilter/LocationFilter';
import ElementName from '../../../common/components/list/listItem/display/elementName/elementName';

class ElementsList extends Component {
    state = {
        rows: [],
        filteredElementName: '',
        filteredLocationName: '',
        filteredWarranty: {checked: true, unchecked: true},
    };

    columns;
    columnIds;

    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.elements,
            filteredElementName: '',
            filteredLocationName: '',
            filteredWarranty: {checked: true, unchecked: true},
        };
        this.columns = [
            new ListColumnCreator('name', 'Nazwa elementu', {}),
            new ListColumnCreator('createdDate', 'Data dodania', {}),
            new ListColumnCreator('locationName', 'Lokalizacja', {
                accessAttribute: element => element.location.name,
            }),
            new ListColumnCreator('branchName', 'Oddział', {
                accessAttribute: element => element.location.branch.name,
            }),
            new ListColumnCreator('warranty', 'Termin gwarancji', {
                accessAttribute: element => element.warrantyTo,
            }),
        ];

        this.columnIds = this.columns.map(column => column.id);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.elements, prevProps.elements)) {
            this.setState({rows: this.props.elements});
        }
    }

    render() {
        const {rows} = this.state;

        return (
            <>
                <List
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getElementListItemTemplate}
                />
                <MobileList
                    columns={['elementName', 'image']}
                    rows={rows}
                    rowTemplate={this.getElementMobileListItemTemplate}
                />
            </>
        );
    }

    getElementListItemTemplate = element => {
        const warrantyComment = `Gwarancja do ${new Date(
            element.warrantyTo || '',
        ).toLocaleDateString()} \nDostarczone przez: ${element.producer}`;

        return (
            <ListItem
                key={element.id}
                onClick={this.props.onElementClick(element.id)}
                columns={this.columnIds}
            >
                <ElementName
                    elementName={element.name}
                    iconUri={element.iconUri}
                />
                {moment(element.createdDate).format('DD/MM/YYYY')}
                {element.location.name}
                {element.location.branch.name}
                <div>
                    <Warranty
                        warrantyComment={warrantyComment}
                        endDate={element.warrantyTo}
                    />
                </div>
            </ListItem>
        );
    };

    getElementMobileListItemTemplate = element => (
        <ListItemMobile
            key={element.id}
            onClick={this.props.onElementClick(element.id)}
        >
            <MobileDataDisplay
                image={element.iconUri}
                title={element.name}
                left={element.location.name}
            />
        </ListItemMobile>
    );

    getElementNameFilter = onCloseFilter => (
        <ElementNameFilter
            value={this.state.filteredElementName}
            onChange={this.handleElementNameFilterChange}
            onApplyFilter={this.onElementNameFilterApply(onCloseFilter)}
            onCancelFilter={this.onElementNameFilterCancel(onCloseFilter)}
        />
    );

    getLocationFilter = onCloseFilter => (
        <LocationFilter
            value={this.state.filteredLocationName}
            onChange={this.handleLocationNameFilterChange}
            onApplyFilter={this.onLocationFilterApply(onCloseFilter)}
            onCancelFilter={this.onLocationFilterCancel(onCloseFilter)}
        />
    );

    getWarrantyFilter = onCloseFilter => (
        <WarrantyFilter
            value={this.state.filteredWarranty}
            onChange={this.handleWarrantyFilterChange}
            onApplyFilter={this.onWarrantyFilterApply(onCloseFilter)}
            onCancelFilter={this.onWarrantyFilterCancel(onCloseFilter)}
        />
    );

    handleElementNameFilterChange = event => {
        this.setState({filteredElementName: event.target.value});
    };

    handleLocationNameFilterChange = event => {
        this.setState({filteredLocationName: event.target.value});
    };

    handleWarrantyFilterChange = filterValue => {
        this.setState({filteredWarranty: filterValue});
    };

    onElementNameFilterApply = onCloseFilter => () => {
        this.setState(
            prevState => {
                if (prevState.filteredElementName.trim()) {
                    const filteredRows = this.props.elements.filter(row =>
                        normalizeStr(row.elementName).includes(
                            normalizeStr(prevState.filteredElementName),
                        ),
                    );

                    return {
                        rows: filteredRows,
                        filteredLocationName: '',
                        filteredWarranty: {checked: true, unchecked: true},
                        // TODO add other disabled filters here as empty string
                    };
                }

                return null;
            },
            () => {
                onCloseFilter();
            },
        );
    };

    onLocationFilterApply = onCloseFilter => () => {
        this.setState(
            prevState => {
                if (prevState.filteredLocationName.trim()) {
                    const filteredRows = this.props.elements.filter(row =>
                        normalizeStr(row.locationName).includes(
                            normalizeStr(prevState.filteredLocationName),
                        ),
                    );

                    return {
                        rows: filteredRows,
                        filteredElementName: '',
                        filteredWarranty: {checked: true, unchecked: true},
                    };
                }

                return null;
            },
            () => {
                onCloseFilter();
            },
        );
    };

    onWarrantyFilterApply = onCloseFilter => () => {
        this.setState(
            prevState => {
                const filteredRows = this.props.elements.filter(row => {
                    const normalizedDate = new Date();
                    normalizedDate.setDate(normalizedDate.getDate() - 1);
                    return (
                        (prevState.filteredWarranty.checked &&
                            row.warrantyTo &&
                            row.warrantyTo > normalizedDate.getTime()) ||
                        (prevState.filteredWarranty.unchecked &&
                            (!row.warrantyTo ||
                                row.warrantyTo < normalizedDate.getTime()))
                    );
                });

                return {
                    rows: filteredRows,
                    filteredElementName: '',
                    filteredLocationName: '',
                };
            },
            () => {
                onCloseFilter();
            },
        );
    };

    onElementNameFilterCancel = onCloseFilter => () => {
        this.setState(
            {filteredElementName: '', rows: this.props.elements},
            () => {
                onCloseFilter();
            },
        );
    };

    onLocationFilterCancel = onCloseFilter => () => {
        this.setState(
            {filteredLocationName: '', rows: this.props.elements},
            () => {
                onCloseFilter();
            },
        );
    };

    onWarrantyFilterCancel = onCloseFilter => () => {
        this.setState(
            {
                filteredWarranty: {checked: true, unchecked: true},
                rows: this.props.elements,
            },
            () => {
                onCloseFilter();
            },
        );
    };
}

export default ElementsList;
