import React from 'react';
import CustomDatePicker from './CustomDatePicker';
import styles from './CustomDatePicker.module.css';

const timespanPicker = ({onDateChange, startDate, endDate}) => (
    <div className={styles.container}>
        <span className={styles.datePickers}>
            <CustomDatePicker
                label="Od"
                value={startDate}
                onChange={date => onDateChange('startDate', date)}
            />
        </span>
        <span className={styles.datePickers}>
            <CustomDatePicker
                label="Do"
                value={endDate}
                onChange={date => onDateChange('endDate', date)}
            />
        </span>
    </div>
);

export default timespanPicker;
