import React from 'react';
import {roles as ROLES} from '../../../constants/roles';
import {Checkbox, UserPicker} from '../../../common/components';

export const mappings = {};

mappings[ROLES.MAINTAINER] = {
    innerComponents: [
        props => <Checkbox {...props} />,
        props => <UserPicker {...props} />,
    ],
    roleProps: {
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.SUPERVISOR] = {
    innerComponents: [
        props => <Checkbox {...props} />,
        props => <UserPicker {...props} />,
    ],
    roleProps: {
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.ADMIN] = {
    innerComponents: [
        props => <Checkbox {...props} />,
        props => <UserPicker {...props} />,
    ],
    roleProps: {
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.REPORTER] = {
    innerComponents: [props => null, props => null],
    roleProps: {
        rolesToFetchUsersWith: [],
    },
};
