import React from 'react';
import Radio from '@material-ui/core/Radio';
import {FormControlLabel, RadioGroup} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import styles from './RadioButton.module.css';

const RadioButton = withStyles(theme => ({
    radio: {
        '&$checked': {
            color: 'var(--light-blue)',
        },
    },
    item: {
        margin: '0px',
    },
    checked: {},
}))(({classes, label, value}) => (
    <FormControlLabel
        className={styles.item}
        classes={{root: classes.item}}
        value={value}
        label={label}
        control={
            <Radio classes={{root: classes.radio, checked: classes.checked}} />
        }
    />
));

const RadioButtons = ({items, label, onChange, defaultValue}) => {
    const radioButtons = items.map(item => {
        return (
            <RadioButton
                key={item.value}
                label={item.name}
                value={item.value}
            />
        );
    });
    return (
        <RadioGroup
            onChange={onChange}
            className={styles.container}
            defaultValue={defaultValue}
        >
            <label>{label}</label>
            {radioButtons}
        </RadioGroup>
    );
};

export default RadioButtons;
