import React, {PureComponent} from 'react';
import TaskFormLayout from './TaskFormLayout';
import {NotificationContext} from '../../../context/notifications';
import {isSameDay} from '../../../utils/date/dateUtils';
import getUsersQuery from '../../../utils/queryBuilder/UsersQueryBuilder';
import {TYPES} from '../../../constants/error';
import {areArraysEqual} from '../../../utils/array/array';

class TaskForm extends PureComponent {
    state = {
        originalTask: null,
        name: '',
        selectedElements: [null],
        selectedLocations: [null],
        elementsForLocation: [],
        startDate: null,
        assignedTo: null,
        repeat: null,
        active: false,
        loading: true,
        submitButtonDisabled: true,
        branch: null,
        notificationCheckbox: false,
        notificationTime: 0,
        users: [],
        selectedUsers: [],
        repeatDays: 0,
        addToIssueList: false,
        addToIssueListTimePeriod: 0,
        priority: 'normal',
        defaultPriority: 'normal',
    };

    static contextType = NotificationContext;
    notificationSystem = null;

    constructor(props) {
        super(props);
        const {originalTask} = this.props;
        originalTask &&
            this.setMissingValuesFromPreviousAppVersions(originalTask);
        if (this.props.originalTask) {
            this.state.originalTask = originalTask;
            const selectedElements = [...originalTask.elements];
            this.state.selectedLocations = selectedElements.map(
                element => element.location,
            );
            this.state.selectedElements = selectedElements;
            this.state.name = originalTask.name;
            this.state.elementsForLocation = originalTask.elementsForLocation;
            this.state.startDate = originalTask.startDate;
            this.state.assignedTo = originalTask.assignedTo;
            this.state.repeat = originalTask.repeat;
            this.state.active = originalTask.active;
            this.state.branch = originalTask.branch;
            this.state.notificationCheckbox = !!originalTask.notifications;
            this.state.notificationTime =
                originalTask.notifications?.notificationTime || 0;
            this.state.addToIssueList = originalTask.addToIssueList;
            this.state.addToIssueListTimePeriod =
                originalTask.addToIssueListTimePeriod;
            this.state.defaultPriority = originalTask.priority;
            this.state.priority = originalTask.priority;
            this.state.repeatDays = originalTask.repeat;
            this.setAvailableUsers(originalTask.branch);
        }
        this.state.elementsForLocation = this.getElementsForLocations(
            this.state.selectedLocations,
        );
    }

    setMissingValuesFromPreviousAppVersions(originalTask) {
        originalTask.addToIssueList = originalTask.addToIssueList || false;
        originalTask.addToIssueListTimePeriod =
            originalTask.addToIssueListTimePeriod || 0;
        originalTask.priority = originalTask.priority || 'normal';
        if (!originalTask.repeat || typeof originalTask.repeat === 'string') {
            switch (originalTask.repeat) {
                case 'weekly':
                    originalTask.repeat = 7;
                    break;
                case 'monthly':
                    originalTask.repeat = 30;
                    break;
                default:
                    originalTask.repeat = 1;
                    break;
            }
            originalTask.repeat = originalTask.repeat || 0;
        }
    }

    setAvailableUsers(branch) {
        if (!this.state.users.length) {
            getUsersQuery()
                .withBranch(branch)
                .get()
                .then(users => {
                    this.setState({
                        users,
                        selectedUsers:
                            this.props?.originalTask?.notifications?.selectedUsers.map(
                                userId =>
                                    users.find(user => user.uid === userId),
                            ) || [],
                    });
                })
                .catch(console.error);
        }
    }

    getElementsForLocations(locations) {
        return locations.map(location =>
            location
                ? this.props.elements.filter(
                      element => element.location?.id === location.id,
                  )
                : this.getElementsForBranch(),
        );
    }

    getElementsForBranch() {
        return this.state.branch
            ? this.props.elements.filter(
                  element =>
                      element.location?.branch.id === this.state.branch.id,
              )
            : this.props.elements;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const submitButtonDisabled = this.isSubmitButtonDisabled();
        if (prevState.submitButtonDisabled !== submitButtonDisabled) {
            this.setState({submitButtonDisabled});
            this.props.setSubmitButtonDisabled(submitButtonDisabled);
        }
        if (
            prevProps.elements.length !== this.props.elements.length ||
            prevProps.locations.length !== this.props.locations.length
        ) {
            this.setElementsForLocations(this.state.selectedLocations);
        }

        this.props.setFormState({
            name: this.state.name,
            selectedElements: this.state.selectedElements,
            startDate: this.state.startDate,
            assignedTo: this.state.assignedTo,
            repeat: this.state.repeatDays,
            active: this.state.active,
            branch: this.state.branch,
            notifications: this.state.notificationCheckbox
                ? {
                      selectedUsers: this.state.selectedUsers.map(
                          user => user.uid,
                      ),
                      notificationTime: this.state.notificationTime || 0,
                  }
                : null,
            priority: this.state.priority,
            addToIssueList: this.state.addToIssueList,
            addToIssueListTimePeriod: this.state.addToIssueListTimePeriod,
        });
    }

    componentDidMount() {
        this.notificationSystem = this.context;
    }

    setElementsForLocations(locations) {
        const elementsForLocation = this.getElementsForLocations(locations);
        this.setState({
            elementsForLocation,
        });
    }

    render() {
        const {
            name,
            selectedElements,
            selectedLocations,
            elementsForLocation,
            startDate,
            assignedTo,
            active,
            users,
            selectedUsers,
            repeatDays,
        } = this.state;
        const {elements, locations, noTopMarginOnFirstInput} = this.props;

        const filteredLocations = this.state.branch
            ? this.filterByBranch()
            : locations;

        return (
            <TaskFormLayout
                elements={elements}
                name={name}
                locations={filteredLocations}
                selectedElements={selectedElements}
                selectedLocations={selectedLocations}
                elementsForLocation={elementsForLocation}
                startDate={startDate}
                assignedTo={assignedTo}
                active={active}
                users={users}
                selectedUsers={selectedUsers}
                repeatDays={repeatDays}
                noTopMarginOnFirstInput={noTopMarginOnFirstInput}
                onTaskNameChange={this.onTaskNameChange}
                onDaysNumberChange={this.onDaysNumberChange}
                onActiveStateChange={this.onActiveStateChange}
                onAssignedToChange={this.onAssignedToChange}
                onStartDateChange={this.onStartDateChange}
                onLocationChange={this.onLocationChange}
                onElementChange={this.onElementChange}
                onElementAdded={this.onElementAdded}
                checkboxToggle={this.checkboxToggle}
                notificationCheckbox={this.state.notificationCheckbox}
                notificationTime={this.state.notificationTime}
                onNotificationTimeChange={this.onNotificationTimeChange}
                setSelectedUsers={this.setSelectedUsers}
                addToIssueList={this.state.addToIssueList}
                addToIssueListTimePeriod={this.state.addToIssueListTimePeriod}
                onAddToIssueListTimePeriodChange={
                    this.onAddToIssueListTimePeriodChange
                }
                defaultPriority={this.state.defaultPriority}
                onPriorityChange={this.onPriorityChange}
            />
        );
    }

    filterByBranch = () =>
        this.props.locations.filter(
            location => location.branch.id === this.state.branch.id,
        );

    onActiveStateChange = event => {
        this.setState({active: event.target.checked});
    };

    onElementAdded = () => {
        const selectedLocations = [...this.state.selectedLocations];
        const selectedElements = [...this.state.selectedElements];
        selectedLocations.push(null);
        selectedElements.push(null);
        this.setElementsForLocations(selectedLocations);
        this.setState({selectedLocations, selectedElements});
    };

    onElementChange = (item, index) => {
        const selectedElements = [...this.state.selectedElements];
        const selectedLocations = [...this.state.selectedLocations];
        const element = this.props.elements.find(
            element => element.id === item.value,
        );

        if (this.isElementAlreadyChosen(element, index)) {
            this.showNotification(
                'Nie można dodać tego samego elementu',
                TYPES.error,
            );
            return;
        }

        selectedElements[index] = element;
        selectedLocations[index] = element.location;
        this.setAvailableUsers(element.location.branch);
        this.setElementsForLocations(selectedLocations);
        this.setState({
            selectedElements,
            selectedLocations,
            branch: element.location.branch,
        });
    };

    isElementAlreadyChosen = element =>
        this.state.selectedElements
            .filter(Boolean)
            .some(selectedElement => selectedElement.id === element.id);

    onLocationChange = (item, index) => {
        const location = this.props.locations.find(
            location => location.id === item.value,
        );
        const selectedLocations = [...this.state.selectedLocations];
        const selectedElements = [...this.state.selectedElements];
        selectedLocations[index] = location;
        selectedElements[index] = null;
        this.setAvailableUsers(location.branch);
        this.setElementsForLocations(selectedLocations);
        this.setSelectedUsers([]);
        this.setState({selectedLocations, selectedElements});
    };

    onStartDateChange = date => {
        this.setState({startDate: date});
    };

    onAssignedToChange = item => {
        const user = this.state.users.find(user => user.uid === item.value);
        this.setState({assignedTo: user});
    };

    onTaskNameChange = event => {
        const name = event.target.value;
        this.setState({name});
    };

    onDaysNumberChange = event => {
        if (Number.isInteger(+event.target.value)) {
            this.setState({repeatDays: +event.target.value});
        }
    };

    isSubmitButtonDisabled() {
        return (
            this.emptyElementPickerExist() ||
            this.requiredInputsEmpty() ||
            (this.isNotificationCheckboxChecked() &&
                !this.areUsersToNotifySelected()) ||
            this.inputsDidNotChange()
        );
    }

    emptyElementPickerExist() {
        if (this.state.selectedElements.length === 1) {
            return !this.state.selectedElements[0];
        }
        return this.state.selectedElements.some((element, index) => {
            if (!element) {
                return Boolean(this.state.selectedLocations[index]);
            }
            return false;
        });
    }

    isNotificationCheckboxChecked() {
        return this.state.notificationCheckbox;
    }

    areUsersToNotifySelected() {
        return this.state.selectedUsers.length > 0;
    }

    requiredInputsEmpty() {
        return !this.state.name || !this.state.startDate;
    }

    inputsDidNotChange() {
        const {originalTask} = this.state;

        if (!originalTask) {
            return false;
        }

        return (
            this.state.name === originalTask.name &&
            this.state.active === originalTask.active &&
            this.assignedEmployeeDidNotChange() &&
            this.state.repeat === originalTask.repeat &&
            isSameDay(this.state.startDate, originalTask.startDate) &&
            this.state.addToIssueList === originalTask.addToIssueList &&
            this.state.priority === originalTask.priority &&
            this.state.addToIssueListTimePeriod ===
                originalTask.addToIssueListTimePeriod &&
            this.state.repeatDays === originalTask.repeat &&
            !this.didNotificationsChanged() &&
            !this.selectedElementsChanged()
        );
    }

    didNotificationsChanged() {
        const {
            originalTask: {notifications},
        } = this.state;

        if (!!notifications !== this.state.notificationCheckbox) {
            return true;
        } else if (!notifications) {
            return false;
        }

        return (
            notifications.notificationTime !== this.state.notificationTime ||
            !areArraysEqual(
                notifications.selectedUsers,
                this.state.selectedUsers.map(user => user.uid),
            )
        );
    }

    assignedEmployeeDidNotChange() {
        const {originalTask} = this.state;
        return (
            this.state.assignedTo === originalTask.assignedTo ||
            (this.state.assignedTo &&
                originalTask.assignedTo &&
                this.state.assignedTo.uid === originalTask.assignedTo.uid)
        );
    }

    selectedElementsChanged() {
        const {originalTask, selectedElements} = this.state;
        return !selectedElements.filter(Boolean).every(selectedElement => {
            return originalTask.elements.find(
                originalElement => selectedElement.id === originalElement.id,
            );
        });
    }

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message,
                type,
            });
        }
    };

    checkboxToggle = event => {
        this.setState({[event.target.name]: event.target.checked});
    };

    onNotificationTimeChange = event => {
        const notificationTime = +event.target.value;

        if (Number.isInteger(notificationTime)) {
            this.setState({notificationTime});
        }
    };

    setSelectedUsers = users => {
        this.setState({selectedUsers: users});
    };

    onAddToIssueListTimePeriodChange = event => {
        const time = +event.currentTarget.value;

        if (Number.isInteger(time)) {
            this.setState({addToIssueListTimePeriod: time});
        }
    };

    onPriorityChange = event => {
        const priority = event.currentTarget.value;

        this.setState({priority});
    };
}

export default TaskForm;
