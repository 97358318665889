import React from 'react';
import {ListActionPanel} from '../../../common/components/index';

const TasksPageControl = ({onSearchTextChange, onToggleAddDialogHandler}) => (
    <ListActionPanel
        searchPlaceholder={'Szukaj zadania'}
        title={'Lista Zadań'}
        addButtonText="Dodaj zadanie"
        onSearchTextChange={onSearchTextChange}
        onToggleAddDialogHandler={onToggleAddDialogHandler}
        showAddButton
    />
);

export default TasksPageControl;
