import {compareWithQueryString} from '../StringUtils/StringUtils';

export const compareUserWithQueryString = (user, queryString) => {
    const role = Object.keys(user.roles)[0];
    return (
        compareWithQueryString(user.email, queryString) ||
        compareWithQueryString(`${user.name} ${user.surname}`, queryString) ||
        compareWithQueryString(role, queryString)
    );
};

export const compareIssueWithQueryString = (issue, queryString) =>
    compareWithQueryString(issue.issueDescription, queryString) ||
    compareWithQueryString(issue.element.name, queryString) ||
    compareWithQueryString(issue.element.location.name, queryString);

export const compareTaskWithQueryString = (task, queryString) =>
    compareWithQueryString(task.name, queryString);
