import axios from '../../config/axios/axiosConfig';
import {ARCHIVED_ISSUES_REPORT} from '../../constants/CloudFunctions';
import {generateArchivedIssuesReport} from '../../utils/PDFGenerator/PDFGenerator';
import {SET_ISSUES_FOR_REPORT} from './actionTypes';

export const fetchIssuesForReportAndGeneratePDF = (
    startDate,
    endDate,
    showErrorMessage,
    userId,
    locationId,
    reportType,
    elementId,
    onFinish,
) => {
    return async dispatch => {
        axios
            .get(ARCHIVED_ISSUES_REPORT, {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    user: userId ? userId : 'all',
                    location: locationId ? locationId : 'all',
                    element: elementId ? elementId : 'all',
                },
            })
            .then(result => {
                if (
                    !result.data ||
                    (Object.keys(result.data).length === 0 &&
                        result.data.constructor === Object)
                ) {
                    showErrorMessage('Brak wyników dla wybranego przedziału');
                } else {
                    generateArchivedIssuesReport(
                        startDate,
                        endDate,
                        reportType,
                        result.data,
                    );
                    dispatch(setIssuesForReport(result.data));
                }
                onFinish();
            })
            .catch(error => {
                showErrorMessage('Wystapił błąd podczas generowania raportu');
                onFinish();
                console.log(error);
            });
    };
};

export const setIssuesForReport = issues => {
    return {
        issuesForReport: issues,
        type: SET_ISSUES_FOR_REPORT,
    };
};
