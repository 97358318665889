import {SET_ISSUES_FOR_REPORT} from '../action/actionTypes';
import {reportsForStore} from '../../constants/reports';

const initialState = {
    issuesForReport: null,
    reportTypes: reportsForStore,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ISSUES_FOR_REPORT:
            return {
                ...state,
                issuesForReport: action.issuesForReport,
            };

        default:
            return state;
    }
};

export default reducer;
