import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import LoginForm from '../../components/LoginForm/LoginForm';
import {tryAuth, sendResetPasswordEmail} from '../../store/action';
import {ISSUES} from '../../constants/routes';
import styles from './LoginPage.module.css';
import ChangePasswordDialog from '../../components/ChangePasswordDialog/ChangePasswordDialog';
import CustomCircularProgress from '../../common/components/CustomCircularProgress/customCircularProgress';

class LoginPage extends Component {
    state = {
        errorMessage: '',
        login: '',
        password: '',
        dialogInput: '',
        loading: false,
        openChangePasswordDialog: false,
    };

    render() {
        let alert = <div />;
        if (this.state.errorMessage !== '') {
            alert = (
                <Alert incorrectCredentials>
                    <p>{this.state.errorMessage}</p>
                </Alert>
            );
        }
        const spinner = this.state.loading && <CustomCircularProgress />;

        return (
            <>
                {spinner}
                {this.state.openChangePasswordDialog && (
                    <ChangePasswordDialog
                        loading={this.state.loading}
                        onClose={this.toggleDialogOpen}
                        submitButtonDisabled={
                            this.state.loading || !this.state.dialogInput
                        }
                        onSubmit={this.onChangePassword}
                        onChange={this.onEmailInDialogChange}
                    />
                )}
                <div className={styles.loginForm}>
                    <LoginForm
                        disabled={this.state.loading}
                        onLoginTextChange={this.onLoginTextChangeHandler}
                        onPasswordTextChange={this.onPasswordChangeHandler}
                        onLoginButtonClicked={this.onLoginHandler}
                        onChangePasswordClick={this.toggleDialogOpen}
                        alert={alert}
                    />
                    <div className={styles.backgroundImage}>
                        <div className={styles.colorFilter}>
                            <img
                                style={{height: '60px'}}
                                alt=""
                                src={require('../../static/images/logo/logo_login.svg')}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    onLoginHandler = () => {
        this.setState({loading: true});
        const authData = {
            login: this.state.login,
            password: this.state.password,
        };
        this.props.onTryAuth(
            authData,
            this.onLoginFailedHandler,
            this.onLoginSuccess,
        );
    };

    onChangePassword = () => {
        this.props.sendResetPasswordEmail(
            this.state.dialogInput,
            this.onLoginFailedHandler,
        );
        this.toggleDialogOpen();
    };

    onLoginTextChangeHandler = e => {
        this.setState({login: e.target.value});
    };

    onPasswordChangeHandler = e => {
        this.setState({password: e.target.value});
    };

    onEmailInDialogChange = e => {
        this.setState({dialogInput: e.target.value});
    };

    onLoginFailedHandler = errorMessage => {
        this.setState({errorMessage, loading: false});
    };

    toggleDialogOpen = () => {
        const dialogState = !this.state.openChangePasswordDialog;
        this.setState({
            openChangePasswordDialog: dialogState,
        });
    };

    onLoginSuccess = () => {
        this.setState({loading: false});
        this.props.history.push(ISSUES);
    };
}

const mapDispatchToProps = dispatch => ({
    onTryAuth: (authData, onLoginFailed, onLoginSuccess) =>
        dispatch(tryAuth(authData, onLoginFailed, onLoginSuccess)),
    sendResetPasswordEmail: (email, onError) =>
        dispatch(sendResetPasswordEmail(email, onError)),
});

export default withRouter(connect(null, mapDispatchToProps)(LoginPage));
