import React from 'react';
import {ListActionPanel} from '../../../common/components/index';

const IssuesPageControl = props => {
    const {
        onSearchTextChange,
        onToggleAddDialogHandler,
        onFilterIconClickHandler,
        openFilter,
        filterState,
        onCheckboxClick,
    } = props;

    return (
        <ListActionPanel
            searchPlaceholder={'Szukaj zgłoszenia'}
            title={'Lista zgłoszeń'}
            addButtonText="Dodaj zgłoszenie"
            onSearchTextChange={onSearchTextChange}
            onToggleAddDialogHandler={onToggleAddDialogHandler}
            onFilterIconClickHandler={onFilterIconClickHandler}
            openFilter={openFilter}
            filterState={filterState}
            onCheckboxClick={onCheckboxClick}
            showAddButton
            withFilter
        />
    );
};

export default IssuesPageControl;
