import React from 'react';
import styles from './TaskDetails.module.css';
import {
    BackNavigation,
    Box,
    CustomButton,
} from '../../../src/common/components';
import {TASKS} from '../../constants/routes';
import {CustomCircularProgress} from '../../common/components';
import TaskForm from '../forms/task/TaskForm';
import sharedStyles from '../shared/mobileStyle.module.css';

const TaskDetailsLayout = ({props}) => (
    <div className={sharedStyles.mobileBackground}>
        {props.loading && <CustomCircularProgress />}
        <BackNavigation
            text="Wróc do listy zadań"
            header={props.header}
            backUrl={TASKS}
        />
        <div className={styles.root}>
            <Box className={styles.taskDetailsBox}>
                <section className={styles.buttons}>
                    <CustomButton
                        className={`${styles.btn} ${styles.deleteBtn}`}
                        text="Usuń zadanie"
                        contained={false}
                        onClick={props.onTaskDeleted}
                        disabled={props.loading}
                    />
                    <CustomButton
                        className={`${styles.btn} ${styles.saveBtn}`}
                        text="Zapisz zmiany"
                        disabled={props.loading || props.saveButtonDisabled}
                        onClick={props.onTaskUpdated}
                    />
                </section>
                <div className={styles.taskDetails}>
                    <TaskForm
                        originalTask={props.originalTask}
                        elements={props.elements}
                        locations={props.locations}
                        users={props.users}
                        setSubmitButtonDisabled={props.setSaveButtonDisabled}
                        setFormState={props.setFormState}
                    />
                </div>
            </Box>
        </div>
    </div>
);
export default TaskDetailsLayout;
