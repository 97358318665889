import firebase from 'firebase/app';
import {task} from '../../constants/endpoints';
import {taskConverter} from '../converter/taskConverter';

export const fetchAllTasks = callback => {
    return firebase
        .firestore()
        .collection(task())
        .withConverter(taskConverter)
        .onSnapshot(callback);
};

export const updateTask = (id, data) =>
    firebase.firestore().collection(task()).doc(id).update(data);

export const deleteTask = taskId =>
    firebase.firestore().collection(task()).doc(taskId).delete();

export const addNewTask = (newTask, onSuccess, onError) => {
    firebase
        .firestore()
        .collection(task())
        .add(newTask)
        .then(onSuccess)
        .catch(err => {
            console.error(err);
            onError();
        });
};
