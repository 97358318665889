import React from 'react';
import * as moment from 'moment';
import {
    List,
    ListItem,
    Warranty,
    ListColumnCreator,
} from '../../../common/components/index';
import HighPriority from './IssueListItem/display/highPriority/highPriority';
import Status from './IssueListItem/display/Status/Status';
import MobileList from '../../../common/components/list/MobileList';
import ListItemMobile from '../../../common/components/list/listItem/ListItemMobile/ListItemMobile';
import MobileHeader from '../../../common/components/list/listItem/display/mobileHeader/mobileHeader';
import MobileDataDisplay from '../../../common/components/list/listItem/display/mobileDataDisplay/moblieDataDisplay';
import {
    statusComparator,
    maintainerComparator,
    issuePriorityComparator,
    issueCreateDateComparator,
    issueUrgentPriorityComparator,
} from '../../../utils/sorting/sorting';
import {isOutdated} from '../../../utils/issue/issueUtils';
import classnames from 'classnames';
import ElementName from '../../../common/components/list/listItem/display/elementName/elementName';
import styles from './IssuesList.module.css';

const issuesList = props => {
    const columns = [
        new ListColumnCreator('elementName', 'Element', {
            accessAttribute: issue => issue.element.name,
        }),
        new ListColumnCreator('locationName', 'Lokalizacja', {
            accessAttribute: issue => issue.element.location.name,
        }),
        new ListColumnCreator('branchName', 'Oddział', {
            accessAttribute: issue => issue.element.location.branch.name,
        }),
        new ListColumnCreator('issueDescription', 'Opis', {}),
        new ListColumnCreator('createdDate', 'Data dodania', {}),
        new ListColumnCreator('assignedTo', 'Przypisany', {
            customComparator: maintainerComparator,
        }),
        new ListColumnCreator('warranty', 'Gwarancja', {
            accessAttribute: issue => issue.element.warrantyTo,
        }),
        new ListColumnCreator('highPriority', 'Krytyczne', {}),
        new ListColumnCreator('status', 'Status', {
            customComparator: statusComparator,
        }),
    ];

    let classes = ['elementName', 'image'];

    const sortIssues = issues => {
        return [...issues].sort(
            (a, b) =>
                issueUrgentPriorityComparator(a, b) ||
                issuePriorityComparator(a, b) ||
                issueCreateDateComparator(a, b),
        );
    };

    const columnIds = columns.map(column => column.id);
    const getIssueListItemTemplate = issue => {
        const outdated = isOutdated(
            issue.highPriority,
            issue.history,
            issue.status,
        );
        return (
            <ListItem
                key={issue.id}
                onClick={props.onIssueClick(issue.id)}
                className={classnames({
                    [styles.overdue]: outdated,
                    [styles.critical]: issue.urgentPriority,
                })}
                columns={columnIds}
            >
                <ElementName
                    elementName={issue.element.name}
                    iconUri={issue.iconUri}
                />
                {issue.element.location.name}
                {issue.element.location.branch.name}
                {issue.issueDescription}
                {moment(issue.createdDate).format('HH:mm DD/MM/YYYY')}
                {issue.assignedTo &&
                    `${issue.assignedTo.name} ${issue.assignedTo.surname}`}
                <Warranty endDate={issue.element.warrantyTo} />
                <HighPriority active={issue.highPriority} />
                <Status status={issue.status} />
            </ListItem>
        );
    };

    const getMobileIssueListItemTemplate = issue => {
        const outdated = isOutdated(
            issue.highPriority,
            issue.history,
            issue.status,
        );
        return (
            <ListItemMobile
                key={issue.id}
                onClick={props.onIssueClick(issue.id)}
                className={classnames({
                    [styles.overdue]: outdated,
                    [styles.critical]: issue.urgentPriority,
                })}
            >
                <MobileHeader classes={['status', 'priority', 'date']}>
                    <Status status={issue.status} />
                    <HighPriority active={issue.highPriority} />
                    {moment(issue.createdDate).format('DD/MM/YYYY')}
                </MobileHeader>
                <MobileDataDisplay
                    image={issue.iconUri}
                    left={issue.element.name}
                    right={issue.element.location.name}
                    title={
                        issue.issueDescription
                            ? issue.issueDescription
                            : 'Brak nazwy zgłoszenia'
                    }
                />
            </ListItemMobile>
        );
    };

    const {issues} = props;
    const sortedIssues = sortIssues(issues);

    return (
        <>
            <List
                columns={columns}
                rows={sortedIssues}
                rowTemplate={getIssueListItemTemplate}
            />
            <MobileList
                columns={classes}
                rows={sortedIssues}
                rowTemplate={getMobileIssueListItemTemplate}
            />
        </>
    );
};

export default issuesList;
