import {SET_SELECTED_BRANCHES, SET_BRANCHES} from '../action/actionTypes';

const initialState = {
    selectedBranches: [],
    branches: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_BRANCHES:
            return {
                ...state,
                selectedBranches: action.selectedBranches,
            };
        case SET_BRANCHES:
            return {
                ...state,
                branches: action.branches,
            };
        default:
            return state;
    }
};

export default reducer;
