import React from 'react';
import styles from './ListItemMobile.module.css';
import classnames from 'classnames';
import TrashIcon from '../../../../../static/images/icons/trash.svg';

const ListItemMobile = props => (
    <div className={classnames(styles.card, props.className)}>
        {React.Children.map(props.children, (child, index) => (
            <div onClick={props.onClick}>{child}</div>
        ))}
        {props.deletable && (
            <div onClick={props.onDelete} className={styles.bin}>
                <img
                    src={TrashIcon}
                    className={styles.bin}
                    alt="Usuń element"
                />
            </div>
        )}
    </div>
);

export default ListItemMobile;
