import React, {useState, useContext} from 'react';
import styles from './PasswordManagerModal.module.css';
import {CustomTextField} from '../../common/components';
import Dialog from '../../common/components/Dialog/Dialog';
import {updateUserPassword} from '../../store/action';
import {connect} from 'react-redux';
import {NotificationContext} from '../../context/notifications';

const PasswordManagerModal = ({open, onClose, userId, updateUserPassword}) => {
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const notificationSystem = useContext(NotificationContext);

    const changePassword = async () => {
        if (checkPasswordValid()) {
            setLoading(true);
            await updateUserPassword(userId, newPassword, onSuccess, onError);
        }
    };

    const checkPasswordValid = () => {
        let valid = true;
        if (newPassword.length < 6) {
            setError('Hasło musi mieć co najmniej 6 znaków');
            valid = false;
        }
        return valid;
    };

    const onSuccess = () => {
        setLoading(false);
        showNotification('Hasło zostało zaktualizowane', 'success');
        onClose();
    };

    const onError = error => {
        setLoading(false);
        setError(error);
    };

    const newPasswordChange = event => {
        setError('');
        setNewPassword(event.target.value);
    };

    const showNotification = (message, type) => {
        if (notificationSystem) {
            notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    return open ? (
        <Dialog
            loading={loading}
            handleClose={onClose}
            dialogTitle={'Zmień hasło użytkownika'}
            submitButtonDisabled={!newPassword}
            onSubmitHandler={changePassword}
            submitButtonText={'Zatwierdź hasło'}
        >
            <CustomTextField
                className={styles.textField}
                label="Wpisz nowe hasło użytkownika"
                placeholder="Wpisz nowe hasło użytkownika"
                defaultValue={newPassword}
                onChange={newPasswordChange}
                type="password"
            />
            <p className={styles.errorText}>{error}</p>
        </Dialog>
    ) : null;
};

const mapDispatchToProps = dispatch => ({
    updateUserPassword: updateUserPassword(dispatch),
});

export default connect(null, mapDispatchToProps)(PasswordManagerModal);
