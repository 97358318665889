import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import styles from './fileDropzone.module.css';
import {ImageDisplay} from '../';
import {PhotoGallery} from '../';
const dropzoneIconSrc = require('../../../static/images/icons/Group.svg');

const ImageDropzone = ({label, onDrop, imagesSrc, onRemoveImage, disabled}) => {
    const [galleryObj, setGalleryObj] = useState({open: false, index: 0});
    const onDropHandler = (accepted, rejected) => {
        if (!rejected.length) {
            onDrop(accepted);
        }
    };
    const images = imagesSrc.map((imageSrc, index) => (
        <ImageDisplay
            key={index}
            src={imageSrc}
            onDelete={() => onRemoveImage(index)}
            onImageClick={() => setGalleryObj({open: true, index: index})}
            deletable={!disabled}
        />
    ));
    return (
        <div className={styles.fileDropzone}>
            <header>{label}</header>
            {!disabled && (
                <Dropzone onDrop={onDropHandler} accept="image/jpeg, image/png">
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className={styles.content}>
                            <input {...getInputProps()} />
                            <img alt="" src={dropzoneIconSrc} />
                        </div>
                    )}
                </Dropzone>
            )}
            <div className={styles.images}>{images}</div>
            {galleryObj.open && (
                <PhotoGallery
                    onDialogClick={() =>
                        setGalleryObj({
                            open: false,
                            index: galleryObj.index,
                        })
                    }
                    photos={imagesSrc}
                    index={galleryObj.index}
                />
            )}
        </div>
    );
};
export default ImageDropzone;
