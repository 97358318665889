import React, {Component} from 'react';
import Dialog from '../../../common/components/Dialog/Dialog';
import {
    CustomTextField,
    LocationsPicker,
    CustomDatePicker,
    ImageDropzone,
    FilesDropzone,
} from '../../../common/components/index';
import {normalizeStr} from '../../../utils/StringUtils/StringUtils';
import {NotificationContext} from '../../../context/notifications';
import {MAX_PHOTO_SIZE, MAX_FILES} from '../../../constants/files';
import {TYPES} from '../../../constants/error';

class AddElementDialog extends Component {
    state = {
        newElementLocation: null,
        newElementName: '',
        newElementWarranty: '',
        newElementDescription: '',
        createdLocationId: '',
        iconFile: null,
        filesDropped: [],
    };

    newElementProducer;
    static contextType = NotificationContext;
    notificationSystem = this.context;

    static getDerivedStateFromProps(props, state) {
        const {createdLocationId} = state;
        const createdLocation = props.locations.find(
            location => location.id === createdLocationId,
        );
        if (createdLocation) {
            return {newElementLocation: createdLocation};
        }
        return null;
    }

    render() {
        const {dialogTitle, locations, open, loading} = this.props;
        const {newElementLocation, iconFile, filesDropped} = this.state;
        let iconUri = iconFile ? URL.createObjectURL(iconFile) : '';
        iconUri = iconUri ? [iconUri] : [];
        return (
            open && (
                <Dialog
                    loading={loading}
                    handleClose={this.onClose}
                    dialogTitle={dialogTitle}
                    submitButtonDisabled={this.submitButtonDisabled()}
                    onSubmitHandler={this.onSubmitNewElementHandler}
                    submitButtonText={dialogTitle}
                >
                    <LocationsPicker
                        defaultValue={'Szukaj lokalizacji'}
                        selectedLocation={newElementLocation}
                        handleChange={this.onNewElementLocationChange}
                        locations={locations}
                        first
                    />
                    <CustomTextField
                        label="Nazwa elementu"
                        onChange={this.onTextChangeHandler('newElementName')}
                        fullWidth={true}
                    />
                    <CustomDatePicker
                        label="Data dodania"
                        value={new Date()}
                        readOnly
                    />
                    <CustomDatePicker
                        label="Gwarancja do"
                        value={this.state.newElementWarranty}
                        onChange={this.onNewElementWarrantyChange}
                    />
                    {this.getTextArea(
                        'Opis elementu',
                        '',
                        this.onTextChangeHandler('newElementDescription'),
                    )}
                    <ImageDropzone
                        label="Zdjęcie elementu"
                        imagesSrc={iconUri}
                        onDrop={this.onIconSelectHandler}
                        onRemoveImage={this.onIconRemoveHandler}
                    />
                    <FilesDropzone
                        label="Pliki dodatkowe"
                        filesSrc={filesDropped.map(file => ({
                            path: URL.createObjectURL(file),
                            name: file.name,
                        }))}
                        onDrop={this.onFileDrop}
                        onRemoveFile={this.onRemoveFile}
                    />
                </Dialog>
            )
        );
    }

    submitButtonDisabled() {
        const {newElementName, newElementLocation} = this.state;
        return this.props.loading || !(newElementName && newElementLocation);
    }

    getTextArea(topLabel, placeholder, onChange) {
        return (
            <CustomTextField
                label={topLabel}
                placeholder={placeholder}
                onChange={onChange}
                multiline
                fullWidth
            />
        );
    }

    onTextChangeHandler = field => event => {
        this.setState({[field]: event.target.value});
    };

    onIconSelectHandler = ([file]) => {
        this.setState({iconFile: file});
    };

    onRemoveFile = index => {
        const filesDropped = [...this.state.filesDropped];
        filesDropped.splice(index, 1);
        this.setState({filesDropped});
    };

    onFileDrop = files => {
        const {filesDropped} = this.state;
        const numberOfunacceptedFiles = files.filter(
            file => file.size > MAX_PHOTO_SIZE,
        ).length;
        const fullImageCount = filesDropped.length + files.length;
        if (fullImageCount > MAX_FILES) {
            this.showNotification(
                'Nie można dodać więcej niż 3 pliki',
                TYPES.error,
            );
        } else if (numberOfunacceptedFiles > 0) {
            this.showNotification(
                'Maksymalny rozmiar pliku to 5MB',
                TYPES.error,
            );
        } else {
            const filesForState = [...this.state.filesDropped, ...files];
            this.setState({filesDropped: filesForState});
        }
    };

    onIconRemoveHandler = () => {
        this.setState({iconFile: null});
    };

    onNewElementWarrantyChange = date => {
        this.setState({newElementWarranty: date});
    };

    onNewElementLocationChange = change => {
        const location = {id: change.value, name: change.label};
        this.setState({newElementLocation: location});
    };

    onClose = () => {
        this.newElementProducer = '';
        this.setState(
            {
                newElementLocation: null,
                newElementName: '',
                newElementDescription: '',
                iconFile: null,
                filesDropped: [],
                newElementWarranty: '',
            },
            () => {
                this.props.handleClose();
            },
        );
    };

    onSubmitNewElementHandler = () => {
        const location = this.props.locations.find(
            location => location.id === this.state.newElementLocation.id,
        );
        const elementToSet = {
            description: this.state.newElementDescription || '',
            name: this.state.newElementName || '',
            producer: this.newElementProducer || '',
            warrantyTo: this.state.newElementWarranty || null,
            location,
            qrCode: '',
            createdDate: new Date(),
        };

        elementToSet.qrCode = normalizeStr(
            elementToSet.location.name + ':' + elementToSet.name,
        );
        this.props.onElementSubmit(
            elementToSet,
            this.state.iconFile,
            this.state.filesDropped,
        );
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };
}

export default AddElementDialog;
