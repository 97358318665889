import {Component} from 'react';
import {connect} from 'react-redux';
import {generateAndDownloadQR} from '../../utils/QRCodeService/qrCode';
import {deleteElement, fetchLocations, updateElement} from '../../store/action';

import {withRouter} from 'react-router-dom';
import {ELEMENTS} from '../../constants/routes';
import {withUserRole} from '../../hoc/User/WithUserRole';
import ElementDetailsBoxLayout from './ElementDetailsBoxLayout';
import {mappings} from './ElementDetailsBoxRoleMapper';
import {NotificationContext} from '../../context/notifications';
import getLocationsQuery from '../../utils/queryBuilder/LocationQueryBuilder';
import {TYPES} from '../../constants/error';
import {MAX_FILES, MAX_PHOTO_SIZE} from '../../constants/files';

class ElementDetailsBox extends Component {
    state = {
        name: '',
        description: '',
        warrantyTo: null,
        location: null,
        newIconFile: null,
        removeOldIcon: false,
        loading: false,
        filesDropped: {
            new: [],
            uploaded: [],
            removed: [],
        },
    };
    static contextType = NotificationContext;
    unsubscribeLocations;
    notificationSystem;

    constructor(props) {
        super(props);
        this.state.name = props.element.name;
        this.state.description = props.element.description;
        this.state.warrantyTo = props.element.warrantyTo;
        this.state.location = props.element.location;
        this.state.filesDropped.uploaded = props.element.files || [];
    }

    componentDidMount() {
        const branchIds = this.props.selectedBranches.map(branch => branch.id);
        this.unsubscribeLocations = getLocationsQuery(branchIds).onSnapshot(
            this.props.fetchLocations,
        );
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectedBranches.length !==
                this.props.selectedBranches.length &&
            this.state.location &&
            !this.props.selectedBranches.some(
                branch => branch.id === this.state.location.branch.id,
            )
        ) {
            this.props.history.push(ELEMENTS);
        }
    }

    componentWillUnmount() {
        this.unsubscribeLocations();
    }

    render() {
        const {locations} = this.props;
        const {createdDate, key, photoUri} = this.props.element;
        const {
            name,
            description,
            warrantyTo,
            location,
            loading,
            newIconFile,
            removeOldIcon,
            filesDropped,
        } = this.state;
        let iconUri = newIconFile
            ? URL.createObjectURL(newIconFile)
            : !removeOldIcon && photoUri;
        iconUri = iconUri ? [iconUri] : [];

        return withUserRole(ElementDetailsBoxLayout, mappings, {
            iconUri,
            key,
            name,
            description,
            warrantyTo,
            location,
            loading,
            locations,
            createdDate,
            filesDropped,
            isSaveButtonEnabled: this.isSaveButtonEnabled,
            onTextChangeHandler: this.onTextChangeHandler,
            isFormDirty: this.isFormDirty,
            onElementWarrantyChange: this.onElementWarrantyChange,
            onLocationChange: this.onLocationChange,
            onIconSelectHandler: this.onIconSelectHandler,
            onIconRemoveHandler: this.onIconRemoveHandler,
            showNotification: this.showNotification,
            deleteElement: this.deleteElement,
            updateElement: this.updateElement,
            onFileDrop: this.onFileDrop,
            onFileRemove: this.onFileRemoveHandler,
            onGenerateQRCode: this.onGenerateQRCode,
        });
    }

    isSaveButtonEnabled = () => {
        const {location, name, loading} = this.state;
        return location && name && !loading && this.isFormDirty();
    };

    isFormDirty = () => {
        const {element} = this.props;
        const {
            name,
            description,
            warrantyTo,
            location,
            newIconFile,
            removeOldIcon,
            filesDropped,
        } = this.state;

        const warrantyDidntChange =
            warrantyTo && element.warrantyTo
                ? warrantyTo.getTime() === element.warrantyTo.getTime()
                : !(warrantyTo || element.warrantyTo);

        return !(
            !filesDropped.removed.length &&
            !filesDropped.new.length &&
            name === element.name &&
            description === element.description &&
            warrantyDidntChange &&
            location.id === element.location.id &&
            !newIconFile &&
            !removeOldIcon
        );
    };

    onGenerateQRCode = () => {
        const content = this.props.element.qrCode;
        const fileName = this.props.element.name;
        generateAndDownloadQR(content, fileName)
            .then()
            .catch(error => {
                console.error(error);
                this.showNotification(
                    'Pobieranie pliku nie powiodło się',
                    TYPES.error,
                );
            });
    };

    onTextChangeHandler = field => event => {
        this.setState({[field]: event.target.value});
    };

    onElementWarrantyChange = warrantyTo => {
        this.setState({warrantyTo});
    };

    onLocationChange = ({value, label}) => {
        this.setState({location: {id: value, name: label}});
    };

    onIconSelectHandler = ([file]) => {
        const {photoUri} = this.props.element;

        this.setState({newIconFile: file});
        if (photoUri) {
            this.setState({removeOldIcon: true});
        }
    };

    onFileDrop = files => {
        const filesDropped = {...this.state.filesDropped};
        const numberOfunacceptedFiles = files.filter(
            file => file.size > MAX_PHOTO_SIZE,
        ).length;
        const actualFilesCount =
            filesDropped.new.length + filesDropped.uploaded.length;
        const newFilesCount = actualFilesCount + files.length;
        if (newFilesCount > MAX_FILES) {
            this.showNotification(
                'Nie można dodać więcej niż 3 pliki',
                TYPES.error,
            );
        } else if (numberOfunacceptedFiles > 0) {
            this.showNotification(
                'Maksymalny rozmiar pliku to 5MB',
                TYPES.error,
            );
        } else {
            filesDropped.new = [...filesDropped.new, ...files];
            this.setState({filesDropped});
        }
    };

    onIconRemoveHandler = () => {
        const {newIconFile} = this.state;
        if (newIconFile) {
            this.setState({newIconFile: null});
        } else {
            this.setState({removeOldIcon: true});
        }
    };

    onFileRemoveHandler = index => {
        const files = this.state.filesDropped;
        const newFiles = [...files.new];
        const removedFiles = [...files.removed];
        const uploadedFiles = [...files.uploaded];
        const uploadedFileIndex = index - newFiles.length;
        if (uploadedFileIndex >= 0) {
            const removedFile = uploadedFiles.splice(uploadedFileIndex, 1)[0];
            removedFiles.push(removedFile);
        } else {
            newFiles.splice(uploadedFileIndex, 1);
        }
        const newFilesField = {
            new: newFiles,
            removed: removedFiles,
            uploaded: uploadedFiles,
        };
        this.setState({
            filesDropped: newFilesField,
        });
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    deleteElement = () => {
        this.setState({loading: true});
        this.props
            .deleteElement(this.props.element)
            .then(() => {
                this.setState({loading: false});
                this.showNotification('Element usunięty', 'success');
                this.props.history.push(ELEMENTS);
            })
            .catch(error => {
                console.error('Error on an element delete', error);
                this.setState({loading: false});
                this.showNotification('Usuwanie nie powiodło się', TYPES.error);
            });
    };

    updateElement = () => {
        this.setState({loading: true});
        const {element} = this.props;
        const {
            description,
            name,
            warrantyTo,
            newIconFile,
            removeOldIcon,
            filesDropped,
        } = this.state;

        let location = this.props.locations.find(
            loc => loc.id === this.state.location.id,
        );

        const elementToUpdate = {
            description,
            name,
            warrantyTo,
            location,
            key: element.key,
            patternImagePath: element.patternImagePath,
            iconImagePath: element.iconImagePath,
        };

        this.props
            .updateElement(
                elementToUpdate,
                newIconFile,
                removeOldIcon,
                filesDropped,
            )
            .then(() => {
                this.setState({loading: false});
                this.showNotification('Zaktualizowano element', 'success');
                this.props.history.push(ELEMENTS);
            })
            .catch(error => {
                console.error('Error on element update', error);
                this.setState({loading: false});
                this.showNotification(
                    'Edycja elementu nie powiodła się',
                    TYPES.error,
                );
            });
    };
}

const mapStateToProps = state => {
    return {
        locations: state.location.locations,
        selectedBranches: state.branch.selectedBranches,
    };
};

const mapDispatchToProps = {
    fetchLocations,
    updateElement,
    deleteElement,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ElementDetailsBox),
);
