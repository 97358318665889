import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import styles from './Checkbox.module.css';

const checkbox = ({
    checked,
    checkedClassName,
    label,
    onChange,
    value,
    color,
    disabled,
    showIcon,
    className,
    name,
}) => {
    const checkedClass = checkedClassName || styles.checked;
    return (
        <div className={`${styles.checkbox} ${className}`}>
            <FormControlLabel
                classes={{label: styles.label}}
                control={
                    showIcon && checked ? (
                        <img
                            className={styles.alertTriangle}
                            alt=""
                            src={require('../../../static/images/icons/alert-triangle.svg')}
                        />
                    ) : (
                        <MaterialCheckbox
                            style={{
                                color: color,
                            }}
                            classes={{
                                checked: checkedClass,
                                root: styles.root,
                            }}
                            checked={checked}
                            onChange={onChange}
                            value={value}
                            disabled={disabled}
                        />
                    )
                }
                label={label}
                name={name}
            />
        </div>
    );
};

checkbox.defaultProps = {
    color: 'var(--light-blue)',
};

export default checkbox;
