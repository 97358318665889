import React, {Component} from 'react';
import {connect} from 'react-redux';
import ArchivedIssuesList from '../../components/Issues/ArchivedIssueList/ArchivedIssueList';
import {fetchIssues} from '../../store/action';
import {
    ListTitle,
    SearchField,
    CustomCircularProgress,
} from '../../common/components';
import {compareIssueWithQueryString} from '../../utils/filtering/filtering';
import {
    getComparator,
    issueClosedDateComparator,
} from '../../utils/sorting/sorting';
import {DESC} from '../../constants/sortingDirections';
import getArchivedIssuesQuery from '../../utils/queryBuilder/ArchivedIssueQueryBuilder';

class ArchivedIssuePage extends Component {
    state = {
        searchString: '',
        indicateActivity: true,
    };

    unsubscribeArchivedIssues;

    componentDidMount() {
        this.subscribeOnArchivedIssues();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            this.unsubscribeArchivedIssues && this.unsubscribeArchivedIssues();
            this.subscribeOnArchivedIssues();
        }
    }

    subscribeOnArchivedIssues = () => {
        const branchIds = this.props.selectedBranches.map(branch => branch.id);
        this.unsubscribeArchivedIssues = getArchivedIssuesQuery(
            branchIds,
        ).onSnapshot(
            issues =>
                this.props.fetchArchivedIssues(
                    issues,
                    this.setActivityIndicator,
                ),
            console.error,
        );
    };

    componentWillUnmount() {
        this.unsubscribeArchivedIssues();
    }

    onSearchTextChangeHandler = event => {
        this.setState({searchString: event.target.value});
    };

    filterIssues = issues =>
        this.state.searchString
            ? issues.filter(issue =>
                  compareIssueWithQueryString(issue, this.state.searchString),
              )
            : issues;

    sortIssues = issues =>
        [...issues].sort(getComparator(issueClosedDateComparator, DESC));

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    render() {
        const issues = this.filterIssues(this.props.archivedIssues);
        const sortedIssues = this.sortIssues(issues);

        const archivedIssueList = this.state.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <ArchivedIssuesList
                issues={sortedIssues}
                onIssueClick={this.onIssueClickHandler}
            />
        );
        return (
            <>
                <ListTitle title="Archiwum" />
                <SearchField
                    placeholder="Szukaj zarchiwizowanego zgłoszenia"
                    onSearchTextChange={this.onSearchTextChangeHandler}
                />
                {archivedIssueList}
            </>
        );
    }

    onIssueClickHandler = issueId => () => {
        this.props.history.push('/issue/archived/' + issueId);
    };
}

const mapStateToProps = state => ({
    archivedIssues: state.issue.archivedIssues,
    selectedBranches: state.branch.selectedBranches,
});

const mapDispatchToProps = dispatch => ({
    fetchArchivedIssues: (snapshot, callback) =>
        dispatch(fetchIssues(snapshot, true, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedIssuePage);
