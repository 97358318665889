import React from 'react';
import './Alert.css';

const alert = props => (
    <div
        className={`alert ${
            (props.incorrectCredentials &&
                'passwordManagerModal--incorrect-credentials') ||
            ''
        }`}
    >
        {props.children}
    </div>
);

export default alert;
