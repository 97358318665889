import firebase from 'firebase/app';
import {getDownloadUrl} from '../../utils/storageUtils/storageUtils';

export const downloadIconsAndSetItems = (
    items,
    accessor,
    setItems,
    onDownloadDone,
) => {
    return dispach => {
        Promise.all(
            items.map(item => {
                return new Promise((resolve, reject) => {
                    const imagePath = accessor(item);
                    if (!imagePath) {
                        resolve(item);
                    }
                    const storageRef = firebase
                        .storage()
                        .ref()
                        .child(imagePath);

                    storageRef
                        .getDownloadURL()
                        .then(result => {
                            resolve({...item, iconUri: result});
                        })
                        .catch(err => {
                            console.warn(err);
                            resolve(item);
                        });
                }).catch(err => {
                    console.error(err);
                    console.error('item: ', item);
                });
            }),
        )
            .then(result => {
                dispach(setItems(result));
                onDownloadDone();
            })
            .catch(err => {
                console.error(err);
                onDownloadDone();
            });
    };
};

export const downloadImagesAndSetElements =
    (items, setItems, onDownloadDone) => dispatch => {
        const promises = items.map(item => {
            if (!item.iconImagePath) {
                return Promise.resolve(item);
            }
            return new Promise(resolve => {
                const localPromises = [
                    getDownloadUrl(item.iconImagePath),
                    getDownloadUrl(item.patternImagePath),
                ];
                Promise.all(localPromises)
                    .then(([iconUri, patternUri]) => {
                        resolve({...item, iconUri, patternUri});
                    })
                    .catch(err => {
                        console.error('Bad element image paths', err);
                        resolve(item);
                    });
            });
        });
        Promise.all(promises).then(items => {
            dispatch(setItems(items));
            onDownloadDone();
        });
    };

export const downloadPhotos = photoPaths => {
    const downloadPromises = [];
    photoPaths.forEach(photoPath =>
        downloadPromises.push(
            firebase.storage().ref(photoPath).getDownloadURL(),
        ),
    );
    return new Promise((resolve, reject) => {
        Promise.all(downloadPromises)
            .then(photoUris => {
                const result = photoPaths.map((photoPath, index) => {
                    return {
                        fileName: photoPath,
                        uri: photoUris[index],
                    };
                });
                resolve(result);
            })
            .catch(error => {
                console.log('failed to download photos ', error);
                reject(error);
            });
    });
};

export const dispatchAndDownloadPhotos = dispatch => (photos, callback) => {
    photos && downloadPhotos(photos).then(callback);
};

export const removePhotos = photoPathsToRemove =>
    photoPathsToRemove.map(photo =>
        firebase.storage().ref().child(photo.fileName).delete(),
    );
