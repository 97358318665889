import React from 'react';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import './App.css';
import {
    HOME,
    ELEMENTS,
    ISSUES,
    LOGIN,
    ARCHIVED_ISSUES,
    REPORTS,
    LOCATIONS,
    USERS,
    USER,
    TASKS,
    TASK,
    BRANCHES,
} from './constants/routes';
import ArchivedIssuePage from './containers/ArchivedIssuePage/ArchivedIssuePage';
import ElementsPage from './containers/ElementsPage/ElementsPage';
import HomePage from './containers/HomePage/HomePage';
import IssueDetails from './containers/IssueDetails/IssueDetails';
import ElementDetails from './containers/ElementDetails/ElementDetails';
import LoginPage from './containers/LoginPage/LoginPage';
import withAuthentication from './hoc/Authentication/WithAuthentication';
import withLayout from './hoc/Layout/WithLayout';
import {allRoles, roles} from './constants/roles';
import ReportsPage from './containers/Reports/ReportsPage';
import LocationsPage from './containers/LocationsPage/LocationsPage';
import TasksPage from './containers/TasksPage/TasksPage';
import UsersPage from './containers/Users/UsersPage';
import UserDetails from './containers/UserDetails/UserDetails';
import {NotificationProvider} from './context/notifications';
import TaskDetails from './containers/TaskDetails/TaskDetails';
import BranchesPage from './containers/BranchesPage/BranchesPage';
import {version} from '../package.json';

console.log(`Version: ${version}`);

const app = () => (
    <BrowserRouter>
        <NotificationProvider>
            <Switch>
                <Redirect exact={true} from={HOME} to={ISSUES} />
                <Route
                    path={ISSUES}
                    exact={true}
                    component={withLayout(
                        withAuthentication(HomePage, true, allRoles),
                        true,
                    )}
                />
            </Switch>
            <Route path={LOGIN} exact={true} component={LoginPage} />
            <Route
                path="/issue/:id"
                exact={true}
                component={withLayout(
                    withAuthentication(IssueDetails, true, allRoles, {
                        archived: false,
                    }),
                )}
            />
            <Route
                path="/issue/archived/:id"
                exact={true}
                component={withLayout(
                    withAuthentication(
                        IssueDetails,
                        true,
                        [roles.MAINTAINER, roles.SUPERVISOR, roles.ADMIN],
                        {
                            archived: true,
                        },
                    ),
                )}
            />
            <Route
                path="/element/:id"
                component={withLayout(
                    withAuthentication(ElementDetails, true, [
                        roles.MAINTAINER,
                        roles.SUPERVISOR,
                        roles.ADMIN,
                    ]),
                )}
            />
            <Route
                path={ARCHIVED_ISSUES}
                exact={true}
                component={withLayout(
                    withAuthentication(ArchivedIssuePage, true, [
                        roles.MAINTAINER,
                        roles.SUPERVISOR,
                        roles.ADMIN,
                    ]),
                    true,
                )}
            />
            <Route
                path={ELEMENTS}
                exact={true}
                component={withLayout(
                    withAuthentication(ElementsPage, true, [
                        roles.MAINTAINER,
                        roles.SUPERVISOR,
                        roles.ADMIN,
                    ]),
                    true,
                )}
            />
            <Route
                path={REPORTS}
                exact={true}
                component={withLayout(
                    withAuthentication(ReportsPage, true, [
                        roles.ADMIN,
                        roles.SUPERVISOR,
                    ]),
                    true,
                )}
            />
            <Route
                path={LOCATIONS}
                exact={true}
                component={withLayout(
                    withAuthentication(LocationsPage, true, [
                        roles.MAINTAINER,
                        roles.SUPERVISOR,
                        roles.ADMIN,
                    ]),
                    true,
                )}
            />
            <Route
                path={USERS}
                exact={true}
                component={withLayout(
                    withAuthentication(UsersPage, true, [
                        roles.SUPERVISOR,
                        roles.ADMIN,
                    ]),
                    true,
                )}
            />
            <Route
                path={USER(':id')}
                exact={true}
                component={withLayout(
                    withAuthentication(UserDetails, true, [roles.ADMIN]),
                )}
            />
            <Route
                path={TASKS}
                exact={true}
                component={withLayout(
                    withAuthentication(TasksPage, true, [roles.ADMIN]),
                    true,
                )}
            />
            <Route
                path={TASK(':id')}
                exact={true}
                component={withLayout(
                    withAuthentication(TaskDetails, true, [roles.ADMIN]),
                )}
            />
            <Route
                path={BRANCHES}
                exact={true}
                component={withLayout(
                    withAuthentication(BranchesPage, true, [roles.ADMIN]),
                    true,
                )}
            />
        </NotificationProvider>
    </BrowserRouter>
);

export default app;
