import React from 'react';
import {ISSUES} from '../../../constants/routes';
import NavigationItem from '../NavigationItem/NavigationItem';
import styles from './DrawerItems.module.css';
import Icons from './DrawerIcons';
import UserLogoutButton from '../../UserStatusPanel/UserLogoutButton';
import {GlobalBranchPicker} from '../../../common/components';
import {connect} from 'react-redux';

const drawerItems = ({
    innerComponents: [
        LocationsNavItem,
        TasksNavItem,
        ElementsNavItem,
        ArchivedIssuesNavItem,
        ReportNavItem,
        UsersNavItem,
        BranchNavItem,
    ],
    branches,
}) => (
    <div className={styles.drawerItems}>
        <NavigationItem
            path={ISSUES}
            title={'Lista zgłoszeń'}
            icon={<Icons.IssuesIcon />}
        />
        {TasksNavItem()}
        {ArchivedIssuesNavItem()}
        {ReportNavItem()}
        {ElementsNavItem()}
        {LocationsNavItem()}
        {BranchNavItem()}
        {UsersNavItem()}
        <hr className={styles.line} />
        <GlobalBranchPicker />
        <span className={styles.onlyMobile}>
            <UserLogoutButton />
        </span>
    </div>
);

const mapStateToProps = store => ({
    branches: store.branch.branches,
});

export default connect(mapStateToProps)(drawerItems);
