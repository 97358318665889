import React from 'react';
import Picker from '../picker/Picker';

const elementPicker = props => {
    const items = props.elements?.map(element => ({
        value: element.id,
        label: element.name,
    }));
    const selectedValue = props.selectedElement && {
        value: props.selectedElement.id,
        label: props.selectedElement.name,
    };
    return (
        <Picker
            label={'Element'}
            selectedValue={selectedValue}
            pickerItems={items}
            name="Elementy"
            handleChange={props.handleChange}
            placeholder={'Szukaj elementu'}
            className={props.className}
            disabled={props.disabled}
        />
    );
};

elementPicker.defaultProps = {
    bottomLabelActive: true,
};

export default elementPicker;
