import React from 'react';
import './elementName.css';
import classnames from 'classnames';

const elementName = ({elementName, iconUri}) => (
    <div className="issue-name">
        <img
            className={classnames('icon', {default: !iconUri})}
            alt=""
            src={
                iconUri ||
                require('../.././../../../../static/images/icons/picture-landscape.svg')
            }
        />
        <span className="nowrap">{elementName}</span>
    </div>
);

export default elementName;
