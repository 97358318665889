import React from 'react';
import Dialog from '../../../common/components/Dialog/Dialog';
import TaskForm from '../../../containers/forms/task/TaskForm';

const createNewTaskLayout = props => (
    <Dialog
        loading={props.loading}
        handleClose={props.onClose}
        dialogTitle={'Dodaj zadanie'}
        submitButtonDisabled={props.loading || props.submitButtonDisabled}
        onSubmitHandler={props.onSubmitNewTaskHandler}
        submitButtonText={'Dodaj zadanie'}
    >
        <TaskForm
            elements={props.elements}
            locations={props.locations}
            users={props.users}
            setSubmitButtonDisabled={props.setAddButtonDisabled}
            setFormState={props.setFormState}
            noTopMarginOnFirstInput
        />
    </Dialog>
);

export default createNewTaskLayout;
