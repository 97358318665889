export const defaultCustomStyles = customSelectedValue => ({
    container: (provided, state) => ({
        ...provided,
        backgroundColor: 'var(--white)',
    }),
    dropdownIndicator: (provided, state) => ({
        display: state.isDisabled ? 'none' : 'block',
        color: state.isFocused
            ? 'rgba(4, 123, 235, 0.533)'
            : 'var(--light-blue)',
    }),
    indicatorSeparator: (provided, state) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        margin: '8px 15px 4px',
        display: 'flex',
    }),
    clearIndicator: (provided, state) => ({
        padding: 0,
    }),
    control: (provided, state) => ({
        minHeight: '40px',
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: customSelectedValue ? '5px' : '30px',
        border: '1px solid var(--light-grey2)',
        borderRadius: '5px',
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.02em',
        backgroundColor: state.isFocused
            ? 'rgba(0, 0, 0, 0.051)'
            : 'var(--white)',
    }),
    placeholder: defaultStyles => ({
        ...defaultStyles,
        color: 'var(--mid-tone-grey2)',
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.02em',
    }),
    option: (provided, _state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
    }),
});

export const globalBranchPickerStyle = (customSelectedValue, disabled) => ({
    container: (provided, state) => ({
        width: 'calc(100% - 40px)',
        margin: '10px auto',
    }),
    dropdownIndicator: (provided, state) => ({
        display: disabled ? 'none' : 'block',
        color: 'var(--white)',
        '@media (max-width: 610px)': {
            color: 'var(--light-blue)',
        },
    }),
    indicatorSeparator: (provided, state) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        margin: '8px 15px',
        marginBottom: '-6px',
    }),
    placeholder: (provided, state) => ({
        color: 'var(--white)',
        opacity: 0.75,
        fontSize: 13,
    }),
    control: (provided, state) => ({
        minHeight: '40px',
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: customSelectedValue ? '5px' : '30px',
        border: '1px solid var(--dark-blue)',
        borderRadius: '5px',
        lineHeight: '19px',
        letterSpacing: '0.02em',
        backgroundColor: 'var(--dark-blue)',
        '@media (max-width: 610px)': {
            backgroundColor: 'var(--white)',
            borderColor: 'var(--light-grey2)',
        },
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: 'var(--dark-blue)',
        '@media (max-width: 610px)': {
            backgroundColor: 'var(--white)',
            maxHeight: '200px',
        },
    }),
    menu: defaultStyles => ({
        ...defaultStyles,
        width: 'calc(100% - 40px)',
    }),
    multiValue: defaultStyles => ({
        ...defaultStyles,
        marginLeft: '-8px',
        backgroundColor: 'transparent',
    }),
    option: (provided, state) => ({
        ...provided,
        color: 'var(--white)',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'normal',
        backgroundColor: state.isFocused
            ? 'var(--hover-over-blue)'
            : 'var(--dark-blue)',
        fontSize: '13px',
        lineHeight: '18px',
        cursor: 'pointer',
        flexWrap: 'wrap',
        '@media (max-width: 610px)': {
            color: 'var(--dark-grey)',
            backgroundColor: 'var(--white)',
        },
    }),
    multiValueLabel: defaultStyles => ({
        ...defaultStyles,
        fontSize: '14px',
        color: 'var(--white)',
        fontWeight: 'normal',
        '@media (max-width: 610px)': {
            color: 'var(--dark-grey)',
        },
    }),
    multiValueRemove: (provided, state) => ({
        display: 'none',
    }),
});
