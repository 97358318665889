import React from 'react';
import {
    CustomDatePicker,
    CustomTextField,
    ElementsPicker,
    IOSSwitchBigger,
    LocationsPicker,
    SwitchField,
    UserPicker,
    AddElement,
    Checkbox,
} from '../../../common/components';
import RadioButtons from '../../../common/components/radio/RadioButton';
import MultiUserPicker from '../../../components/Tasks/multiUserPicker/MultiUserPicker';
import {PRIORITY_LABEL} from '../../../constants/issues';

const taskPriorityRadioGroupOptions = Object.entries(PRIORITY_LABEL).map(
    ([key, value]) => ({name: value, value: key}),
);

const TaskFormLayout = props => (
    <>
        <CustomTextField
            onChange={props.onTaskNameChange}
            label="Nazwa zadania"
            value={props.name}
            first={props.noTopMarginOnFirstInput}
        />
        {props.selectedLocations.map((location, index) => (
            <div key={index}>
                <LocationsPicker
                    handleChange={location =>
                        props.onLocationChange(location, index)
                    }
                    locations={props.locations}
                    selectedLocation={location}
                />
                <ElementsPicker
                    handleChange={element =>
                        props.onElementChange(element, index)
                    }
                    selectedElement={props.selectedElements[index]}
                    elements={props.elementsForLocation[index]}
                />
            </div>
        ))}
        <AddElement text="Dodaj element" onClick={props.onElementAdded} />
        <CustomDatePicker
            label="Termin zadania"
            onChange={props.onStartDateChange}
            value={props.startDate}
        />
        <Checkbox
            onChange={props.checkboxToggle}
            label="Wyślij powiadomienie e-mail przed terminem"
            name="notificationCheckbox"
            checked={props.notificationCheckbox}
        />
        {props.notificationCheckbox && (
            <>
                <MultiUserPicker
                    users={props.users}
                    selectedUsers={props.selectedUsers}
                    setSelectedUsers={props.setSelectedUsers}
                />
                <CustomTextField
                    label={`Wyślij przypomnienie ${
                        props.notificationTime || 0
                    } dni przed terminem.`}
                    value={props.notificationTime}
                    onChange={props.onNotificationTimeChange}
                    name="notificationTime"
                    inputProps={{maxLength: 3}}
                />
            </>
        )}
        <UserPicker
            users={props.users}
            label="Przypisany pracownik"
            handleChange={props.onAssignedToChange}
            selectedUser={props.assignedTo}
        />

        <Checkbox
            onChange={props.checkboxToggle}
            label="Wstaw zadanie na liste zgłoszeń"
            name="addToIssueList"
            checked={props.addToIssueList}
        />
        {props.addToIssueList && (
            <>
                {RadioButtons({
                    items: taskPriorityRadioGroupOptions,
                    label: 'Priorytet zadania',
                    onChange: props.onPriorityChange,
                    defaultValue: props.defaultPriority,
                })}
                <CustomTextField
                    label={`Wstaw zadanie na listę zgłoszeń ${props.addToIssueListTimePeriod} dni przed upływem terminu`}
                    value={props.addToIssueListTimePeriod}
                    onChange={props.onAddToIssueListTimePeriodChange}
                    name="addToIssueListTime"
                    inputProps={{maxLength: 3}}
                />
            </>
        )}

        <CustomTextField
            type="text"
            label={`Cyklicznie powtarzaj zadanie co ${props.repeatDays} dni`}
            onChange={props.onDaysNumberChange}
            inputProps={{maxLength: 4}}
            value={props.repeatDays}
        />
        <SwitchField
            label="Aktywne"
            switchComponent={
                <IOSSwitchBigger
                    onChange={props.onActiveStateChange}
                    checked={props.active}
                />
            }
        />
    </>
);

export default TaskFormLayout;
