import React from 'react';
import * as styles from './Status.module.css';
import {STATUS_LABELS} from '../../../../../../constants/issues';
import classNames from 'classnames';

const status = props => (
    <>
        <span className={classNames(styles.icon, styles[props.status])} />
        <span className={classNames(styles.mobileIcon, styles[props.status])}>
            {STATUS_LABELS[props.status]}
        </span>
    </>
);

export default status;
