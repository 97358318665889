import React from 'react';
import styles from './AddIssueLayout.module.css';
import {
    CustomTextField,
    LocationsPicker,
    ElementsPicker,
    CustomDatePicker,
    ImageDropzone,
    ImageDisplay,
} from '../../../common/components/index';
import Dialog from '../../../common/components/Dialog/Dialog';

const addIssueFormLayout = ({
    innerComponents: [Checkbox, UserPicker],
    props,
}) => (
    <Dialog
        loading={props.loading}
        handleClose={props.onClose}
        dialogTitle={'Dodaj zgłoszenie'}
        submitButtonDisabled={props.isSubmitButtonDisabled}
        onSubmitHandler={props.onSubmitNewIssueHandler}
        submitButtonText={'Dodaj zgłoszenie'}
    >
        <LocationsPicker
            selectedLocation={props.selectedLocation}
            handleChange={props.onLocationChange}
            first
            locations={props.locations}
        />
        <ElementsPicker
            selectedElement={props.selectedElement}
            handleChange={props.onElementChange}
            elements={props.elements}
        />
        {props.selectedElement?.iconUri && (
            <ImageDisplay
                src={props.selectedElement?.iconUri}
                fullSizeSrc={props.selectedElement?.patternUri}
                title="Zdjęcie elementu"
            />
        )}
        <CustomDatePicker label="Data zgłoszenia" value={new Date()} readOnly />
        {UserPicker({
            label: 'Przypisany pracownik',
            selectedUser: props.assignedEmployee,
            handleChange: props.onAssignedEmployeeChange,
            users: props.users,
        })}
        {Checkbox({
            className: styles.highPriorityCheckbox,
            label: 'Zgłoszenie krytyczne',
            onChange: props.onUrgentPriorityChange,
            checked: props.urgentPriority,
        })}
        {!props.organizationData.importantFlagOnIssueHidden &&
            Checkbox({
                className: styles.highPriorityCheckbox,
                label: 'Zgłoszenie pilne',
                onChange: props.onHighPriorityChange,
                checked: props.highPriority,
            })}
        <CustomTextField
            className={styles.description}
            label="Opis zgłoszenia"
            onChange={props.onDescriptionChange}
            fullWidth
            multiline
        />
        <ImageDropzone
            label="Zdjęcia zgłoszenia"
            onDrop={props.onIssueImageDrop}
            imagesSrc={props.issueImageFiles.map(image =>
                URL.createObjectURL(image),
            )}
            onRemoveImage={props.onRemoveIssueImage}
        />
    </Dialog>
);

export default addIssueFormLayout;
